import React from "react";
import { Row, Col, Form, Input, message, Spin, Modal } from "antd";
import { useParams } from "react-router-dom";
import api from "api";
import { HomeOutlined, UserOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
export default function Active(props) {
  const [loadingApp, setLoadingApp] = React.useState(false);
  const { active_code } = useParams();
  let navigate = useNavigate();
  React.useEffect(() => {
    setLoadingApp(true);
    api
      .get("Register/Active?code=" + active_code)
      .then((res) => {
        if (res.code == 1) {
          Modal.success({
            content: (
              <div>
                <p>Congratulations on your successful activation!</p>
              </div>
            ),
            onOk() {
              navigate("/login");
            },
          });
        } else message.error(res.Message);
        setLoadingApp(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoadingApp(false);
      });
  }, []);

  return (
    <Row>
      {loadingApp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999,
              backgroundColor: "#d3d1d1",
              opacity: 0.5,
            }}
          ></div>
          <div style={{ margin: "auto", zIndex: 10000 }}>
            <Spin size={"large"}></Spin>
          </div>
        </div>
      )}
    </Row>
  );
}
