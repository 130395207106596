import * as React from "react";
import api from "api";
import { Row, Button, Space, Card, Select, Col, DatePicker, Divider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import dayjs from "dayjs";
import AntTable from "component/common/AntTable";
const Option = Select;
const { RangePicker } = DatePicker;

const Inventory = (props) => {
  const [lstctg, setlstctg] = React.useState([]);
  const tblWH = React.useRef();
  const [lstproduct, setlstproduct] = React.useState([]);
  const [total, settotal] = React.useState([]);
  const [filter, setFilter] = React.useState({
    StartDate: dayjs().date(1),
    EndDate: dayjs().endOf("month"),
    Info: "",
  });
  React.useEffect(() => {
    getListProduct();
    getListCtg();
  }, []);
  React.useEffect(() => {
    getlstWarehouse();
  }, [filter]);
  const getTotal = (data) => {
    console.log(data);
    settotal(data.all);
  };

  const getlstWarehouse = () => {
    tblWH.current.reload();
  };
  const dateChange = (dates) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      StartDate: dates[0],
      EndDate: dates[1],
    }));
  };
  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };
  const getListCtg = () => {
    api.get("Product/GetListCategory").then((res) => {
      setlstctg(res.lstctg);
    });
  };
  const getListProduct = () => {
    api.get("Warehouse/GetAllProduct").then((res) => {
      setlstproduct(res.data);
    });
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Danh mục",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Tồn kho",
      dataIndex: "Stock",
      classname: " ",
      children: [
        {
          title: "Số lượng",
          className: "alignRight",
          dataIndex: "current_quantity",
          render: (text, record, index) => {
            return <div>{formatCurrency(record.current_quantity)}</div>;
          },
        },
        {
          title: "Giá trị",
          className: "alignRight",
          dataIndex: "current_price",
          render: (text, record, index) => {
            return <div>{formatCurrency(record.current_quantity * record.current_price)}</div>;
          },
        },
      ],
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div style={{ fontWeight: "bold" }}>Ngày:</div>
            <div>
              <RangePicker
                style={{ width: "100%" }}
                value={[filter.StartDate, filter.EndDate]}
                format={"DD/MM/YYYY"}
                onChange={dateChange}
                allowClear={false}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Danh mục</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.CategoryID}
                style={{
                  width: "100%",
                }}
                mode="multiple"
                onChange={(e) => {
                  paramChange(e, "CategoryID");
                }}
                options={lstctg}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Sản phẩm</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.ProductID}
                style={{
                  width: "100%",
                }}
                mode="multiple"
                onChange={(e) => {
                  paramChange(e, "ProductID");
                }}
                options={lstproduct}
              />
            </div>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              getlstWarehouse();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <Row gutter={16}>
          <Col span={6}>
            <div style={{ textAlign: "center", border: "1px solid #f5f5f5" }}>
              <Row>
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    padding: "10px 10px",
                    fontWeight: "bold",
                  }}
                >
                  Tồn đầu kỳ
                </p>
              </Row>
              <Divider type="horizontal" style={{ margin: 0 }} />
              <Row>
                <Col
                  span={12}
                  style={{
                    borderRight: "1px solid #f5f5f5",
                  }}
                >
                  <p>Số lượng</p>
                  <p>
                    <b>{formatCurrency(total.begin_quantity)}</b>
                  </p>
                </Col>
                <Col span={12}>
                  <p>Giá trị</p>
                  <p>
                    <b>{formatCurrency(total.begin_totalprice)}</b>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ textAlign: "center", border: "1px solid #f5f5f5" }}>
              <Row>
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    padding: "10px 10px",
                    fontWeight: "bold",
                  }}
                >
                  Nhập trong kỳ
                </p>
              </Row>
              <Divider type="horizontal" style={{ margin: 0 }} />
              <Row>
                <Col
                  span={12}
                  style={{
                    borderRight: "1px solid #f5f5f5",
                  }}
                >
                  <p>Số lượng</p>
                  <p>
                    <b>{formatCurrency(total.mid_in_quantity)}</b>
                  </p>
                </Col>
                <Col span={12}>
                  <p>Giá trị</p>
                  <p>
                    <b>{formatCurrency(total.mid_in_totalprice)}</b>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ textAlign: "center", border: "1px solid #f5f5f5" }}>
              <Row>
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    padding: "10px 10px",
                    fontWeight: "bold",
                  }}
                >
                  Xuất trong kỳ
                </p>
              </Row>
              <Divider type="horizontal" style={{ margin: 0 }} />
              <Row>
                <Col
                  span={12}
                  style={{
                    borderRight: "1px solid #f5f5f5",
                  }}
                >
                  <p>Số lượng</p>
                  <p>
                    <b>{formatCurrency(total.mid_ount_quantity)}</b>
                  </p>
                </Col>
                <Col span={12}>
                  <p>Giá trị</p>
                  <p>
                    <b>{formatCurrency(total.mid_out_totalprice)}</b>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ textAlign: "center", border: "1px solid #f5f5f5" }}>
              <Row>
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    padding: "10px 10px",
                    fontWeight: "bold",
                  }}
                >
                  Tồn cuối kỳ
                </p>
              </Row>
              <Divider type="horizontal" style={{ margin: 0 }} />
              <Row>
                <Col
                  span={12}
                  style={{
                    borderRight: "1px solid #f5f5f5",
                  }}
                >
                  <p>Số lượng</p>
                  <p>
                    <b>{formatCurrency(total.end_quantity)}</b>
                  </p>
                </Col>
                <Col span={12}>
                  <p>Giá trị</p>
                  <p>
                    <b>{formatCurrency(total.end_totalprice)}</b>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card>
      <Card>
        <AntTable
          bordered
          columns={columns}
          ajax="Warehouse/GetlstWarehouseInventory"
          customSearch={filter}
          ref={tblWH}
          onGetDataFinish={getTotal}
        ></AntTable>
      </Card>
    </>
  );
};

export default Inventory;
