import * as React from "react";
import api from "api";
import { Row, Button, Space, Card, Select, Col, Input, Tag, Alert, DatePicker } from "antd";
import StudentPopup from "./student_popup";
import StatusPopup from "./popup_update_status";
import { EditOutlined, PlusOutlined, SearchOutlined, RotateRightOutlined } from "@ant-design/icons";
import { formatShortDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import dayjs from "dayjs";
import { imageURL } from "config/index";
const { RangePicker } = DatePicker;
const Student = (props) => {
  const [lstclass, setlstclass] = React.useState([]);
  const studentPopup = React.useRef();
  const statusPopup = React.useRef();
  const tblstudent = React.useRef();
  const [dataGroup, setDataGroup] = React.useState([]);
  const [filter, setFilter] = React.useState({
    StartDate: null,
    EndDate: null,
  });
  React.useEffect(() => {
    getListClass();
  }, []);

  React.useEffect(() => {
    getlststudent();
  }, [filter]);

  const getlststudent = () => {
    tblstudent.current.reload();
  };

  const dateChange = (dates) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      StartDate: dates?.[0],
      EndDate: dates?.[1],
    }));
  };

  const getListClass = () => {
    api.get("Student/GetListClass").then((res) => {
      setlstclass(res.data);
    });
  };
  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const onGetDataFinish = (res) => {
    setDataGroup(res.group);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={imageURL + record.avatar} />
          </>
        );
      },
    },
    {
      title: "Tên học sinh",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "studying") return <Tag color="green">Đang học</Tag>;
        if (text == "on_hole") return <Tag color="orange">Tạm ngưng</Tag>;
        if (text == "pandemic_break") return <Tag color="red">Đã nghỉ</Tag>;
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Lớp học",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Ngày nhập học",
      dataIndex: "enrollment_date",
      key: "enrollment_date",
      render: (text, record, index) => {
        return (
          <div>
            <p>
              <Tag color="green">{formatShortDate(text)}</Tag>
            </p>
            {record.break_date && (
              <p>
                <Tag color="orange">{formatShortDate(record.break_date)}</Tag>
              </p>
            )}
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              studentPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm học sinh
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                studentPopup.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => {
                statusPopup.current.open(record.id);
              }}
            >
              <RotateRightOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Lớp học</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.ClassIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "ClassIDs");
                }}
                options={lstclass}
                allowClear={true}
                optionFilterProp={"name"}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                style={{
                  width: "100%",
                }}
                onPressEnter={(e) => {
                  paramChange(e.target.value, "Info");
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái</div>
              <Select
                value={filter.Status}
                onChange={(e) => {
                  paramChange(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "", label: "Tất cả" },
                  { value: "studying", label: "Đang học" },
                  { value: "on_hole", label: "Tạm ngưng" },
                  { value: "pandemic_break", label: "Đã nghỉ" },
                ]}
                defaultValue={""}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Ngày nhập học</div>
              <RangePicker
                style={{ width: "100%" }}
                value={[filter.StartDate, filter.EndDate]}
                format={"DD/MM/YYYY"}
                onChange={dateChange}
                allowClear={true}
                presets={[
                  {
                    label: "Hôm nay",
                    value: [dayjs(), dayjs()],
                  },
                  {
                    label: "7 ngày trước",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Tháng trước",
                    value: [
                      dayjs().add(-1, "month").date(1),
                      dayjs().add(-1, "month").endOf("month"),
                    ],
                  },
                  {
                    label: "Tháng này",
                    value: [dayjs().date(1), dayjs().endOf("month")],
                  },
                  {
                    label: "Năm này",
                    value: [dayjs().month(0).date(1), dayjs().endOf("month")],
                  },
                ]}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                getlststudent();
              }}
            >
              <SearchOutlined /> Tìm kiếm
            </Button>
          </Space>
        </div>
      </Card>
      <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Alert
            message={`Đang học: ${dataGroup.find((x) => x.status == "studying")?.count || 0}`}
            type="success"
            style={{ borderColor: "#35fc59", textAlign: "center" }}
          />
        </Col>
        <Col span={8}>
          <Alert
            message={`Tạm ngưng: ${dataGroup.find((x) => x.status == "on_hole")?.count || 0}`}
            style={{ borderColor: "orange", backgroundColor: "#FFF7E6", textAlign: "center" }}
          />
        </Col>
        <Col span={8}>
          <Alert
            message={`Đã nghỉ: ${dataGroup.find((x) => x.status == "pandemic_break")?.count || 0}`}
            style={{
              borderColor: "red",
              backgroundColor: "#FFF1F0",
              textAlign: "center",
            }}
          />
        </Col>
      </Row>
      <Card>
        <AntTable
          columns={col}
          ajax="Student/GetListStudent"
          customSearch={filter}
          ref={tblstudent}
          onGetDataFinish={onGetDataFinish}
        ></AntTable>
        <StudentPopup ref={studentPopup} reload={getlststudent}></StudentPopup>
        <StatusPopup ref={statusPopup} reload={getlststudent}></StatusPopup>
      </Card>
    </>
  );
};

export default Student;
