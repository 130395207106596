import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, DatePicker, Select, Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import StudyHistory from "./study_history";
import { imageURL, uploadImageURL } from "config/index";
import axios from "axios";

const { Option } = Select;
const { TextArea } = Input;
const StudentPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [lstclass, setlstclass] = React.useState([]);
  const form = React.useRef();
  const studyHistory = React.useRef();
  const [mode, setmode] = React.useState("edit");

  const [defaultImageURL, setdefaultImageURL] = React.useState("");
  const defaultFileList = [
    {
      url: defaultImageURL,
    },
  ];

  React.useEffect(() => {
    getListClass();
  }, [props.reload]);

  const getListClass = () => {
    api.get("Student/GetListClass").then((res) => {
      setlstclass(res.data);
    });
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    var token = localStorage.getItem("token");
    if (!token) token = "";
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data", token },
    };
    fmData.append("image", file);
    try {
      const res = await axios.post(uploadImageURL, fmData, config);
      setdefaultImageURL(imageURL + res.data);
      form.current.setFieldsValue({ avatar: res.data });
      onSuccess("Ok");
    } catch (err) {
      setdefaultImageURL();
      form.current.setFieldsValue({ avatar: "" });
      onError({ err });
    }
  };

  const saveStudent = (values) => {
    values.date_of_birth = values.date_of_birth?.format();
    values.enrollment_date = values.enrollment_date?.format();
    api
      .post("Student/SaveStudent", values)
      .then((res) => {
        message.success(res.message);
        props.reload();
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(student) {
      setVisible(true);
      setTimeout(() => {
        setmode("edit");
        if (student) {
          student.date_of_birth = student.date_of_birth ? dayjs(student.date_of_birth) : null;
          student.enrollment_date = student.enrollment_date ? dayjs(student.enrollment_date) : null;
          form.current.setFieldsValue(student);
          setdefaultImageURL(imageURL + student.avatar);
        } else {
          setmode("add");
          form.current.resetFields();
          form.current.setFieldsValue({ enrollment_date: dayjs() });
          setdefaultImageURL();
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      width="50%"
      open={visible}
      title={mode == "add" ? "Thêm mới học sinh" : "Chỉnh sửa học sinh"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
    >
      <Form ref={form} onFinish={saveStudent} name="validateOnly" layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="id" name="id" hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Tên học sinh"
              name="name"
              rules={[{ required: true, message: "Nhập tên học sinh!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="class_id"
              label="Lớp học"
              // rules={[{ required: true, message: "Chọn lớp!" }]}
            >
              <Select
                placeholder="Lớp học"
                allowClear
                options={lstclass.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="gender"
              label="Giới tính"
              rules={[{ required: true, message: "Chọn giới tính!" }]}
            >
              <Select placeholder="Giới tính" allowClear>
                <Option value="male">Nam</Option>
                <Option value="female">Nữ</Option>
                <Option value="other">Khác</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone_number"
              label="Số điện thoại"
              // rules={[{ required: true, message: "Vui lòng nhập SĐT!" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ngày sinh " name="date_of_birth">
              <DatePicker
                placeholder="Chọn ngày sinh"
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="parent_name"
              label="Họ tên phụ huynh"
              rules={[{ required: true, message: "Vui lòng nhập Họ tên phụ huynh!" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="parent_phone_number"
              label="SĐT phụ huynh"
              rules={[{ required: true, message: "Vui lòng nhập SĐT phụ huynh!" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Địa chỉ " name="address">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Thời gian nhập học" name="enrollment_date">
              <DatePicker
                placeholder="Chọn thời gian nhập học"
                style={{ width: "100%" }}
                format={"DD-MM-YYYY"}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Mô tả " name="description">
              <TextArea rows={4} style={{ marginLeft: "5px" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Avatar" name="avatar">
              <Upload
                fileList={defaultFileList}
                maxCount={1}
                customRequest={uploadImage}
                listType="picture-card"
                accept={".png, .jpg, .jpeg"}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Avatar</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <a
              onClick={() => {
                studyHistory.current.open({
                  id: form.current.getFieldValue("id"),
                  name: form.current.getFieldValue("name"),
                });
              }}
            >
              Lịch sử học
            </a>
          </Col>
        </Row>
      </Form>
      <StudyHistory ref={studyHistory} />
    </Modal>
  );
});
export default StudentPopup;
