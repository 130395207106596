import React from "react";
import { Row, Col, Form, Input, message, Spin, Modal } from "antd";
import api from "api";
import {
  HomeOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function Register(props) {
  const [loadingApp, setLoadingApp] = React.useState(false);
  let navigate = useNavigate();
  React.useEffect(() => {}, []);
  const onFinish = (values) => {
    setLoadingApp(true);
    api
      .post("Register/RegisNewCenter", values)
      .then((res) => {
        if (res.code == 1) {
          Modal.success({
            content: (
              <div>
                <p>Congratulations on your successful registration!</p>
                <p>
                  Please check your{" "}
                  <a href="https://mail.google.com/mail/u/0/?tab=wm#inbox" target="blank">
                    email
                  </a>{" "}
                  to activate the center
                </p>
              </div>
            ),
            onOk() {
              navigate("/login");
            },
          });
        } else message.error(res.Message);
        setLoadingApp(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoadingApp(false);
      });
  };
  return (
    <Row>
      <Col xl={16} lg={12} md={14} sm={0} xs={0}>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url("/image/loginBg.jpg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        ></div>
      </Col>
      <Col xl={8} lg={12} md={10} sm={14} xs={24}>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            backgroundImage: `url("/image/logofull.png")`,
            backgroundSize: "80%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#8befe914",
          }}
        >
          <div style={{ margin: "auto" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "purple",
                  fontFamily: "Texturina",
                  fontWeight: "bold",
                }}
              >
                <h1>SIGN UP</h1>
              </div>
            </div>

            <Form
              name="basic"
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              style={{
                width: 300,
              }}
            >
              <Form.Item
                name="customer_name"
                rules={[{ required: true, message: "Please input your your name!" }]}
              >
                <Input
                  size="large"
                  placeholder="customer name"
                  prefix={<UserOutlined />}
                  style={{ fontSize: 18, borderRadius: 50 }}
                />
              </Form.Item>
              <Form.Item
                name="address"
                rules={[{ required: true, message: "Please input your your address!" }]}
              >
                <Input
                  size="large"
                  placeholder="address"
                  prefix={<EnvironmentOutlined />}
                  style={{ fontSize: 18, borderRadius: 50 }}
                />
              </Form.Item>
              <Form.Item
                name="phone_number"
                rules={[{ required: true, message: "Please input your your phone number!" }]}
              >
                <Input
                  size="large"
                  placeholder="phone number"
                  prefix={<PhoneOutlined />}
                  style={{ fontSize: 18, borderRadius: 50 }}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your your email!" },
                  {
                    validator: (rule, value) => {
                      return new Promise((resolve, error) => {
                        if ((value && !value.includes("@")) || value.includes(" ")) {
                          error();
                        } else {
                          resolve();
                        }
                      });
                    },
                    message: "Please enter the correct email format",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="email"
                  prefix={<MailOutlined />}
                  style={{ fontSize: 18, borderRadius: 50 }}
                />
              </Form.Item>
              <Form.Item
                name="center_name"
                rules={[
                  { required: true, message: "Please input your center name!" },
                  {
                    validator: (rule, value) => {
                      return new Promise((resolve, error) => {
                        var format = /^[A-Za-z]+$/;
                        if (value && !format.test(value)) {
                          error();
                        } else {
                          resolve();
                        }
                      });
                    },
                    message: "The name cannot contain special characters!",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="center name"
                  prefix={<HomeOutlined />}
                  style={{ fontSize: 18, borderRadius: 50 }}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <div style={{ textAlign: "center" }}>
                  <button className="button-85" role="button">
                    Sign up
                  </button>
                  <div style={{ position: "absolute", bottom: 5, right: 5 }}>
                    <a
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Sign in
                    </a>
                  </div>
                  {/* 
                  <Button type="primary" htmlType="submit">
                    Login
                  </Button> */}
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
      {loadingApp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999,
              backgroundColor: "#d3d1d1",
              opacity: 0.5,
            }}
          ></div>
          <div style={{ margin: "auto", zIndex: 10000 }}>
            <Spin size={"large"}></Spin>
          </div>
        </div>
      )}
    </Row>
  );
}
