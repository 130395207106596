import * as React from "react";
import api from "api";
import { Row, Button, Table, Space, Card, Tag, message, Select, Col, DatePicker, Input, AutoComplete } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PopupCollectTuition from "./popup_collect_tuition";
import dayjs from "dayjs";
import useStateCallback from "component/common/useStateCallBack";
const PaymentTuitionOnMonth = (props) => {
  const [data, setData] = React.useState([]);
  const [classSelect, setClassSelect] = React.useState(0);
  const [month, setMoth] = React.useState();
  const [tbLoading, setTbLoading] = React.useState();
  const [lstClass, setLstClass] = React.useState([]);
  // const [isStudy, setIsStudy] = React.useState(false);
  const popupCollectTuition = React.useRef();
  const [info, setInfo] = useStateCallback("");
  const [lstStudents, setLstStudents] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    getlstClass();
    getlstStudents();
  }, [props.reload]);

  const getlstClass = () => {
    api.get("Class/GetListActiveAndFinishWithStudent").then((res) => {
      if (res.data.length > 0) {
        res.data.push({ ...res.data[0], id: 0, name: "Tất cả", students: [] });
        res.data.sort((a, b) => a.id - b.id);
        setLstClass(res.data);
      }
    });
  };
  const getlstStudents = () => {
    api.get("Student/GetAllStudentName").then((res) => {
      if (res.data.length > 0) {
        setLstStudents(res.data);
      }
    });
  };

  const getListData = (infoNow) => {
    // if (!classSelect) {
    //   message.error("Vui lòng chọn lớp");
    //   return;
    // }
    if (!month) {
      message.error("Vui lòng chọn tháng");
      return;
    }
    setTbLoading(true);
    api.get(`Tuition/GetListClassTuition?info=${infoNow || info}&class_id=${classSelect}&month=${month.month() + 1}&year=${month.year()}`).then((res) => {
      setData(res.data);
      // if (
      //   month.year() < dayjs().year() ||
      //   (month.year() == dayjs().year() && month.month() < dayjs().month())
      // ) {
      //   if (res.lstAttendance.length == 0) setIsStudy(false);
      //   else setIsStudy(true);
      // } else {
      //   if (res.lstStudyDate.filter((x) => x.TotalLesson > 0).length > 0) setIsStudy(true);
      //   else setIsStudy(false);
      // }
      setTbLoading(false);
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên học sinh",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "studying") return <Tag color="green">Đang học</Tag>;
        if (text == "on_hole") return <Tag color="orange">Tạm ngưng</Tag>;
        if (text == "pandemic_break") return <Tag color="red">Đã nghỉ</Tag>;
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Đóng tiền học",
      dataIndex: "is_payment",
      key: "is_payment",
      render: (text, record, index) => {
        if (!text && record.isStudy) return <Tag>Chưa đóng học phí</Tag>;
        return <Tag color="green">Đã đóng tiền học</Tag>;
      },
    },
    {
      title: "Tháng nợ HP",
      dataIndex: "timeDebt",
      key: "timeDebt",
      render: (text, record, index) => {
        return record.timeDebt ? `Tháng ${text}` : "";
      },
    },
    {
      key: "action",
      align: "center",
      render: (text, record, index) => {
        if (!record.is_payment && record.isStudy) {
          return (
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
              <Button
                type="primary"
                onClick={() => {
                  popupCollectTuition.current.open(record.id, record.class_id, month);
                }}
              >
                Đóng học phí
              </Button>
            </Space>
          );
        } else {
          return (
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
              <Button
                type="primary"
                onClick={() => {
                  popupCollectTuition.current.open(record.id, record.class_id, month, "product");
                }}
                ghost
              >
                Mua sản phẩm
              </Button>
            </Space>
          );
        }
      },
    },
  ];

  const handleSearch = (value) => {
    setOptions(
      !value
        ? []
        : lstStudents
            .filter((item) => item.toLowerCase().includes(value.toLowerCase()))
            .map((i) => {
              return { value: i };
            }),
    );
  };

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
            </div>
            <AutoComplete
              options={options}
              onSelect={(value) => {
                setInfo(value, () => {
                  getListData(value);
                });
              }}
              onSearch={handleSearch}
              style={{ width: "100%" }}
            >
              <Input
                placeholder={"Tên học sinh"}
                onPressEnter={() => getListData()}
                value={info}
                onChange={(e) => {
                  setInfo(e.target.value);
                }}
                allowClear
              />
            </AutoComplete>
          </Col>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Lớp</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={classSelect}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  setClassSelect(e);
                }}
                options={lstClass}
                filterOption={(inputValue, option) => {
                  if (
                    option.students.some((x) => x.toLowerCase().includes(inputValue.toLowerCase())) ||
                    option.name.toLowerCase().includes(inputValue.toLowerCase())
                  )
                    return true;
                  return false;
                }}
                showSearch={true}
              />
            </div>
          </Col>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Tháng</div>
              <DatePicker
                onChange={(a, b) => setMoth(a)}
                picker="month"
                value={month}
                style={{ width: "100%" }}
                cellRender={(current, info) => {
                  return current.month() + 1;
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => {
              getListData();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <Row style={{ marginTop: 20 }}>
          <Table style={{ width: "100%" }} columns={col} dataSource={data} rowKey="id" loading={tbLoading} />
        </Row>
      </Card>
      <PopupCollectTuition ref={popupCollectTuition} reload={getListData}></PopupCollectTuition>
    </>
  );
};

export default PaymentTuitionOnMonth;
