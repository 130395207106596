import * as React from "react";
import api from "api";
import { Row, Button, Space, Card, Select, Col, Input, Tag, DatePicker } from "antd";
import {
  EditOutlined,
  PrinterOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { formatCurrency, formatShortDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import PopupPrintBill from "component/print/popup_print_bill";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const SellProductHistory = React.forwardRef((props, ref) => {
  const [lstclass, setlstclass] = React.useState([]);
  const tbSellProductHistory = React.useRef();
  const [lstProduct, setLstProduct] = React.useState([]);
  const [filter, setFilter] = React.useState({
    Start: dayjs().date(1),
    End: dayjs().endOf("month"),
  });
  const popupPrintBill = React.useRef();

  React.useEffect(() => {
    getListClass();
    getListProduct();
  }, []);

  const reload = (filter) => {
    setFilter(filter);
    setTimeout(() => {
      tbSellProductHistory.current.reload();
    }, 500);
  };
  React.useImperativeHandle(ref, () => ({
    reload,
  }));

  const getListProduct = () => {
    api.get("Product/GetAll").then((res) => {
      setLstProduct(res.data);
    });
  };

  const getListClass = () => {
    api.get("Student/GetListClass").then((res) => {
      setlstclass(res.data);
    });
  };
  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const dateChange = (dates) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      Start: dates[0],
      End: dates[1],
    }));
  };

  const col2 = [
    {
      title: "Lớp",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Học sinh",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Sản phẩm",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Giá ",
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },
    {
      key: "action",
      render: (text, record, index) => {
        if (props.mode == "add")
          return (
            <Button
              type="primary"
              onClick={() => {
                if (props.add) props.add(record);
              }}
            >
              <PlusCircleOutlined />
            </Button>
          );
        else
          return (
            <Button
              type="primary"
              onClick={() => {
                popupPrintBill.current.open(
                  null,
                  record,
                  record.student_id,
                  dayjs(record.create_date),
                );
              }}
            >
              <PrinterOutlined />
            </Button>
          );
      },
    },
  ];

  return (
    <>
      {props.mode != "add" && (
        <Card style={{ marginBottom: 20 }}>
          <Row gutter={20}>
            <Col span={6}>
              <div>
                <div style={{ fontWeight: "bold" }}>Lớp học</div>
                <Select
                  fieldNames={{ label: "name", value: "id" }}
                  value={filter.ClassID}
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    paramChange(e, "ClassID");
                  }}
                  options={lstclass}
                  allowClear={true}
                  optionFilterProp={"name"}
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={{ fontWeight: "bold" }}>Ngày tạo:</div>
              <div>
                <RangePicker
                  style={{ width: "100%" }}
                  value={[filter.Start, filter.End]}
                  format={"DD/MM/YYYY"}
                  onChange={dateChange}
                  allowClear={false}
                  presets={[
                    {
                      label: "7 ngày trước",
                      value: [dayjs().add(-7, "d"), dayjs()],
                    },
                    {
                      label: "Tháng trước",
                      value: [
                        dayjs().add(-1, "month").date(1),
                        dayjs().add(-1, "month").endOf("month"),
                      ],
                    },
                    {
                      label: "3 Tháng trước",
                      value: [dayjs().add(-3, "month").date(1), dayjs().endOf("month")],
                    },
                    {
                      label: "Tháng này",
                      value: [dayjs().date(1), dayjs().endOf("month")],
                    },

                    {
                      label: "Năm này",
                      value: [dayjs().month(0).date(1), dayjs().endOf("month")],
                    },
                  ]}
                />
              </div>
            </Col>

            <Col span={6}>
              <div>
                <div style={{ fontWeight: "bold" }}>Sản phẩm</div>
                <Select
                  fieldNames={{ label: "name", value: "id" }}
                  allowClear
                  value={filter.ProductID}
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    paramChange(e, "ProductID");
                  }}
                  options={lstProduct}
                />
              </div>
            </Col>

            <Col span={6}>
              <div>
                <div style={{ fontWeight: "bold" }}>Tên học sinh</div>
                <Input
                  value={filter.StudentName}
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    paramChange(e.target.value, "StudentName");
                  }}
                />
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  tbSellProductHistory.current.reload();
                }}
              >
                <SearchOutlined /> Tìm kiếm
              </Button>
            </Space>
          </div>
        </Card>
      )}

      <Card>
        <AntTable
          columns={col2}
          ajax="SellProduct/SearchSellProductHistory"
          customSearch={filter}
          ref={tbSellProductHistory}
        ></AntTable>
        <PopupPrintBill ref={popupPrintBill}></PopupPrintBill>
      </Card>
    </>
  );
});

export default SellProductHistory;
