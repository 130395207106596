import * as React from "react";
import api from "api";
import { Popconfirm, Button, Space, Card, message, Tag } from "antd";
import UserPopup from "./user_popup";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import { formatShortDate } from "common/Format2";
import { imageURL } from "config/index";
import AntTable from "component/common/AntTable";
import UserStatusPopup from "./popup_update_status";
const User = (props) => {
  const userPopup = React.useRef();
  const statusPopup = React.useRef();
  const tbluser = React.useRef();
  const [filter, setFilter] = React.useState({});
  React.useEffect(() => {
    getlstuser();
  }, []);
  React.useEffect(() => {
    getlstuser();
  }, [filter]);

  const getlstuser = () => {
    tbluser.current.reload();
  };

  const deleteUser = (user) => {
    api.get("User/DeleteUser?username=" + user).then((res) => {
      message.success(res.message);
      getlstuser();
    });
  };
  const resetPassWord = (user) => {
    api.post("User/ResetPassWord?username=" + user).then((res) => {
      message.success(res.message);
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={imageURL + record.avatar} />
          </>
        );
      },
    },
    {
      title: "Tên tài khoản",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "working") return <Tag color="green">Đang làm</Tag>;
        if (text == "on_break") return <Tag color="orange">Tạm nghỉ</Tag>;
        if (text == "resting") return <Tag color="red">Đã nghỉ</Tag>;
      },
    },
    {
      title: "Quyền",
      dataIndex: "role_name",
      key: "role_name",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              userPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm Nhân Viên
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                userPopup.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => {
                statusPopup.current.open(record.user_name);
              }}
            >
              <RotateRightOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn đặt lại mật khẩu tài khoản này không?"
              placement="topRight"
              onConfirm={() => {
                resetPassWord(record.user_name);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <ReloadOutlined />
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Bạn có muốn xóa tài khoản này không?"
              placement="topRight"
              onConfirm={() => {
                deleteUser(record.user_name);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <AntTable columns={col} ajax="User/Listuser" ref={tbluser} customSearch={{}}></AntTable>
        <UserPopup ref={userPopup} reload={getlstuser}></UserPopup>
        <UserStatusPopup ref={statusPopup} reload={getlstuser}></UserStatusPopup>
      </Card>
    </>
  );
};

export default User;
