import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Space, message, Row, Col, Select, Tag, DatePicker } from "antd";
import PopupEditDayOff from "./popup_edit_day_off";
import { EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import AntTable from "component/common/AntTable";

const { RangePicker } = DatePicker;

const DayOff = (props) => {
  const [filter, setFilter] = React.useState({});
  const [lstClass, setLstClass] = React.useState([]);
  const classPopup = React.useRef();
  const tbDayOff = React.useRef();

  React.useEffect(() => {
    getListClass();
  }, []);

  const getListClass = () => {
    api.get("Class/GetListActiveAndFinish").then((res) => {
      setLstClass(res.data);
    });
  };

  const getLstDayOff = () => {
    tbDayOff.current.reload();
  };

  const deleteDayOff = (id) => {
    api.get("DayOff/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getLstDayOff();
    });
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const rangeChange = (value) => {
    var data = { ...filter };
    if (value) {
      data.start_date = value[0];
      data.end_date = value[1];
    } else {
      data.start_date = null;
      data.end_date = null;
    }
    setFilter(data);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Lớp",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Loại",
      dataIndex: "is_deduction_tuition",
      key: "is_deduction_tuition",
      render: function (text, record, index) {
        if (record.is_deduction_tuition) return <Tag color="#108ee9">Khấu trừ học phí</Tag>;
        else return <Tag>Không khấu trừ học phí</Tag>;
      },
    },

    {
      title: "Thời gian bắt đâu nghỉ",
      dataIndex: "start_date",
      key: "start_date",
      render: (text, record, index) => {
        if (text) return formatLongDate(text);
      },
    },
    {
      title: "Thời gian nghỉ kết thúc",
      dataIndex: "end_date",
      key: "end_date",
      render: (text, record, index) => {
        if (text) return formatLongDate(text);
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              classPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                classPopup.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              title="Bạn có muốn xóa lớp học này không"
              placement="topRight"
              onConfirm={() => {
                deleteDayOff(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Lớp</div>
              <Select
                allowClear
                fieldNames={{ label: "name", value: "id" }}
                value={filter.ClassID}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "ClassID");
                }}
                options={lstClass}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thời gian nghỉ</div>
              <RangePicker
                value={[filter.start_date, filter.end_date]}
                showTime
                format={"DD-MM-YYYY HH:mm"}
                onChange={(e) => {
                  rangeChange(e);
                }}
                allowClear
              ></RangePicker>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Loại</div>
              <Select
                value={filter.Type}
                onChange={(e) => {
                  paramChange(e, "Type");
                }}
                style={{
                  width: "100%",
                }}
                allowClear
                options={[
                  { value: true, label: "khấu trừ học phí" },
                  { value: false, label: "Không trừ học phí" },
                ]}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => {
              getLstDayOff();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable
          columns={col}
          ajax="DayOff/GetList"
          customSearch={filter}
          ref={tbDayOff}
        ></AntTable>
        <PopupEditDayOff ref={classPopup} reload={getLstDayOff}></PopupEditDayOff>
      </Card>
    </>
  );
};

export default DayOff;
