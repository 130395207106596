import * as React from "react";
import api from "api";
import { parseJwt } from "common/jwt/jwt_helper";
import {
  PhoneOutlined,
  FacebookOutlined,
  WhatsAppOutlined,
  EnvironmentOutlined,
  LogoutOutlined,
  BlockOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  ToolOutlined,
  ShopOutlined,
  ClockCircleOutlined,
  ContainerOutlined,
  ProfileOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  NotificationOutlined,
  BookOutlined,
  BankOutlined,
  FileDoneOutlined,
  TableOutlined,
  FormOutlined,
  DollarOutlined,
  GiftOutlined,
  UsergroupDeleteOutlined,
  GoldOutlined,
  GroupOutlined,
  HistoryOutlined,
  DashboardOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { Layout, Button, Menu, Avatar, Row, Dropdown, Spin } from "antd";
import { useNavigate, Outlet } from "react-router-dom";
import "./App.css";
import { imageURL, permission } from "config/index";
import ChangePassWord from "./component/user/changepass_popup";
import UserInfo from "./component/user/userinfo_popup";
import { registerLoading } from "component/common/loading";
import Notification from "./component/notification";
import dayjs from "dayjs";

const { Header, Footer, Sider, Content } = Layout;

const App = (props) => {
  const changepass = React.useRef();
  const userinfo = React.useRef();
  const [collapsed, setCollapsed] = React.useState(false);
  const [headerTitle, setHeaderTitle] = React.useState("");
  const [userlogin, setUserlogin] = React.useState("");
  const [loadingApp, setLoadingApp] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState(["/config"]);
  const [centerInfo, setCenterInfo] = React.useState({});

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const navigate = useNavigate();
  registerLoading(setLoadingApp);
  const [avatar, setavatar] = React.useState("");

  const avatarMenu = [
    {
      icon: <UserAddOutlined />,
      label: "Thông tin tài khoản",
      key: "userinfo",
    },
    {
      icon: <BlockOutlined />,
      label: "Đổi mật khẩu",
      key: "changePassword",
    },
    {
      icon: <LogoutOutlined />,
      label: "Đăng xuất",
      key: "logout",
    },
  ];
  const handleAvatarMenu = (e) => {
    switch (e.key) {
      case "userinfo":
        userinfo.current.open(userlogin.user_name);
        break;
      case "changePassword":
        changepass.current.open(userlogin.user_name);
        break;
      case "logout":
        localStorage.removeItem("token");
        navigate("/login");
        break;
    }
  };
  const itemMenu = [
    {
      key: "/report",
      icon: <AreaChartOutlined />,
      label: "Dashboard",
    },
    {
      key: "/tuition",
      icon: <DollarOutlined />,
      label: "Học phí",
      children: [
        {
          key: "/collect_tuition",
          icon: <DollarOutlined />,
          label: "Thu học phí",
        },
        {
          key: "/tuition_notification",
          icon: <NotificationOutlined />,
          label: "Thông báo học phí",
        },
        {
          key: "/tuition_history",
          icon: <HistoryOutlined />,
          label: "Lịch sử học phí",
        },
        {
          key: "/sell_history",
          icon: <DashboardOutlined />,
          label: "Lịch sử bán hàng",
        },
      ],
    },
    {
      key: "/revoucher",
      icon: <GoldOutlined />,
      label: "Phiếu thu chi",
    },
    {
      key: "/teacher",
      icon: <FileDoneOutlined />,
      label: "Điểm danh",
    },
    {
      key: "/attendance",
      icon: <FormOutlined />,
      label: "Chấm công",
    },
    {
      key: "/student",
      icon: <UsergroupAddOutlined />,
      label: "Học sinh",
    },
    {
      key: "/warehouseGroup",
      icon: <BankOutlined />,
      label: "Kho",
      children: [
        {
          key: "/category",
          icon: <BookOutlined />,
          label: "Danh mục SP",
        },
        {
          key: "/product",
          icon: <DatabaseOutlined />,
          label: "Sản phẩm",
        },
        {
          key: "/inventory",
          icon: <GroupOutlined />,
          label: "Tồn kho",
        },
        {
          key: "/warehouse",
          icon: <BankOutlined />,
          label: "Sổ kho",
        },
      ],
    },
    {
      key: "/schedule",
      icon: <TableOutlined />,
      label: "Thời khóa biểu",
    },
    {
      key: "/user",
      icon: <UserAddOutlined />,
      label: "Nhân viên",
    },
    {
      key: "/marketing",
      icon: <GiftOutlined />,
      label: "Khuyến mãi",
      children: [
        {
          key: "/voucher",
          icon: <DollarOutlined />,
          label: "Mã khuyến mãi",
        },
        {
          key: "/promotionprogram",
          icon: <GiftOutlined />,
          label: "Chương trình khuyến mãi",
        },
      ],
    },
    {
      key: "/config",
      icon: <ToolOutlined />,
      label: "Cấu hình",
      children: [
        {
          key: "/classlevel",
          icon: <ProfileOutlined />,
          label: "Giáo trình",
        },
        {
          key: "/classroom",
          icon: <ShopOutlined />,
          label: "Phòng học",
        },
        {
          key: "/shift",
          icon: <ClockCircleOutlined />,
          label: "Ca học",
        },
        {
          key: "/class",
          icon: <ContainerOutlined />,
          label: "Lớp học",
        },
        {
          key: "/dayoff",
          icon: <UsergroupDeleteOutlined />,
          label: "Ngày nghỉ",
        },
        {
          key: "/role",
          icon: <ClusterOutlined />,
          label: "Chức vụ",
        },
      ],
    },
  ];

  const getMenu = () => {
    var token = localStorage.getItem("token");
    if (token) {
      var roleConfig = permission.find((x) => x.role == parseJwt(token).role);

      if (roleConfig) {
        if (roleConfig.menu == "ALL") return itemMenu;
        const lstMenu = itemMenu.filter((x) => roleConfig.menu.includes(x.key));
        lstMenu.forEach((item) => {
          if (item.children) {
            item.children = item.children.filter((x) =>
              roleConfig.menu.includes(x.key),
            );
          }
        });

        return lstMenu;
      }
    }

    return itemMenu;
  };

  const footerStyle = {
    textAlign: "center",
    backgroundColor: "#a11f37",
    color: "#fff",
  };

  const onOpenKeyChange = (lstKey) => {
    setOpenKeys(lstKey);
  };

  const setHeader = function () {
    var labelHeader = undefined;
    itemMenu.every((item) => {
      if (item.children)
        labelHeader = item.children.find(
          (x) => x.key == window.location.pathname,
        );
      if (item.key == window.location.pathname) labelHeader = item;
      if (labelHeader) return false;
      return true;
    });
    if (labelHeader) setHeaderTitle(labelHeader.label);
    else setHeaderTitle("Điểm danh");
  };

  React.useEffect(() => {
    api.get("user/me").then((res) => {
      setavatar(res.data.useravatar);
      setUserlogin(res.data);
    });

    api.get("Center/GetCenterInfo").then((res) => {
      setCenterInfo(res.center);
    });
    setHeader();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div
        id="menuLeft"
        style={{
          backgroundColor: "#a11f37",
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 100,
        }}
      >
        <div
          onClick={toggleCollapsed}
          style={{
            width: "100%",
            height: 64,
            backgroundImage: `url("${
              collapsed ? "/image/logotop.jpg" : "/image/logobot.jpg"
            }")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#a11f37",
            backgroundPosition: "center center",
            cursor: "pointer",
          }}
        ></div>
        <Menu
          selectedKeys={window.location.pathname}
          // defaultOpenKeys={[window.location.pathname]}
          style={{ color: "#fff", backgroundColor: "#a11f37", fontWeight: 500 }}
          mode="inline"
          inlineCollapsed={collapsed}
          items={getMenu()}
          onSelect={(data) => {
            navigate(data.key);
            setHeader();
          }}
          openKeys={openKeys}
          onOpenChange={onOpenKeyChange}
        />
      </div>
      <div
        style={{
          backgroundColor: "#a11f37",
          height: "100vh",
          pointerEvents: "none",
          overflowY: "clip",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 64,
          }}
        ></div>
        <Menu
          mode="inline"
          inlineCollapsed={collapsed}
          items={itemMenu}
          openKeys={collapsed ? [] : openKeys}
        />
      </div>
      <Layout>
        <Header
          style={{
            backgroundColor: "#a11f37",
            paddingInline: "20px !important",
            position: "sticky",
            top: 0,
            zIndex: 999,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 300,
            }}
          />
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
            }}
          >
            <span
              className="animate-charcter"
              style={{ fontSize: 38, fontWeight: "bold", color: "#ffffff" }}
            >
              {headerTitle}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: 300,
            }}
          >
            <Button
              style={{ marginRight: 10 }}
              onClick={() => {
                navigate("extend");
              }}
              type={"dashed"}
              ghost={
                !(dayjs().diff(dayjs(centerInfo.valid_to || ""), "day") >= -3)
              }
            >
              <GiftOutlined />
              Gia hạn
            </Button>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Notification />
            </div>

            <Dropdown
              trigger={["click"]}
              menu={{
                items: avatarMenu,
                onClick: handleAvatarMenu,
              }}
            >
              <div style={{ cursor: "pointer", marginLeft: 20 }}>
                <Avatar src={imageURL + avatar} size={38} lt="avatar" />
                <span style={{ color: "#fff", marginLeft: 10 }}>
                  {userlogin.name}
                </span>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content>
          <div style={{ padding: 10, borderRadius: 5 }}>
            <Outlet context={{ userlogin }} />
          </div>
        </Content>
        {/* <Footer style={footerStyle}>
          <div>© {new Date().getFullYear()} Khai Minh. All rights reserved.</div>
          <div>
            <Button icon={<PhoneOutlined />} type="link">
              02633.683.686
            </Button>
            <Button
              icon={<FacebookOutlined />}
              type="link"
              target="_blank"
              href="https://www.facebook.com/trungtamngoaingurainbow"
            >
              Facebook
            </Button>
            <Button
              icon={<WhatsAppOutlined />}
              type="link"
              target="_blank"
              href="https://zalo.me/0974459057"
            >
              Zalo
            </Button>
          </div>
          <div>
            <EnvironmentOutlined /> Địa chỉ: 276 Tân Hiệp, xã Tân Hội, huyện Đức Trọng, tỉnh Lâm
            Đồng.
          </div>
        </Footer> */}
      </Layout>
      <ChangePassWord ref={changepass}></ChangePassWord>
      <UserInfo ref={userinfo}></UserInfo>
      {loadingApp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999,
              backgroundColor: "#d3d1d1",
              opacity: 0.5,
            }}
          ></div>
          <div style={{ margin: "auto", zIndex: 10000 }}>
            <Spin size={"large"}></Spin>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
