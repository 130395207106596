import * as React from "react";
import api from "api";
import { Row, Card, Select, Col, DatePicker, Divider, Statistic, Tabs, Tag } from "antd";
import AntTable from "component/common/AntTable";
import { EditOutlined, TeamOutlined, SearchOutlined } from "@ant-design/icons";
import { formatCurrency, formatLongDate } from "common/Format2";
import dayjs from "dayjs";
import { Line, Column, Pie, G2 } from "@ant-design/plots";
import { each, groupBy } from "@antv/util";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const Report = (props) => {
  const [dataRe, setData] = React.useState([]);
  const [totalMount, setTotalAmount] = React.useState(0);
  const [dataReToday, setDataReToday] = React.useState([]);
  const [dataStudent, setDataStudent] = React.useState([]);
  const [currentAmount, setcurrentAmount] = React.useState("");
  const [currentTranferAmount, setcurrentTranferAmount] = React.useState("");
  const [dataStudentToday, setDataStudentToday] = React.useState({});
  const tbREClass = React.useRef();
  const tbChangeStatusStudent = React.useRef();
  const [lstclass, setlstclass] = React.useState([]);
  const [dataReMonth, setDataReMonth] = React.useState([]);
  const [dataReYear, setDataReYear] = React.useState([]);
  const [dataRv, setDataRv] = React.useState([]);
  const [datervFilter, setDatervFilter] = React.useState({
    StartDate: dayjs().add(-30, "days"),
    EndDate: dayjs(),
  });
  const [datestudentFilter, setDatestudentFilter] = React.useState({
    StartDate: dayjs().add(-30, "days"),
    EndDate: dayjs(),
  });
  const [dateFilter, setDateFilter] = React.useState({
    StartDate: dayjs().add(-30, "days"),
    EndDate: dayjs(),
    mode: "day",
  });
  const [monthFilter, setMonthFilter] = React.useState({
    StartDate: dayjs().add(-6, "months"),
    EndDate: dayjs(),
    mode: "month",
  });
  const [yearFilter, setYearFilter] = React.useState({
    StartDate: dayjs(),
    EndDate: dayjs(),
    mode: "year",
  });
  const [dataStudentCl, setdataStudentCl] = React.useState([]);
  const G = G2.getEngine("canvas");
  const [filter, setFilter] = React.useState({
    StartDate: dayjs().date(2),
    EndDate: dayjs().endOf("month"),
    StartDate_Change: dayjs(),
    EndDate_Change: dayjs(),
  });

  const [filterStudentStatus, setFilterStudentStatus] = React.useState({
    StartDate_Change: dayjs(),
    EndDate_Change: dayjs(),
  });

  React.useEffect(() => {
    getDataREInfo();
    getDataStudentInfo();
    getCurrentAmount();
    getDataStudentChart();
    getListClass();
  }, []);
  React.useEffect(() => {
    getlstReClass();
  }, [filter]);
  React.useEffect(() => {
    getLstStudentChangeStatus();
  }, [filterStudentStatus]);
  React.useEffect(() => {
    getDataREChart();
  }, [dateFilter]);
  React.useEffect(() => {
    getDataREChartMonth();
  }, [monthFilter]);
  React.useEffect(() => {
    getDataREChartYear();
  }, [yearFilter]);
  React.useEffect(() => {
    getDataStudentColumn();
  }, [datestudentFilter]);
  React.useEffect(() => {
    getDataRVColumn();
  }, [datervFilter]);
  const dateChange = (e, mode) => {
    if (mode == "month") {
      var data = { ...monthFilter };
    } else if (mode == "year") {
      var data = { ...yearFilter };
    } else {
      var data = { ...dateFilter };
    }
    data.StartDate = e ? e[0] : null;
    data.EndDate = e ? e[1] : null;
    if (mode == "month") {
      setMonthFilter(data);
    } else if (mode == "year") {
      setYearFilter(data);
    } else {
      setDateFilter(data);
    }
  };
  const getListClass = () => {
    api.get("Student/GetListClass").then((res) => {
      setlstclass(res.data);
    });
  };
  const datestudentChange = (e) => {
    var data = { ...datestudentFilter };
    data.StartDate = e ? e[0] : null;
    data.EndDate = e ? e[1] : null;
    setDatestudentFilter(data);
  };
  const dateRVChange = (e) => {
    var data = { ...datervFilter };
    data.StartDate = e ? e[0] : null;
    data.EndDate = e ? e[1] : null;
    setDatervFilter(data);
  };
  const dateReClassChange = (dates) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      StartDate: dates[0].date(2),
      EndDate: dates[1].date(2),
    }));
  };

  const dateChangeStaus = (dates) => {
    setFilterStudentStatus((prevFilter) => ({
      ...prevFilter,
      StartDate_Change: dates[0],
      EndDate_Change: dates[1],
    }));
  };

  const paramREClassChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };
  const paramStatusStudentChange = (value, name) => {
    var data = { ...filterStudentStatus };
    data[name] = value;
    setFilterStudentStatus(data);
  };

  const cfgstudent = {
    appendPadding: 10,
    data: dataStudent,
    angleField: "total_student",
    colorField: "class_name",
    radius: 0.75,
    legend: false,
    tooltip: {
      customContent: (title, data) => {
        let tooltipContent = data
          .map(
            (item) =>
              `
        <div style="padding: 10px", background-color:"` +
              item.color +
              `">       
        <div style="font-weight: bold; color: green; margin-bottom: 5px;">
        Đang học: ${item.data.total_studying}
        </div>
        <div style="font-weight: bold; color: orange; margin-bottom: 5px;">
        Tạm nghỉ: ${item.data.total_pause}
        </div>
        <div style="font-weight: bold; color: red; margin-bottom: '5px'};">
        Đã nghỉ: ${item.data.total_stop}
        </div>
        </div>
  `,
          )
          .join("");
        tooltipContent =
          `<div style="width: 100%; text-align: center; padding-top: 5px; color: black; font-weight: bold;}">${
            title ?? ""
          }</div>` + tooltipContent;
        return tooltipContent;
      },
    },
    label: {
      type: "spider",
      labelHeight: 50,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 70,
            height: 80,
            r: 5,
            text: `${data.class_name}`,
            fill: mappingData.color,
          },
        });

        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.class_name}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 25,
            text: `${data.total_student}(${(data.percent * 100).toFixed("2 ")}%)`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 700,
          },
        });

        return group;
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  const configRe = {
    data: dataRe,
    xField: "day_vouchers",
    yField: "totaldate",
    seriesField: "voucher_type",
    yAxis: {
      label: {
        formatter: (v) => formatCurrency(v),
      },
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: data.voucher_type,
          value: formatCurrency(data.totaldate),
        };
      },
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
    color: ({ voucher_type }) => {
      switch (voucher_type) {
        case "Chi":
          return "#D62A0D";
        case "Thu":
          return "#1979C9";
      }
    },
  };
  const configReYear = {
    data: dataReYear,
    xField: "year_vouchers",
    yField: "totaldate",
    seriesField: "voucher_type",
    yAxis: {
      label: {
        formatter: (v) => formatCurrency(v),
      },
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: data.voucher_type,
          value: formatCurrency(data.totaldate),
        };
      },
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
    color: ({ voucher_type }) => {
      switch (voucher_type) {
        case "Chi":
          return "#D62A0D";
        case "Thu":
          return "#1979C9";
      }
    },
  };
  const configReMonth = {
    data: dataReMonth,
    xField: "month_vouchers",
    yField: "totaldate",
    seriesField: "voucher_type",
    yAxis: {
      label: {
        formatter: (v) => formatCurrency(v),
      },
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: data.voucher_type,
          value: formatCurrency(data.totaldate),
        };
      },
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
    color: ({ voucher_type }) => {
      switch (voucher_type) {
        case "Chi":
          return "#D62A0D";
        case "Thu":
          return "#1979C9";
      }
    },
  };
  const configstudent = {
    data: dataStudentCl,
    isGroup: true,
    xField: "date",
    yField: "value",
    seriesField: "new_status",
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    color: ({ new_status }) => {
      switch (new_status) {
        case "Học sinh mới":
          return "#48db70";
        case "Học lại":
          return "#1fbcff";
        case "Tạm nghỉ":
          return "#ff8c00";
        case "Đã nghỉ":
          return "#eb3e05";
        default:
          return "yellow";
      }
    },
  };
  const annotations = [];
  each(groupBy(dataRv, "day_vouchers"), (values, k) => {
    const value = values.reduce((a, b) => a + b.value, 0);
    annotations.push({
      type: "text",
      position: [k, value],
      content: `${(value / 1000000).toFixed(1) + "tr"}`,
      style: { textAlign: "center", fontSize: 14, fill: "rgba(0,0,0,0.85)" },
      offsetY: -10,
    });
  });
  const configRv = {
    data: dataRv,
    isStack: true,
    xField: "day_vouchers",
    yField: "value",
    yAxis: {
      label: {
        formatter: (v) => formatCurrency(v),
      },
    },
    seriesField: "type",
    tooltip: {
      formatter: (data) => {
        return {
          name: data.type,
          value: formatCurrency(data.value),
        };
      },
    },

    annotations,
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
    connectedArea: {
      style: (oldStyle, element) => {
        return {
          fill: "rgba(0,0,0,0.25)",
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        };
      },
    },
  };
  const getlstReClass = () => {
    tbREClass.current.reload();
  };
  const getLstStudentChangeStatus = () => {
    tbChangeStatusStudent.current.reload();
  };
  const onGetDataFinish = (data) => {
    setTotalAmount(data.all);
  };

  const getCurrentAmount = () => {
    api.get("ReceiptsExpenses/GetCurrentAmount").then((res) => {
      setcurrentAmount(res.CurrentAmount);
      setcurrentTranferAmount(res.CurrentTranferAmount);
    });
  };
  const getDataREInfo = () => {
    api.get("Report/GetREInfoToday").then((res) => {
      setDataReToday(res.data);
    });
  };
  const getDataStudentInfo = () => {
    api.get("Report/GetStudentInfoToday").then((res) => {
      setDataStudentToday(res.data);
    });
  };
  const getDataREChart = () => {
    var post = { ...dateFilter };
    post.StartDate = post.StartDate.format();
    post.EndDate = post.EndDate.format();
    api.post("Report/GetDataForREChart", post).then((res) => {
      setData(res.data);
    });
  };
  const getDataREChartMonth = () => {
    var post = { ...monthFilter };
    post.StartDate = post.StartDate.format();
    post.EndDate = post.EndDate.format();
    api.post("Report/GetDataForREChart", post).then((res) => {
      setDataReMonth(res.data);
    });
  };
  const getDataREChartYear = () => {
    var post = { ...yearFilter };
    post.StartDate = post.StartDate.format();
    post.EndDate = post.EndDate.format();
    api.post("Report/GetDataForREChart", post).then((res) => {
      setDataReYear(res.data);
    });
  };
  const getDataStudentChart = () => {
    api.get("Report/GetDataForStudentChart").then((res) => {
      setDataStudent(res.data);
    });
  };
  const getDataStudentColumn = () => {
    var post = { ...datestudentFilter };
    post.StartDate = post.StartDate.format();
    post.EndDate = post.EndDate.format();
    api.post("Report/GetDataForStudentColumn", post).then((res) => {
      setdataStudentCl(res.data);
    });
  };
  const getDataRVColumn = () => {
    var post = { ...datervFilter };
    post.StartDate = post.StartDate.format();
    post.EndDate = post.EndDate.format();
    api.post("Report/GetDataForRevenue", post).then((res) => {
      setDataRv(res.data);
    });
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "Amount",
      key: "Amount",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Lớp",
      dataIndex: "ClassName",
      key: "ClassName",
    },
    {
      title: "Giáo viên",
      dataIndex: "teacher_name",
      key: "teacher_name",
    },
    {
      title: "Giáo trình",
      dataIndex: "level_name",
      key: "level_name",
    },
    {
      title: "Số học viên",
      dataIndex: "StudentCount",
      key: "StudentCount",
      align: "right",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Đã đóng HP",
      dataIndex: "count_att",
      key: "count_att",
      align: "right",
    },
    {
      title: "Giá trị",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
  ];

  const columns_status_student = [
    {
      title: "STT",
      dataIndex: "Amount",
      key: "Amount",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Lớp",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Học sinh",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trạng thái cũ",
      dataIndex: "oldstatus",
      key: "oldstatus",
      render: (text, record, index) => {
        switch (text) {
          case "studying":
            return <Tag color={"green"}>Đang học</Tag>;
          case "on_hole":
            return <Tag color={"orange"}>Tạm ngưng</Tag>;
          case "pandemic_break":
            return <Tag color={"red"}>Đã nghỉ</Tag>;
        }
      },
    },
    {
      title: "Trạng thái mới",
      dataIndex: "new_status",
      key: "new_status",
      render: (text, record, index) => {
        switch (text) {
          case "studying":
            return <Tag color={"green"}>Đang học</Tag>;
          case "on_hole":
            return <Tag color={"orange"}>Tạm ngưng</Tag>;
          case "pandemic_break":
            return <Tag color={"red"}>Đã nghỉ</Tag>;
        }
      },
    },
    {
      title: "Ngày chuyển",
      dataIndex: "created_date",
      key: "created_date",
      align: "center",
      render: (text, record, index) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{record.created_by}</p>
            <p style={{ whiteSpace: "nowrap" }}>{formatLongDate(text)}</p>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ overflow: "hidden" }}>
      <Row gutter={24} style={{ marginTop: 40 }}>
        <Col span={18}>
          <Card>
            <h4 style={{ marginBottom: 25, fontSize: 20 }}>Báo cáo học viên</h4>

            <Row gutter={24}>
              <Col span={6}>
                <Card>
                  <Statistic
                    title={
                      <div>
                        {" "}
                        <div style={{ color: "#EE4D2D" }}>Tổng số học viên</div>
                      </div>
                    }
                    value={dataStudent.reduce((total, obj) => total + obj.total_student, 0)}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title={
                      <div>
                        {" "}
                        <div style={{ color: "#EE4D2D" }}>Đang học</div>
                      </div>
                    }
                    value={dataStudent.reduce((total, obj) => total + obj.total_studying, 0)}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title={
                      <div>
                        {" "}
                        <div style={{ color: "#EE4D2D" }}>Tạm nghỉ</div>
                      </div>
                    }
                    value={dataStudent.reduce((total, obj) => total + obj.total_pause, 0)}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title={
                      <div>
                        {" "}
                        <div style={{ color: "#EE4D2D" }}>Đã nghỉ</div>
                      </div>
                    }
                    value={dataStudent.reduce((total, obj) => total + obj.total_stop, 0)}
                    precision={0}
                  />
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: 50 }} gutter={24}>
              <Col span={12} style={{ position: "relative" }}>
                <Pie style={{ marginTop: "20px" }} {...cfgstudent} />
                <div style={{ height: 60 }}>
                  <p
                    style={{
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                      bottom: 5,
                    }}
                  >
                    SỐ LƯỢNG HỌC VIÊN THEO LỚP
                  </p>
                </div>
              </Col>
              <Col span={12} style={{ position: "relative" }}>
                <RangePicker
                  format={"DD/MM/YYYY"}
                  value={[datestudentFilter.StartDate, datestudentFilter.EndDate]}
                  onChange={datestudentChange}
                />
                <Column style={{ marginTop: "20px" }} {...configstudent} />
                <div style={{ height: 60 }}>
                  <p
                    style={{
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                      bottom: 5,
                    }}
                  >
                    BIẾN ĐỘNG HỌC VIÊN THEO NGÀY
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={6}>
          <Card style={{ height: "100%" }}>
            <Row gutter={24}>
              <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                <TeamOutlined />
                Thống kê học viên trong ngày
              </p>
              <Col span={24}>
                <p style={{ float: "left" }}>- Mới đăng ký</p>
                <p style={{ float: "right", fontWeight: "bold" }}>{dataStudentToday.total_now}</p>
                <Divider style={{ margin: "0px" }} />
                <p style={{ float: "left" }}>- Tạm nghỉ</p>
                <p style={{ float: "right", fontWeight: "bold" }}>{dataStudentToday.total_pause}</p>
                <Divider style={{ margin: "0px" }} />
                <p style={{ float: "left" }}>- Đã nghỉ</p>
                <p style={{ float: "right", fontWeight: "bold" }}>{dataStudentToday.total_stop}</p>
                <Divider style={{ margin: "0px" }} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 20 }}>
        <Col span={18}>
          <Card style={{ height: "100%" }}>
            <h4 style={{ marginBottom: 25, fontSize: 20 }}>Báo cáo chi tiết thu chi</h4>

            <Row gutter={24}>
              <Col span={8}>
                <Card>
                  <Statistic
                    title={
                      <div>
                        {" "}
                        <div style={{ color: "#EE4D2D" }}>Doanh số hiện tại</div>
                      </div>
                    }
                    value={formatCurrency(currentAmount) + " VNĐ"}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title={
                      <div>
                        {" "}
                        <div style={{ color: "#EE4D2D" }}>Chuyển khoản</div>
                      </div>
                    }
                    value={formatCurrency(currentTranferAmount) + " VNĐ"}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title={
                      <div>
                        {" "}
                        <div style={{ color: "#EE4D2D" }}>Tiền mặt</div>
                      </div>
                    }
                    value={formatCurrency(currentAmount - currentTranferAmount) + " VNĐ"}
                    precision={0}
                  />
                </Card>
              </Col>
            </Row>

            <div>
              <Tabs defaultActiveKey="date">
                <TabPane tab="Ngày" key="date">
                  <RangePicker
                    format={"DD/MM/YYYY"}
                    value={[dateFilter.StartDate, dateFilter.EndDate]}
                    onChange={(e) => {
                      dateChange(e, "date");
                    }}
                  />
                  <Line style={{ marginTop: "20px" }} {...configRe} />
                  <p style={{ textAlign: "center" }}>THỐNG KÊ THU CHI THEO NGÀY</p>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <span>
                      Tổng thu:{" "}
                      {formatCurrency(
                        dataRe
                          .filter((x) => x.voucher_type == "Thu")
                          .reduce((partialSum, a) => partialSum + a.totaldate, 0),
                      )}{" "}
                      VNĐ
                    </span>
                    <span>
                      Tổng chi:{" "}
                      {formatCurrency(
                        dataRe
                          .filter((x) => x.voucher_type == "Chi")
                          .reduce((partialSum, a) => partialSum + a.totaldate, 0),
                      )}{" "}
                      VNĐ
                    </span>
                  </div>
                </TabPane>
                <TabPane tab="Tháng" key="month">
                  <RangePicker
                    format={"MM/YYYY"}
                    picker="month"
                    value={[monthFilter.StartDate, monthFilter.EndDate]}
                    onChange={(e) => {
                      dateChange(e, "month");
                    }}
                  />
                  <Line style={{ marginTop: "20px" }} {...configReMonth} />
                  <p style={{ textAlign: "center" }}>THỐNG KÊ THU CHI THEO THÁNG</p>
                </TabPane>
                <TabPane tab="Năm" key="year">
                  <RangePicker
                    format={"YYYY"}
                    value={[yearFilter.StartDate, yearFilter.EndDate]}
                    picker="year"
                    onChange={(e) => {
                      dateChange(e, "year");
                    }}
                  />
                  <Line style={{ marginTop: "20px" }} {...configReYear} />
                  <p style={{ textAlign: "center" }}>THỐNG KÊ THU CHI THEO NĂM</p>
                </TabPane>
              </Tabs>
            </div>
            <h4 style={{ marginBottom: 25, fontSize: 20 }}>Chi tiết doanh số</h4>
            <div>
              <RangePicker
                format={"DD/MM/YYYY"}
                value={[datervFilter.StartDate, datervFilter.EndDate]}
                onChange={dateRVChange}
              />
              <Column style={{ marginTop: "20px" }} {...configRv} />
            </div>
          </Card>
        </Col>

        <Col span={6}>
          <Card style={{ height: "100%" }}>
            <Row gutter={24}>
              <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                <EditOutlined /> Thống kê chi tiết thu chi trong ngày
              </p>
              <Col span={24}>
                {dataReToday.map((item) => (
                  <>
                    <p style={{ float: "left" }}>{item.name}</p>
                    <p style={{ float: "right", fontWeight: "bold" }}>
                      {formatCurrency(item.totalvalue) + " VNĐ"}
                    </p>
                    <Divider style={{ margin: "0px" }} />
                  </>
                ))}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card style={{ marginTop: 40 }}>
        <h4 style={{ marginBottom: 25, fontSize: 20 }}>Doanh số theo lớp</h4>
        <Row gutter={20}>
          <Col span={6}>
            <div style={{ fontWeight: "bold" }}>Ngày</div>
            <div>
              <RangePicker
                style={{ width: "100%" }}
                value={[filter.StartDate, filter.EndDate]}
                format={"MM/YYYY"}
                onChange={dateReClassChange}
                allowClear={false}
                picker="month"
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Lớp</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.Class}
                style={{
                  width: "100%",
                }}
                mode="multiple"
                onChange={(e) => {
                  paramREClassChange(e, "ClassID");
                }}
                options={lstclass}
              />
            </div>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        ></div>
        <AntTable
          bordered
          columns={columns}
          ajax="Report/GetDataReClass"
          customSearch={filter}
          ref={tbREClass}
          onGetDataFinish={onGetDataFinish}
          footer={(data) => {
            return (
              <span
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                Tổng: {formatCurrency(totalMount) + " VNĐ"}
              </span>
            );
          }}
        ></AntTable>
      </Card>
      <Card style={{ marginTop: 40 }}>
        <h4 style={{ marginBottom: 25, fontSize: 20 }}>Biến động học sinh</h4>
        <Row gutter={20}>
          <Col span={6}>
            <div style={{ fontWeight: "bold" }}>Ngày</div>
            <div>
              <RangePicker
                style={{ width: "100%" }}
                value={[filterStudentStatus.StartDate_Change, filterStudentStatus.EndDate_Change]}
                format={"DD/MM/YYYY"}
                onChange={dateChangeStaus}
                allowClear={false}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Lớp</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filterStudentStatus.Class_Change}
                style={{
                  width: "100%",
                }}
                mode="multiple"
                onChange={(e) => {
                  paramStatusStudentChange(e, "Class_Change");
                }}
                options={lstclass}
              />
            </div>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        ></div>
        <AntTable
          bordered
          columns={columns_status_student}
          ajax="Report/GetDataChangeStastusStudent"
          customSearch={filterStudentStatus}
          ref={tbChangeStatusStudent}
        ></AntTable>
      </Card>
    </div>
  );
};

export default Report;
