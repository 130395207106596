import * as React from "react";
import api from "api";
import {
  Card,
  Input,
  Form,
  message,
  Modal,
  Select,
  Tabs,
  Space,
  Button,
  Row,
  Col,
  InputNumber,
  Divider,
  DatePicker,
  Table,
} from "antd";
import dayjs from "dayjs";
import { PlusOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import AttendanceOfStudent from "component/teacher/attendance_of_student";
import { formatCurrency } from "common/Format2";
import Product from "component/product";
import PopupPrintBill from "component/print/popup_print_bill";

const { TextArea } = Input;

const PopupCollectTuition = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [tabKey, setTabKey] = React.useState("2");
  const [studentID, setStudentID] = React.useState();
  const [classID, setClassID] = React.useState();
  const [lstProduct, setListProduct] = React.useState([]);
  const [totalProductPrice, setTotalProductPrice] = React.useState(0);
  const [lstPromotion, setListPromotion] = React.useState([]);
  const [showDiscount, setShowDiscount] = React.useState(false);
  const [dataCollectTuition, setDataCollectTuition] = React.useState({});
  const [ShowDataCollectTuition, setShowDataCollectTuition] = React.useState(false);
  const [lstpaymentmethod, setlstpaymentmethod] = React.useState([]);
  const [paymentMethod, setPaymentMethod] = React.useState();
  const [paymentMethodProduct, setPaymentMethodProduct] = React.useState();
  const [checkInfoLoading, setCheckInfoLoading] = React.useState(false);
  const [tuitionBill, setTuitionBill] = React.useState();
  const [productBill, setProductBill] = React.useState();
  const [mode, setMode] = React.useState();
  const [month, setMonth] = React.useState();
  const [loadingPrint, setLoadingPrint] = React.useState(true);

  const form = React.useRef();
  const attendanceOfStudent = React.useRef();
  const popupPrintBill = React.useRef();

  const items = [
    {
      key: "2",
      label: `Đóng học phí`,
    },
    {
      key: "1",
      label: `Bán hàng`,
    },
  ];

  const items2 = [
    {
      key: "1",
      label: `Bán hàng`,
    },
  ];

  const cols = [
    {
      title: "Tên sản phẩm",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Giá",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Giá bán",
      dataIndex: "sale_price",
      key: "sale_price",
      render: (text, record, index) => {
        return (
          <InputNumber
            min={0}
            value={record.sale_price}
            disabled={productBill}
            onChange={(e) => {
              productPropertiChange(e, index, "sale_price");
            }}
            style={{ width: "100%" }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
          ></InputNumber>
        );
      },
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => {
        return (
          <InputNumber
            min={0}
            disabled={productBill}
            value={record.quantity}
            onChange={(e) => {
              productPropertiChange(e, index, "quantity");
            }}
          ></InputNumber>
        );
      },
    },
    {
      key: "action",
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              if (!productBill) {
                var lst = [...lstProduct];
                lst.splice(index, 1);
                setListProduct(lst);
              }
            }}
          >
            <MinusCircleOutlined />
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    getLstPromotion();
    getListPaymentMethod();
  }, []);

  React.useEffect(() => {
    const sum = lstProduct.reduce((accumulator, object) => {
      return accumulator + object.quantity * object.sale_price;
    }, 0);
    setTotalProductPrice(sum);
  }, [lstProduct]);

  const getListPaymentMethod = () => {
    api.get("ReceiptsExpenses/GetListPaymentMethod").then((res) => {
      setlstpaymentmethod(res.data);
    });
  };

  const checkInfoTuition = (values) => {
    setCheckInfoLoading(true);
    values.ClassID = classID;
    values.StudentID = studentID;
    api
      .post("Tuition/GetInforCollectTuition", values)
      .then((res) => {
        setShowDataCollectTuition(true);
        res.data.totalAmount =
          res.data.totalAmount == 0 ? 0 : Math.round(res.data.totalAmount / 1000) * 1000;
        setDataCollectTuition(res.data);
        setCheckInfoLoading(false);
      })
      .catch((err) => {
        message.error(err.message);
        setCheckInfoLoading(false);
      });
  };

  const createTuition = () => {
    if (paymentMethod == null) {
      message.error("Bạn chưa chọn hình thức thanh toán");
      return;
    }
    setCheckInfoLoading(true);
    var dataPost = {
      ClassID: classID,
      StudentID: studentID,
      LstVoucher: dataCollectTuition.lstVouchersuccess,
      LstPromotion: dataCollectTuition.lstPromotionSuccess,
      LstMonth: dataCollectTuition.lstTotalStudyMonth,
      MoneyDiscount: dataCollectTuition.MoneyDiscount,
      PercentDiscount: dataCollectTuition.PercentDiscount,
      TotalMoney: dataCollectTuition.totalmoney,
      TotalPrice: dataCollectTuition.totalPrice,
      TotalPercent: dataCollectTuition.totalPercent,
      TotalAmount: dataCollectTuition.totalAmount,
      PaymentMethod: paymentMethod,
    };
    api
      .post("/Tuition/CreateTuition", dataPost)
      .then((res) => {
        message.success("thành công");
        setTuitionBill({ ...res.data, student_name: res.student.name });
        props.reload();
        setCheckInfoLoading(false);
        setLoadingPrint(false);
      })
      .catch((err) => {
        message.error("Thất bại");
        setCheckInfoLoading(false);
      });
  };

  const getLstPromotion = () => {
    api.get("PromotionProgram/GetAll").then((res) => {
      setListPromotion(res.data);
    });
  };

  const close = () => {
    form.current.resetFields();
    setShowDataCollectTuition(false);
    setShowDiscount(false);
    setDataCollectTuition({});
    setVisible(false);
    setListProduct([]);
    setTuitionBill(null);
    setMode(null);
    setProductBill(null);
    setPaymentMethod(null);
    setPaymentMethodProduct(null);
  };

  const onTabChange = (key) => {
    setTabKey(key);
  };

  const calculateBill = (e, index) => {
    var data = { ...dataCollectTuition };
    data.lstTotalStudyMonth[index].total = e;
    var totalPrice = 0;
    data.lstTotalStudyMonth.forEach((element) => {
      totalPrice += element.total * element.tuition_lesson;
    });
    data.totalPrice = totalPrice;
    data.totalAmount =
      totalPrice -
      (totalPrice / 100) *
        (((data.totalmoney + data.MoneyDiscount) / totalPrice) * 100 +
          (data.totalPercent + data.PercentDiscount));
    data.totalAmount = data.totalAmount == 0 ? 0 : Math.round(data.totalAmount / 1000) * 1000;
    setDataCollectTuition(data);
  };

  const addProduct = (record) => {
    if (!productBill) {
      var lst = [...lstProduct];
      var check = lst.find((x) => x.product_id == record.id);
      if (!check) {
        lst.push({
          product_id: record.id,
          name: record.name,
          quantity: 1,
          unit_price: record.price,
          sale_price: record.price,
          class_id: classID,
          student_id: studentID,
        });
      }
      setListProduct(lst);
    }
  };

  const productPropertiChange = (e, index, name) => {
    var lst = [...lstProduct];
    lst[index][name] = e;
    setListProduct(lst);
    setTimeout(() => {}, 500);
  };

  const sellProduct = () => {
    if (paymentMethodProduct == null) {
      message.error("Bạn chưa chọn hình thức thanh toán");
      return;
    }
    setCheckInfoLoading(true);
    api
      .post("SellProduct/PaymentProduct", {
        LstProduct: lstProduct,
        ClassID: classID,
        StudentID: studentID,
        TotalPrice: totalProductPrice,
        PaymentMethod: paymentMethodProduct,
      })
      .then((res) => {
        setProductBill({ ...res.data, student_name: res.student.name });
        message.success("Thành công");
        setCheckInfoLoading(false);
        setLoadingPrint(false);
      });
  };

  const printBill = () => {
    popupPrintBill.current.open(tuitionBill, productBill, studentID, month);
  };

  React.useImperativeHandle(ref, () => ({
    open(student_id, class_id, month, mode) {
      setMode(mode);
      if (mode == "product") {
        setTabKey("1");
      } else {
        setTabKey("2");
      }
      setMonth(month);
      setVisible(true);
      setStudentID(student_id);
      setClassID(class_id);

      api
        .post(`/Tuition/GetInfoDiscountNotification`, { StudentID: student_id, LstMonth: [month] })
        .then((res) => {
          setTimeout(() => {
            // attendanceOfStudent.current.onMonthChange(month.add(-1, "month"));
            form.current.setFieldsValue({
              LstMonth: [month],
              LstVoucher: [],
              LstPromotion: res.data.lstPromotion,
              PercentDiscount: res.data.PercentDiscount,
              MoneyDiscount: res.data.MoneyDiscount,
            });
            if (
              res.data.lstPromotion.length > 0 ||
              res.data.PercentDiscount != 0 ||
              res.data.MoneyDiscount != 0
            ) {
              setShowDiscount(true);
            }
            form.current.submit();
          }, 100);
        });
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      footer={
        <Button onClick={printBill} type="primary" disabled={loadingPrint || checkInfoLoading}>
          In Bill
        </Button>
      }
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="90%"
      centered={true}
    >
      <Tabs activeKey={tabKey} items={mode == "product" ? items2 : items} onChange={onTabChange} />
      <div hidden={tabKey != "1"}>
        <Row gutter={20}>
          <Col span={12}>
            <div>
              <Product mode="add" addProduct={addProduct}></Product>
            </div>
          </Col>
          <Col span={12}>
            <Card>
              <Row style={{ marginBottom: 20 }} gutter={20}>
                <Col span={12}>
                  <div span={8}>Hình thức thanh toán</div>
                  <div span={16}>
                    <Select
                      disabled={productBill}
                      value={paymentMethodProduct}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setPaymentMethodProduct(e);
                      }}
                      options={lstpaymentmethod.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                    ></Select>
                  </div>
                </Col>
              </Row>
              <Table
                columns={cols}
                dataSource={lstProduct}
                footer={(data) => (
                  <div>
                    <b>Tổng số tiền:</b> {formatCurrency(totalProductPrice)}đ
                  </div>
                )}
              ></Table>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                <Button
                  type="primary"
                  disabled={productBill}
                  onClick={() => {
                    sellProduct();
                  }}
                  loading={checkInfoLoading}
                >
                  Tính tiền
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div hidden={tabKey != "2"}>
        <Row gutter={20}>
          <Col span={12}>
            <Card>
              <Form ref={form} onFinish={checkInfoTuition} layout="vertical" disabled={tuitionBill}>
                <div>
                  <Form.List
                    name={"LstMonth"}
                    rules={[
                      {
                        validator: (rule, value) => {
                          return new Promise((resolve, error) => {
                            if (!value || value.length == 0) {
                              error();
                            } else {
                              resolve();
                            }
                          });
                        },
                        message: "Bạn chưa chọn tháng",
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Row gutter={20}>
                          {fields.map((field, index) => (
                            <Col span={8}>
                              <Form.Item key={field.key}>
                                <Space>
                                  <Form.Item
                                    {...field}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                      {
                                        validator: (rule, value) => {
                                          return new Promise((resolve, error) => {
                                            if (value) {
                                              resolve();
                                            } else {
                                              error();
                                            }
                                          });
                                        },
                                        message: "Vui lòng chọn tháng hoặc xóa dòng này",
                                      },
                                      {
                                        validator: (rule, value) => {
                                          return new Promise((resolve, error) => {
                                            var lstMonth = form.current.getFieldValue("LstMonth");
                                            if (lstMonth) {
                                              var check = lstMonth.filter(
                                                (x) =>
                                                  x &&
                                                  x.month() == value.month() &&
                                                  x.year() == value.year()
                                              );
                                              console.log(check);
                                              if (check.length > 1) {
                                                error();
                                              } else {
                                                resolve();
                                              }
                                            } else resolve();
                                          });
                                        },
                                        message: "Tháng này đã bị trùng",
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <DatePicker picker="month"></DatePicker>
                                  </Form.Item>

                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(field.name)}
                                  />
                                </Space>
                              </Form.Item>
                            </Col>
                          ))}
                        </Row>
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{
                              width: "100%",
                            }}
                            icon={<PlusOutlined />}
                          >
                            Thêm tháng
                          </Button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
                <div>
                  <div
                    style={{ marginBottom: 20, color: "#1677ff", cursor: "pointer" }}
                    onClick={() => {
                      setShowDiscount(!showDiscount);
                    }}
                  >
                    <Space>
                      Áp dụng khuyến mãi
                      {showDiscount ? (
                        <MinusCircleOutlined onClick={() => setShowDiscount(false)} />
                      ) : (
                        <PlusCircleOutlined />
                      )}
                    </Space>
                  </div>
                  <div hidden={!showDiscount} style={{ transition: "all 0.5s" }}>
                    <Row gutter={20}>
                      <Col span={6}>
                        <div style={{ marginBottom: 8 }}> Mã voucher</div>
                        <Form.List name={"LstVoucher"}>
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item key={field.key}>
                                  <Form.Item
                                    {...field}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: "Vui lòng nhập mã voucher hoặc xóa dòng này",
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Input
                                      style={{
                                        width: "80%",
                                        marginRight: 20,
                                      }}
                                    />
                                  </Form.Item>

                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(field.name)}
                                  />
                                </Form.Item>
                              ))}

                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  style={{
                                    width: "100%",
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  Thêm mã KM
                                </Button>

                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Col>
                      <Col span={10}>
                        <Form.Item label="Chương trình khuyến mãi" name={"LstPromotion"}>
                          <Select
                            options={lstPromotion}
                            fieldNames={{ label: "name", value: "id" }}
                            mode="multiple"
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <div style={{ marginBottom: 8 }}> Khuyến mãi phát sinh</div>

                        <Row gutter={20}>
                          <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                            Phần trăm:
                          </Col>
                          <Col span={16}>
                            <Form.Item name={"PercentDiscount"} style={{ width: "100%" }}>
                              <InputNumber
                                min={0}
                                max={100}
                                style={{ width: "100%" }}
                              ></InputNumber>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={20}>
                          <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                            Tiền:
                          </Col>
                          <Col span={16}>
                            <Form.Item name={"MoneyDiscount"} style={{ width: "100%" }}>
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                step={1000}
                              ></InputNumber>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="primary"
                    loading={checkInfoLoading}
                    onClick={() => {
                      form.current.submit();
                    }}
                  >
                    Kiểm tra thông tin học phí
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <AttendanceOfStudent
              classID={classID}
              studentID={studentID}
              ref={attendanceOfStudent}
              month={month?.add(-1, "month")}
            ></AttendanceOfStudent>
          </Col>
        </Row>

        {ShowDataCollectTuition && (
          <>
            <div style={{ display: "flex" }}>
              <b className="tuiton_info">THÔNG TIN HỌC PHÍ</b>
            </div>
            <div>
              <div>
                <Row gutter={20}>
                  <Col span={12}>
                    <Card>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <h3 style={{ marginTop: 0 }}>Thông tin tiết học</h3>
                      </div>
                      <table
                        style={{
                          width: "100%",
                          border: "1px black solid",
                          borderCollapse: "collapse",
                        }}
                        align="center"
                      >
                        <tr
                          align="center"
                          style={{ border: "1px black solid", borderCollapse: "collapse" }}
                        >
                          <th style={{ border: "1px black solid", borderCollapse: "collapse" }}>
                            <b>Tháng</b>
                          </th>
                          <th style={{ border: "1px black solid", borderCollapse: "collapse" }}>
                            <b>Tổng số tiết</b>
                          </th>
                          <th style={{ border: "1px black solid", borderCollapse: "collapse" }}>
                            <b>Số tiết được nghỉ không KTHP</b>
                          </th>
                          <th style={{ border: "1px black solid", borderCollapse: "collapse" }}>
                            <b>Số tiết được nghỉ có KTHP</b>
                          </th>
                          <th style={{ border: "1px black solid", borderCollapse: "collapse" }}>
                            <b>Số tiết học </b>
                          </th>
                          <th style={{ border: "1px black solid", borderCollapse: "collapse" }}>
                            <b>Giá 1 tiết học</b>
                          </th>
                        </tr>

                        {dataCollectTuition?.lstTotalStudyMonth?.map((item, index) => {
                          return (
                            <tr
                              align="center"
                              style={{ border: "1px black solid", borderCollapse: "collapse" }}
                            >
                              <td
                                span={12}
                                style={{ border: "1px black solid", borderCollapse: "collapse" }}
                              >
                                {dayjs(item.date).format("MM/YYYY")}
                              </td>
                              <td
                                span={12}
                                style={{ border: "1px black solid", borderCollapse: "collapse" }}
                              >
                                {item.full}
                              </td>
                              <td
                                span={12}
                                style={{ border: "1px black solid", borderCollapse: "collapse" }}
                              >
                                {item.total_off_no_deduct}
                              </td>

                              <td
                                span={12}
                                style={{ border: "1px black solid", borderCollapse: "collapse" }}
                              >
                                {item.total_off_deduct}
                              </td>
                              <td
                                span={12}
                                style={{ border: "1px black solid", borderCollapse: "collapse" }}
                              >
                                <InputNumber
                                  value={item.total}
                                  disabled={tuitionBill}
                                  min={0}
                                  onChange={(e) => {
                                    calculateBill(e, index);
                                  }}
                                ></InputNumber>
                              </td>
                              <td
                                span={12}
                                style={{ border: "1px black solid", borderCollapse: "collapse" }}
                              >
                                {formatCurrency(item.tuition_lesson)}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                      <Divider></Divider>
                      <div>
                        <b>Tổng số tiền: </b> {formatCurrency(dataCollectTuition.totalPrice)} VNĐ
                      </div>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <h3 style={{ marginTop: 0 }}>Thông tin khuyến mãi</h3>
                      </div>
                      <div>
                        <Row gutter={20}>
                          <Col span={24}>
                            <table
                              style={{
                                width: "100%",
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                              align="center"
                            >
                              <tr
                                align="center"
                                style={{ border: "1px black solid", borderCollapse: "collapse" }}
                              >
                                <th
                                  style={{ border: "1px black solid", borderCollapse: "collapse" }}
                                >
                                  <b>Mã voucher</b>
                                </th>
                                <th
                                  style={{ border: "1px black solid", borderCollapse: "collapse" }}
                                >
                                  <b>Giá trị</b>
                                </th>
                              </tr>

                              {dataCollectTuition?.lstVouchersuccess?.map((item) => {
                                return (
                                  <tr
                                    align="center"
                                    style={{
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    <td
                                      span={12}
                                      style={{
                                        border: "1px black solid",
                                        borderCollapse: "collapse",
                                      }}
                                    >
                                      {item.code}
                                    </td>
                                    <td
                                      span={12}
                                      style={{
                                        border: "1px black solid",
                                        borderCollapse: "collapse",
                                      }}
                                    >{`${formatCurrency(item.value)} ${
                                      item.unit == "percent" ? "%" : "VNĐ"
                                    }`}</td>
                                  </tr>
                                );
                              })}
                              {dataCollectTuition?.lstPromotionSuccess?.map((item) => {
                                return (
                                  <tr
                                    align="center"
                                    style={{
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    <td
                                      span={12}
                                      style={{
                                        border: "1px black solid",
                                        borderCollapse: "collapse",
                                      }}
                                    >
                                      {item.name}
                                    </td>
                                    <td
                                      span={12}
                                      style={{
                                        border: "1px black solid",
                                        borderCollapse: "collapse",
                                      }}
                                    >{`${formatCurrency(item.value)} ${
                                      item.unit == "percent" ? "%" : "VNĐ"
                                    }`}</td>
                                  </tr>
                                );
                              })}
                            </table>
                          </Col>

                          <Divider></Divider>
                          <div
                            style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
                          >
                            <div style={{ marginRight: 20, width: 300 }}>
                              <Row gutter={20}>
                                <Col span={8}>Hình thức thanh toán</Col>
                                <Col span={16}>
                                  <Select
                                    value={paymentMethod}
                                    style={{ width: "100%" }}
                                    disabled={tuitionBill}
                                    onChange={(e) => {
                                      setPaymentMethod(e);
                                    }}
                                    options={lstpaymentmethod.map((item) => {
                                      return { value: item.id, label: item.name };
                                    })}
                                  ></Select>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <div>
                                <b> Phần trăm khuyến mãi phát sinh: </b>{" "}
                                {formatCurrency(dataCollectTuition.PercentDiscount)} %
                              </div>
                              <div>
                                <b> Số tiền khuyến mãi phát sinh: </b>{" "}
                                {formatCurrency(dataCollectTuition.MoneyDiscount)} VNĐ
                              </div>
                              <div>
                                <b> Tổng phần trăm khuyến mãi: </b>{" "}
                                {formatCurrency(
                                  dataCollectTuition.totalPercent +
                                    dataCollectTuition.PercentDiscount
                                )}{" "}
                                %
                              </div>
                              <div>
                                <b> Tổng số tiền khuyến mãi: </b>{" "}
                                {formatCurrency(
                                  dataCollectTuition.totalmoney + dataCollectTuition.MoneyDiscount
                                )}{" "}
                                VNĐ
                              </div>

                              <Divider></Divider>
                              <div>
                                <b> Thành tiền: </b>{" "}
                                {formatCurrency(dataCollectTuition.totalAmount)} VNĐ
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    createTuition();
                  }}
                  disabled={tuitionBill}
                  loading={checkInfoLoading}
                >
                  Thanh toán
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <PopupPrintBill ref={popupPrintBill}></PopupPrintBill>
    </Modal>
  );
});
export default PopupCollectTuition;
