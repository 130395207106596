import * as React from "react";
import api from "api";
import { Select, Row, message, Modal, Col } from "antd";

const PopupEditUserStatus = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [user, setUser] = React.useState();
  const [status, setStatus] = React.useState();
  const form = React.useRef();
  const popupNoti = React.useRef();

  const updateStatus = () => {
    if (!status) {
      message.error("Bạn chưa chọn trạng thái nhân viên");
      return;
    }
    api
      .post("/User/UpdateUserStatus", { user_name: user, Status: status })
      .then((res) => {
        message.success("Thành công");
        close();
      })
      .catch((err) => {
        message.error(err.message);
        popupNoti.current.open(err.dataErr);
      });
  };

  const close = () => {
    setVisible(false);
    props.reload();
    setStatus(null);
  };

  React.useImperativeHandle(ref, () => ({
    open(user_name) {
      api.get("/User/GetByUser?user=" + user_name).then((res) => {
        setStatus(res.data.status);
      });
      setUser(user_name);
      setVisible(true);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa trạng thái"
      okText="Lưu"
      onOk={() => {
        updateStatus();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Row gutter={20}>
        <Col span={6} style={{ display: "flex" }}>
          <b3 style={{ marginBottom: "auto", marginTop: "auto" }}>Trạng thái</b3>{" "}
        </Col>
        <Col span={18}>
          <Select
            style={{ marginLeft: "5px", width: "100%" }}
            value={status}
            onChange={(e) => {
              setStatus(e);
            }}
            options={[
              { value: "working", label: "Đang làm" },
              { value: "on_break", label: "Tạm nghỉ" },
              { value: "resting", label: "Đã nghỉ" },
            ]}
          />
        </Col>
      </Row>
    </Modal>
  );
});
export default PopupEditUserStatus;
