import React from "react";
import { Card, Table, Button, message, Tag } from "antd";
import api from "api";
import { parseJwt } from "common/jwt/jwt_helper";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { FileDoneOutlined, ArrowRightOutlined } from "@ant-design/icons";
import PopupAttendance from "./popup_attendance";

const ClassInDate = () => {
  const { date } = useParams();
  const [lstSche, setLstSche] = React.useState([]);
  const popupAttendance = React.useRef();

  React.useEffect(() => {
    var token = window.localStorage.getItem("token");
    var data = parseJwt(token);
    api
      .get("Attendance/GetListClassActionByDay?strday=" + date + "&username=" + data.user_name)
      .then((res) => {
        setLstSche(res.data);
      });
  }, []);

  const createAttendance = (class_id, shift_id) => {
    api
      .get(
        `Attendance/CreateAttendance?class_id=${class_id}&shift_id=${shift_id}&datecreate=${date}`
      )
      .then((res) => {
        popupAttendance.current.open(res.id);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const cols = [
    {
      title: "Ca học",
      dataIndex: "shift_name",
      key: "shift_name",
    },
    {
      title: "Giờ học",
      dataIndex: "start_time",
      key: "start_time",
      render: (text, record, index) => {
        return (
          <span>
            {dayjs(text).format("HH:mm") + " "}
            <ArrowRightOutlined /> {" " + dayjs(record.end_time).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "Tên lớp",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Giáo viên",
      dataIndex: "teacher_name",
      key: "teacher_name",
    },
    {
      title: "Giáo án",
      dataIndex: "level_name",
      key: "level_name",
    },
    {
      title: "Tình trạng",
      dataIndex: "is_attendanced",
      key: "is_attendanced",
      render: (text, record, index) => {
        if (record.is_attendanced) return <Tag color="green">Đã điểm danh</Tag>;
        return <Tag>Chưa điểm danh</Tag>;
      },
    },
    {
      title: "Điểm danh",
      dataIndex: "dd",
      key: "dd",
      render: (text, record, index) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              createAttendance(record.class_id, record.shift_id);
            }}
          >
            <FileDoneOutlined />
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Card>
        <Table columns={cols} dataSource={lstSche}></Table>
      </Card>
      <PopupAttendance ref={popupAttendance}></PopupAttendance>
    </div>
  );
};
export default ClassInDate;
