import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhoneVolume, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FacebookFilled } from "@ant-design/icons";
import { formatCurrency, formatShortDate } from "common/Format2";
import dayjs from "dayjs";

const NotiTuiton = React.forwardRef(({ data }, ref) => {
  return (
    <div className="bodyPrint" ref={ref}>
      <style>{`@media print {body{zoom: 94%;}}`}</style>
      {data?.data?.data?.map((item, index) => {
        return (
          <>
            <div
              className="container"
              style={{ marginTop: index % 2 == 0 ? 28 : 0, marginLeft: 15 }}
            >
              <div
                style={{
                  backgroundImage: `url("/image/logoprint.png")`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <div>
                  <strong className="header">TRUNG TÂM NGOẠI NGỮ RAINBOW</strong>
                  <p style={{ margin: "10px 0px 10px 0px" }}>
                    <FontAwesomeIcon icon={faLocationDot} /> Địa chỉ: Tân Hiệp - Tân Hội - Đức Trọng
                    - Lâm Đồng
                  </p>
                  <p style={{ margin: "10px 0px 10px 0px" }}>
                    <FontAwesomeIcon icon={faPhoneVolume} /> Số điện thoại: 02633 683 686{" "}
                  </p>
                  <p style={{ margin: "10px 0px 10px 0px" }}>
                    <FacebookFilled /> Facebook: Trung Tâm Ngoại Ngữ RAINBOW{" "}
                  </p>
                  <p style={{ margin: "10px 0px 10px 0px" }}>
                    <FontAwesomeIcon icon={faEnvelope} /> Email: rainbow2022.edu@gmail.com{" "}
                  </p>
                </div>
                <div className="slide2"></div>
                <br />
                <div className="midheader">
                  <strong style={{ fontSize: 29 }}>PHIẾU THÔNG BÁO HỌC PHÍ</strong>
                </div>
                <br />
                <div style={{ fontSize: 16 }}>
                  <p>Trung Tâm Ngoại Ngữ Rainbow kính gửi Quý phụ huynh thông báo học phí</p>{" "}
                  <p>
                    tháng
                    {` ${dayjs(data.data.month).month() + 1}`}/{dayjs(data.data.month).year()}
                  </p>
                  <p>Tên học viên: {item.name}</p>
                  <p>Lớp: {item.class_name}</p>
                  <p>
                    Số tiền:
                    {" " +
                      formatCurrency(item.total_amount) +
                      "đ " +
                      `${
                        (item.total_off_deduct &&
                          item.total_off_deduct != 0 &&
                          item.day_off_description != null &&
                          item.day_off_description != "") ||
                        (item.note != null && item.note != "")
                          ? `(${item.note != null && item.note != "" ? `${item.note}` : ""}${
                              item.total_off_deduct &&
                              item.total_off_deduct != 0 &&
                              item.day_off_description != null &&
                              item.day_off_description != ""
                                ? item.note != null && item.note != ""
                                  ? `, ${item.day_off_description}`
                                  : `${item.day_off_description}`
                                : ""
                            })`
                          : ""
                      }`}
                  </p>
                  <p>Quý phụ huynh vui lòng thanh toán học phí trước ngày 10 của tháng.</p>
                  <p>Mọi thắc mắc xin liên hệ số điện thoại 02633.683.686 hoặc Zalo 0974459057.</p>
                  <p>Xin chân thành cảm ơn và chúc Quý phụ huynh sức khỏe!</p>
                </div>
              </div>
            </div>
            {index % 2 == 0 && (
              <div className="slide" style={{ marginTop: 25, marginBottom: 25 }}></div>
            )}

            {index != 0 && index % 2 != 0 && <div className="pagebreak" />}
          </>
        );
      })}
    </div>
  );
});

export default NotiTuiton;
