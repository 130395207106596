import * as React from "react";
import api from "api";
import { Popconfirm, Button, Table, Space, Card, message } from "antd";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import PopupEditShift from "./popup_edit_shift";

const Shift = (props) => {
  const [listShift, setListShift] = React.useState([]);
  const popupEditShift = React.useRef();

  React.useEffect(() => {
    getlistShift();
  }, [props.reload]);

  const getlistShift = () => {
    api.get("Shift/GetList").then((res) => {
      setListShift(res.data);
    });
  };

  const deleteShift = (id) => {
    api.get("Shift/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getlistShift();
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "start_time",
      key: "start_time",
      render: (text, record, index) => {
        return dayjs(text).format("HH:mm");
      },
    },
    {
      title: "Thời gian kết thúc",
      dataIndex: "end_time",
      key: "end_time",
      render: (text, record, index) => {
        return dayjs(text).format("HH:mm");
      },
    },
    {
      title: "Số tiết",
      dataIndex: "lesson",
      key: "lesson",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              popupEditShift.current.open();
            }}
          >
            <PlusOutlined />
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                popupEditShift.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa quyền này không"
              placement="topRight"
              onConfirm={() => {
                deleteShift(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Table style={{ width: "100%" }} columns={col} dataSource={listShift} rowKey="id" />

      <PopupEditShift ref={popupEditShift} reload={getlistShift}></PopupEditShift>
    </Card>
  );
};

export default Shift;
