import * as React from "react";
import api from "api";
import { Select, Row, message, Modal, Col } from "antd";

const PopupEditStudentStatus = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [id, setID] = React.useState();
  const [status, setStatus] = React.useState();
  const form = React.useRef();
  const popupNoti = React.useRef();

  const updateStatus = () => {
    if (!status) {
      message.error("Bạn chưa chọn trạng thái học sinh");
      return;
    }
    api
      .post("/Student/UpdateStudentStatus", { ID: id, Status: status })
      .then((res) => {
        if (res.code == 1) message.success("Thành công");
        else message.error(res.message);
        close();
      })
      .catch((err) => {
        message.error(err.message);
        popupNoti.current.open(err.dataErr);
      });
  };

  const close = () => {
    setVisible(false);
    props.reload();
    setStatus(null);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      api.get("/Student/GetByID?id=" + id).then((res) => {
        setStatus(res.data.status);
      });
      setID(id);
      setVisible(true);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa trạng thái"
      okText="Lưu"
      onOk={() => {
        updateStatus();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Row gutter={20}>
        <Col span={6} style={{ display: "flex" }}>
          <b3 style={{ marginBottom: "auto", marginTop: "auto" }}>Trạng thái</b3>{" "}
        </Col>
        <Col span={18}>
          <Select
            style={{ marginLeft: "5px", width: "100%" }}
            value={status}
            onChange={(e) => {
              setStatus(e);
            }}
            options={[
              { value: "studying", label: "Đang học" },
              { value: "on_hole", label: "Tạm ngưng" },
              { value: "pandemic_break", label: "Đã nghỉ" },
            ]}
          />
        </Col>
      </Row>
    </Modal>
  );
});
export default PopupEditStudentStatus;
