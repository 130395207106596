import * as React from "react";
import { Modal, List, message, Radio } from "antd";
import api from "api";

const PopupAttendance = React.forwardRef((props, ref) => {
  const [data, setData] = React.useState([]);
  const [id, setId] = React.useState();
  const [cl, setCl] = React.useState({ name: "" });
  const [visible, setVisible] = React.useState(false);

  const onSwitchChange = (e, id) => {
    var dt = [...data];
    var item = dt.find((x) => x.id == id);
    item.status = e;
    setData(dt);
  };

  const save = () => {
    api
      .post("Attendance/SaveAttendanceDetail", { ID: id, Lst: data })
      .then((res) => {
        message.success("Lưu thành công");
        close();
      })
      .catch((err) => {
        message.err("Lưu thất bại");
      });
  };

  const close = () => {
    setVisible(false);
    setData([]);
    setId(null);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setId(id);
      api.get("Attendance/GetAttendanceDetailByAttendanceID?id=" + id).then((res) => {
        setData(res.data);
        setCl(res.cl);
        setVisible(true);
      });
    },
  }));
  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Điểm danh"
      onOk={save}
      okText={"Lưu"}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width={"45%"}
    >
      <List
        header={
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center", fontWeight: "bold" }}
          >
            {cl.name.toUpperCase()}
          </div>
        }
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <p style={{ margin: 0 }}>{item.name}</p>
                <p style={{ margin: 0, fontSize: 13, color: "darkred" }}>
                  {item.parent_phone_number}
                </p>
              </div>
              <div>
                <Radio.Group
                  value={item.status}
                  buttonStyle="solid"
                  onChange={(e) => {
                    onSwitchChange(e.target.value, item.id);
                  }}
                >
                  <Radio.Button value="appear">Có mặt</Radio.Button>
                  <Radio.Button value="absent">Vắng mặt</Radio.Button>
                  <Radio.Button value="permission">Có phép</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  );
});
export default PopupAttendance;
