import * as React from "react";
import { Modal, Table, message, Alert, Button } from "antd";
import api from "api";

const PopupNotification = React.forwardRef((props, ref) => {
  const [data, setData] = React.useState([]);
  const [dataPost, setDataPost] = React.useState([]);
  const [mode, setMode] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const cols = [
    {
      label: "STT",
      key: "STT",
      render: (text, record, index) => {
        return index;
      },
    },
    {
      label: "Thông báo",
      key: "message",
      dataIndex: "message",
    },
  ];

  const save = () => {
    var data = { ...dataPost, DupSchedule: true };
    api
      .post("Class/SaveSchedule", data)
      .then((res) => {
        message.success("Lưu thời khóa biểu thành công");
        close();
      })
      .catch((err) => {
        message.error("Lưu thời khóa biểu thất bại");
      });
  };

  const close = () => {
    if (props.close) props.close();
    setVisible(false);
    setData([]);
  };

  React.useImperativeHandle(ref, () => ({
    open(data) {
      setData(data);
      setMode(1);
      setVisible(true);
    },

    open2(data, dataPost) {
      setData(data);
      setDataPost(dataPost);
      setMode(2);
      setVisible(true);
    },
  }));

  if (mode == 1)
    return (
      <Modal
        maskClosable={false}
        open={visible}
        title="Thông báo"
        onCancel={close}
        footer={[
          <Button
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>,
        ]}
      >
        <Table columns={cols} dataSource={data}></Table>
      </Modal>
    );
  else
    return (
      <Modal
        maskClosable={false}
        open={visible}
        title="Thông báo"
        onOk={save}
        okText={"Lưu"}
        cancelText="Đóng"
        onCancel={() => {
          close();
        }}
      >
        <div>
          <Alert
            message="Lớp đang hoạt động bị trùng thời khóa biểu, nếu bạn vẫn muốn lưu thì lớp học này sẽ bị chuyển về trạng thái chưa hoạt động "
            type="warning"
            showIcon
          />
        </div>
        <Table columns={cols} dataSource={data}></Table>
      </Modal>
    );
});
export default PopupNotification;
