import * as React from "react";
import api from "api";
import { Row, Button, Space, Card, Select, Col, Input, Statistic, DatePicker, Tag } from "antd";
import { PrinterOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { formatCurrency, formatLongDate, formatShortDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import TuitionDiscountHistory from "./tuition_discount_history";
import TuitionHistoryDetail from "./tuition_history_detail";
import PopupPrintBill from "component/print/popup_print_bill";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const TuitionHistory = React.forwardRef((props, ref) => {
  const [lstclass, setlstclass] = React.useState([]);
  const [filter, setFilter] = React.useState({ Month: dayjs() });
  const [dataReport, setDataReport] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const tbTuitionHistory = React.useRef();
  const popupPrintBill = React.useRef();

  React.useEffect(() => {
    getListClass();
    getDataReport();
  }, []);

  const getDataReport = () => {
    setLoading(true);
    api.post("Tuition/TuitionDebt", filter).then((res) => {
      setDataReport(res.data);
      setLoading(false);
    });
  };

  const reload = (filter) => {
    setFilter(filter);
    setTimeout(() => {
      tbTuitionHistory.current.reload();
    }, 500);
  };

  React.useImperativeHandle(ref, () => ({
    reload,
  }));

  const getListClass = () => {
    api.get("Student/GetListClass").then((res) => {
      setlstclass(res.data);
    });
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const dateChange = (dates) => {
    setFilter((prevFilter) => {
      if (dates) {
        return {
          ...prevFilter,
          Start: dates[0],
          End: dates[1],
        };
      } else {
        return {
          ...prevFilter,
          Start: null,
          End: null,
        };
      }
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên học sinh",
      dataIndex: "student_name",
      key: "student_name",
      align: "center",
      render: (text, record, index) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag>{record.class_name}</Tag>
            <p>{text}</p>
          </div>
        );
      },
    },
    {
      title: "Tháng",
      dataIndex: "months",
      key: "months",
    },
    {
      title: "Giá gốc ",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Thanh toán",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "KM hệ thông",
      dataIndex: "total_discount_money",
      key: "total_discount_money",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "% KM hệ thống",
      dataIndex: "total_discount_percent",
      key: "total_discount_percent",
    },
    {
      title: "KM phát sinh",
      dataIndex: "discount_money",
      key: "discount_money",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "% KM phát sinh",
      dataIndex: "discount_percent",
      key: "discount_percent",
    },
    {
      title: "Ngày tạo",
      fixed: "right",
      dataIndex: "create_date",
      key: "create_date",
      align: "center",
      render: (text, record, index) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{record.create_by}</p>
            <p style={{ whiteSpace: "nowrap" }}>{formatLongDate(text)}</p>
          </div>
        );
      },
    },
    {
      key: "action",
      fixed: "right",
      render: (text, record, index) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              popupPrintBill.current.open(
                record,
                null,
                record.student_id,
                dayjs(record.create_date)
              );
            }}
          >
            <PrinterOutlined />
          </Button>
        );
      },
    },
  ];

  const col2 = [
    {
      title: "Lớp",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Tên học sinh",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Tháng",
      dataIndex: "months",
      key: "months",
    },
    {
      title: "Thanh toán",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },
    {
      title: "",
      key: "action",
      render: (text, record, index) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              if (props.add) props.add(record, "tuition");
            }}
          >
            <PlusCircleOutlined />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      {props.mode != "add" && (
        <>
          <Card style={{ marginBottom: 20 }}>
            <Row gutter={20}>
              <Col span={6}>
                <div>
                  <div style={{ fontWeight: "bold" }}>Lớp học</div>
                  <Select
                    fieldNames={{ label: "name", value: "id" }}
                    value={filter.ClassID}
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      paramChange(e, "ClassID");
                    }}
                    options={lstclass}
                    allowClear={true}
                    optionFilterProp={"name"}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div style={{ fontWeight: "bold" }}>Ngày tạo:</div>
                <div>
                  <RangePicker
                    style={{ width: "100%" }}
                    value={[filter.Start, filter.End]}
                    format={"DD/MM/YYYY"}
                    onChange={dateChange}
                    allowClear={true}
                    presets={[
                      {
                        label: "7 ngày trước",
                        value: [dayjs().add(-7, "d"), dayjs()],
                      },
                      {
                        label: "Tháng trước",
                        value: [
                          dayjs().add(-1, "month").date(1),
                          dayjs().add(-1, "month").endOf("month"),
                        ],
                      },
                      {
                        label: "3 Tháng trước",
                        value: [dayjs().add(-3, "month").date(1), dayjs().endOf("month")],
                      },
                      {
                        label: "Tháng này",
                        value: [dayjs().date(1), dayjs().endOf("month")],
                      },

                      {
                        label: "Năm này",
                        value: [dayjs().month(0).date(1), dayjs().endOf("month")],
                      },
                    ]}
                  />
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <div style={{ fontWeight: "bold" }}>Tháng thanh toán</div>
                  <DatePicker
                    value={filter.Month}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      paramChange(e, "Month");
                    }}
                    picker="month"
                  />
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <div style={{ fontWeight: "bold" }}>Tên học viên</div>
                  <Input
                    value={filter.Name}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      paramChange(e.target.value, "Name");
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    tbTuitionHistory.current.reload();
                    getDataReport();
                  }}
                >
                  <SearchOutlined /> Tìm kiếm
                </Button>
              </Space>
            </div>
          </Card>
          <Row gutter={8}>
            <Col span={18}>
              <Card
                style={{ marginBottom: 20 }}
                title={
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {filter?.Month
                      ? `Công nợ tháng ${filter?.Month.month() + 1}`
                      : `Công nợ tháng ${dayjs().month() + 1}`}
                  </div>
                }
              >
                <Row gutter={20}>
                  <Col span={8} style={{ textAlign: "center" }}>
                    <Statistic
                      title="Tổng tiền (Không tính giảm giá)"
                      value={formatCurrency(dataReport.total ?? 0) + " VNĐ"}
                      loading={loading}
                    />
                  </Col>
                  <Col span={8} style={{ textAlign: "center" }}>
                    <Statistic
                      title="Đã thu"
                      value={formatCurrency(dataReport.collected ?? 0) + " VNĐ"}
                      loading={loading}
                    />
                  </Col>
                  <Col span={8} style={{ textAlign: "center" }}>
                    <Statistic
                      title="Còn lại (Không tính giảm giá)"
                      value={
                        formatCurrency((dataReport.total ?? 0) - (dataReport.collected ?? 0)) +
                        " VNĐ"
                      }
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                style={{ marginBottom: 20 }}
                title={
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {filter?.Month
                      ? `Số lượng học phí tháng ${filter?.Month.month() + 1}`
                      : `Số lượng học phí tháng ${dayjs().month() + 1}`}
                  </div>
                }
              >
                <Row gutter={20}>
                  <Col span={12} style={{ textAlign: "center" }}>
                    <Statistic
                      title="Tổng học sinh"
                      value={dataReport.dataCount?.TotalStudent}
                      loading={loading}
                    />
                  </Col>
                  <Col span={12} style={{ textAlign: "center" }}>
                    <Statistic
                      title="Đã thu"
                      value={dataReport.dataCount?.PaidCount}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Card>
        <AntTable
          expandable={
            props.mode == "add"
              ? null
              : {
                  expandedRowRender: (record) => {
                    return (
                      <Row gutter={20}>
                        <Col span={12}>
                          <TuitionHistoryDetail tuitionID={record.id}></TuitionHistoryDetail>
                        </Col>
                        <Col span={12}>
                          <TuitionDiscountHistory tuitionID={record.id}></TuitionDiscountHistory>
                        </Col>
                      </Row>
                    );
                  },
                }
          }
          columns={props.mode == "add" ? col2 : col}
          ajax="Tuition/SearchTuitionHistory"
          customSearch={filter}
          ref={tbTuitionHistory}
          rowKey={"id"}
          tableName={"SearchTuitionHistory"}
          defaultCol={[{ dataIndex: "id", show: true, width: 70 }]}
        ></AntTable>
        <PopupPrintBill ref={popupPrintBill}></PopupPrintBill>
      </Card>
    </>
  );
});

export default TuitionHistory;
