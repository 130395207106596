import * as React from "react";
import api from "api";
import {
  DatePicker,
  Input,
  Form,
  message,
  Modal,
  InputNumber,
  Radio,
  Select,
  Row,
  Col,
  Button,
  Switch,
} from "antd";
import dayjs from "dayjs";

const { TextArea } = Input;

const PopupEditVoucher = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [id, setID] = React.useState();

  const form = React.useRef();

  const savePromotion = (values) => {
    if (values.Voucher.start_date) values.Voucher.start_date = values.Voucher.start_date.format();
    if (values.Voucher.end_date) values.Voucher.end_date = values.Voucher.end_date.format();
    api
      .post("/Voucher/UpdateMany", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  const createCode = () => {
    api
      .get("/Voucher/GenerateVoucherCode")
      .then((res) => {
        form.current.setFieldsValue({ Voucher: { code: res.voucher_code } });
      })
      .catch((err) => {
        console.log("err", err);
        message.error("Tạo mã thất bại");
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setID(id);
      setTimeout(() => {
        if (id) {
          api.get("/Voucher/GetByID?id=" + id).then((res) => {
            if (res.data?.start_date) res.data.start_date = dayjs(res.data.start_date);
            if (res.data?.end_date) res.data.end_date = dayjs(res.data.end_date);
            form.current.setFieldsValue({
              IsCreateMany: false,
              Voucher: res.data,
              is_active: false,
            });
          });
        } else {
          form.current.setFieldsValue({ IsCreateMany: false });
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={id ? "Chỉnh sửa voucher" : "Thêm mới voucher"}
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={savePromotion} layout="vertical">
        <Form.Item name={["Voucher", "id"]} hidden></Form.Item>

        <Form.Item name={"IsCreateMany"}>
          <Radio.Group>
            <Radio value={true}>{id ? "Chỉnh sửa" : "Thêm mới"} nhiều voucher</Radio>
            <Radio value={false}>{id ? "Chỉnh sửa" : "Thêm mới"} 1 voucher</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.IsCreateMany !== currentValues.IsCreateMany ||
            prevValues.Vocuher?.id !== currentValues.Vocuher?.id
          }
        >
          {({ getFieldValue }) => {
            var mode = getFieldValue("IsCreateMany");
            var id = getFieldValue(["Voucher", "id"]);
            if (mode && !id)
              return (
                <Form.Item
                  label={"Số lượng"}
                  name={"Quantity"}
                  rules={[{ required: true, message: "Bạn chưa nhập số lượng voucher!" }]}
                >
                  <InputNumber></InputNumber>
                </Form.Item>
              );
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.IsCreateMany !== currentValues.IsCreateMany ||
            prevValues.Vocuher?.id !== currentValues.Vocuher?.id
          }
        >
          {({ getFieldValue }) => {
            var mode = getFieldValue("IsCreateMany");
            var id = getFieldValue(["Voucher", "id"]);
            if (!mode)
              return (
                <Row gutter={20}>
                  <Col span={18}>
                    <Form.Item
                      label={"Mã voucher"}
                      name={["Voucher", "code"]}
                      rules={[
                        { required: true, message: "Nhập mã voucher!" },
                        {
                          validator: (rule, value) => {
                            return new Promise((resolve, error) => {
                              if (value.length <= 10 && value.length >= 7) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Nhập mã voucher từ 7->10 ký tự!",
                        },
                      ]}
                    >
                      <Input disabled={id}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ marginTop: 30 }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        createCode();
                      }}
                      disabled={id}
                    >
                      Tạo mã
                    </Button>
                  </Col>
                </Row>
              );
          }}
        </Form.Item>

        <Form.Item label="Kích hoạt " name={["Voucher", "is_active"]} valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item
          label="Tên "
          name={["Voucher", "name"]}
          rules={[{ required: true, message: "Nhập tên voucher!" }]}
        >
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>

        <Form.Item
          label="Loại voucher "
          name={["Voucher", "voucher_type"]}
          rules={[{ required: true, message: "Chọn loại của voucher!" }]}
        >
          <Select
            options={[
              { label: "Có thời hạn", value: "expir" },
              { label: "Vô hạn", value: "not_expir" },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.Voucher?.voucher_type !== currentValues.Voucher?.voucher_type
          }
        >
          {({ getFieldValue }) => {
            var type = getFieldValue(["Voucher", "voucher_type"]);
            if (type == "expir")
              return (
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      label="Giờ bắt đâu "
                      name={["Voucher", "start_date"]}
                      rules={[
                        { required: true, message: "Nhập thời gian bắt đầu!" },
                        {
                          validator: (rule, value) => {
                            var end = form.current.getFieldValue(["Voucher", "end_date"]);
                            return new Promise((resolve, error) => {
                              if (end == null || value < end) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc!",
                        },
                      ]}
                    >
                      <DatePicker format={"DD/MM/YY HH:mm"} showTime />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Giờ kết thúc "
                      name={["Voucher", "end_date"]}
                      rules={[
                        { required: true, message: "Nhập thời gian kết thúc!" },
                        {
                          validator: (rule, value) => {
                            var start = form.current.getFieldValue(["Voucher", "start_date"]);

                            return new Promise((resolve, error) => {
                              if (start == null || value > start) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Thời gian tan ca phải lớn hơn thời gian vào ca!",
                        },
                      ]}
                    >
                      <DatePicker format={"DD/MM/YY HH:mm"} showTime />
                    </Form.Item>
                  </Col>
                </Row>
              );
          }}
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.Voucher?.unit !== currentValues.Voucher?.unit
              }
            >
              {({ getFieldValue }) => {
                var unit = getFieldValue(["Voucher", "unit"]);
                var rule = [{ required: true, message: "Nhập giá trị voucher!" }];
                if (unit == "percent")
                  rule.push({
                    validator: (rule, value) => {
                      return new Promise((resolve, error) => {
                        if (value <= 100) {
                          resolve();
                        } else {
                          error();
                        }
                      });
                    },
                    message: "Không được vượt quá 100%!",
                  });

                return (
                  <Form.Item label={"Giá trị"} name={["Voucher", "value"]} rules={rule}>
                    {unit == "percent" ? (
                      <InputNumber min={0} max={100}></InputNumber>
                    ) : (
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        step={1000}
                      ></InputNumber>
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Đơn vị "
              name={["Voucher", "unit"]}
              rules={[{ required: true, message: "Nhập đơn vị của voucher!" }]}
            >
              <Select
                options={[
                  { label: " Phần trăm", value: "percent" },
                  { label: " VNĐ", value: "vnd" },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
export default PopupEditVoucher;
