import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, Modal } from "antd";

const ChangePassPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const form = React.useRef();

  React.useEffect(() => {}, [props.reload]);

  const changePassUser = (values) => {
    api
      .post("User/ChangePassUser", values)
      .then((res) => {
        message.success(res.message);
        setVisible(false);
        form.current.setFieldsValue({});
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(username) {
      setVisible(true);
      setTimeout(() => {
        if (username) {
          form.current.setFieldsValue({ username: username });
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={"Thay đổi mật khẩu"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
    >
      <Form ref={form} onFinish={changePassUser} name="validateOnly" layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item hidden name="username">
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Mật khẩu cũ"
              name="passwordold"
              rules={[
                {
                  required: true,
                  message: "Please input your old password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Mật khẩu mới"
              name="passwordnew"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Xác nhận mật khẩu"
              name="passwordconfirm"
              dependencies={["passwordnew"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("passwordnew") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password that you entered do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
export default ChangePassPopup;
