import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, Select, Modal, Upload, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { imageURL, uploadImageURL } from "config/index";
import axios from "axios";

const { TextArea } = Input;
const ProductPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [lstcategory, setlstcategory] = React.useState([]);
  const [lstunit, setlstunit] = React.useState([]);
  const form = React.useRef();
  const [mode, setmode] = React.useState("edit");

  const [defaultImageURL, setdefaultImageURL] = React.useState("");
  const defaultFileList = [
    {
      url: defaultImageURL,
    },
  ];

  React.useEffect(() => {
    getlstunit();
    getListCategory();
  }, [props.reload]);

  const getListCategory = () => {
    api.get("Product/GetListCategory").then((res) => {
      setlstcategory(res.lstctg);
    });
  };

  const getlstunit = () => {
    api.get("Product/GetListUnits").then((res) => {
      setlstunit(res.lstunit);
    });
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    var token = localStorage.getItem("token");
    if (!token) token = "";
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data", token },
    };
    fmData.append("image", file);
    try {
      const res = await axios.post(uploadImageURL, fmData, config);
      setdefaultImageURL(imageURL + res.data);
      form.current.setFieldsValue({ image: res.data });
      onSuccess("Ok");
    } catch (err) {
      setdefaultImageURL();
      form.current.setFieldsValue({ image: "" });
      onError({ err });
    }
  };
  const saveProduct = (values) => {
    const data = { product: values, mode };
    api.post("Product/SaveProduct", data).then((res) => {
      if (res.code == 1) {
        message.success(res.message);
        props.reload();
        setVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  React.useImperativeHandle(ref, () => ({
    open(product) {
      setVisible(true);
      setTimeout(() => {
        setmode("edit");
        if (product) {
          form.current.setFieldsValue(product);
          setdefaultImageURL(imageURL + product.image);
        } else {
          setmode("add");
          form.current.resetFields();
          setdefaultImageURL();
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      width="50%"
      open={visible}
      title={mode == "add" ? "Thêm mới sản phẩm" : "Chỉnh sửa sản phẩm"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
    >
      <Form ref={form} onFinish={saveProduct} name="validateOnly" layout="vertical">
        <Row gutter={16}>
          <Form.Item label="Mã sp" name="id" hidden>
            <Input />
          </Form.Item>
          <Col span={24}>
            <Form.Item
              label="Tên sản phẩm"
              name="name"
              rules={[{ required: true, message: "Nhập tên sản phẩm!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hình ảnh" name="image">
              <Upload
                customRequest={uploadImage}
                fileList={defaultFileList}
                maxCount={1}
                listType="picture-card"
                accept={".png, .jpg, .jpeg"}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Hình ảnh</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="category_id"
              label="Danh mục"
              rules={[{ required: true, message: "Chọn danh mục!" }]}
            >
              <Select
                placeholder="Danh mục"
                allowClear
                options={lstcategory.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="unit_id"
              label="Đơn vị tính"
              rules={[{ required: true, message: "Chọn Đơn vị tính !" }]}
            >
              <Select
                placeholder="Đơn vị tính"
                allowClear
                options={lstunit.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              ></Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Giá trị" name="price">
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => `${value} VNĐ`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "").replace(" VNĐ", "")}
                step={1000}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Mô tả " name="description">
              <TextArea rows={4} style={{ marginLeft: "5px" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
export default ProductPopup;
