import * as React from "react";
import api from "api";
import { DatePicker, Tag, Table, Col, Card, Space } from "antd";
import dayjs from "dayjs";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";

const AttendanceOfStudent = React.forwardRef((props, ref) => {
  const [lstAttendance, setLstAttendance] = React.useState([]);
  const [cols, setCols] = React.useState([]);
  const [value, setValue] = React.useState(dayjs());

  React.useEffect(() => {
    if (props.month) {
      onMonthChange(props.month);
    }
  }, [props.month]);
  const onMonthChange = (e) => {
    setValue(e);
    api
      .get(
        `/Tuition/GetAttendanceByStudent?class_id=${props.classID}&student_id=${
          props.studentID
        }&month=${e.format("YYYY/MM/DD")}`
      )
      .then((res) => {
        var cols = [
          {
            title: "Tên học sinh",
            dataIndex: "student_name",
            key: "student_name",
          },
          {
            title: "Tên lớp",
            dataIndex: "class_name",
            key: "class_name",
          },
        ];
        res.cols.forEach((element) => {
          var col = {
            title: dayjs(element, "DD/MM/YYYY").date(),
            dataIndex: element,
            key: element,
            render: function (text, record, index) {
              if (text == "appear") return <Tag color="green" icon={<CheckCircleOutlined />}></Tag>;
              if (text == "permission")
                return <Tag color="orange" icon={<MinusCircleOutlined />}></Tag>;
              if (text == "dod") return <Tag color="purple" icon={<CheckCircleOutlined />}></Tag>;
              if (text == "dond") return <Tag color="purple" icon={<StopOutlined />}></Tag>;
              if (text == "absent") return <Tag color="red" icon={<CloseCircleOutlined />}></Tag>;
            },
          };
          cols.push(col);
        });
        setCols(cols);
        setLstAttendance(res.data);
      });
  };

  React.useImperativeHandle(ref, () => ({
    onMonthChange,
  }));

  return (
    <>
      {!props.noSearch && (
        <Card style={{ marginBottom: 20 }}>
          <Col span={8}>
            <div>
              <div style={{ fontWeight: "bold" }}>Tháng điểm danh</div>
              <DatePicker
                style={{ width: "100%" }}
                picker="month"
                onChange={(e) => {
                  onMonthChange(e);
                }}
                value={value}
              ></DatePicker>{" "}
            </div>
          </Col>
        </Card>
      )}

      <Card style={{ overflowX: "scroll" }}>
        <div style={{ marginBottom: 20 }}>
          <Space>
            <Tag color="green">Có mặt</Tag>
            <Tag color="orange">Có phép</Tag>
            <Tag color="red">Vắng mặt</Tag>
            <Tag color="purple">Được nghỉ</Tag>
          </Space>
        </div>
        <Table
          style={{ width: "100%" }}
          columns={cols}
          dataSource={lstAttendance}
          pagination={false}
          rowKey="id"
        />
      </Card>
    </>
  );
});

export default AttendanceOfStudent;
