import React, { useState, useEffect } from "react";
import { InputNumber, Popconfirm, Divider } from "antd";
import { formatCurrency, formatShortDate } from "common/Format2";
import "./stylewh.css";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import * as format from "common/Format2";
import { icons } from "antd/es/image/PreviewGroup";

const ProductItem = ({ data, index, onChange, onRemove, status, type }) => {
  const [item, setItem] = useState(data);
  useEffect(() => {
    setItem(data);
  }, [data]);

  const itemChange = (name, value) => {
    if (value < 0) {
      value = 0;
    }
    const updatedItem = {
      ...item,
      [name]: value,
      total_amount: name == "quantity" ? value * item.price : item.quantity * value,
    };
    setItem(updatedItem);
    onChange(index, updatedItem);
  };

  const remove = () => {
    onRemove(index);
  };

  return (
    <>
      <tr style={{ borderTop: "1px solid" }}>
        {/* <td style={{ verticalAlign: "middle" }}>
          <div style={{ maxWidth: "130px" }}>{item.product_id}</div>
        </td> */}
        <td
          style={{
            wordBreak: "break-word",
            minWidth: "70px",
            verticalAlign: "middle",
          }}
        >
          {item.name}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "right" }}>
          {status === "complete" ? (
            <>{format.formatCurrency(item.quantity)}</>
          ) : (
            <InputNumber
              style={{ width: "100%" }}
              value={item.quantity}
              onChange={(value) => {
                itemChange("quantity", value);
              }}
            ></InputNumber>
          )}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "right" }}>
          {status == "complete" || type == "OUT" ? (
            <>{format.formatCurrency(item.price)}</>
          ) : (
            <InputNumber
              style={{ width: "100%" }}
              value={item.price}
              onChange={(value) => {
                itemChange("price", value);
              }}
              step={1000}
            ></InputNumber>
          )}
        </td>

        <td style={{ verticalAlign: "middle", textAlign: "right" }}>
          {format.formatCurrency(item.total_amount)}
        </td>
        <td style={{ verticalAlign: "inherit", textAlign: "right" }}>
          {status !== "complete" && (
            <Popconfirm title="Xóa sản phẩm" okText="Yes" cancelText="No" onConfirm={remove}>
              <a href="#">
                {" "}
                <CloseOutlined />
              </a>
            </Popconfirm>
          )}
        </td>
      </tr>
    </>
  );
};

export default ProductItem;
