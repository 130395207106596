import React from "react";
import api from "api";
import { Row, Col, Card, Modal, Empty, message, Spin } from "antd";
import TuitionHistory from "component/tuition/tuition_history";
import SellProductHistory from "component/tuition/sell_product_history";
import { formatCurrency } from "common/Format2";
import { Print, proccessPrint } from "component/print";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

let centerInfo = null;
api.get("Center/GetCenterInfo").then((res) => {
  centerInfo = res.center;
});

const PopupPrintBill = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [tuitionHistory, setTuitionHistory] = React.useState();
  const [sellProductHistory, setSellProductHistory] = React.useState();
  const [studentName, setStudentName] = React.useState();
  const [month, setMonth] = React.useState();

  const tuitionHistoryRef = React.useRef();
  const sellProductHistoryRef = React.useRef();
  const printRef = React.useRef();

  const addData = (record, type) => {
    if (type == "tuition") {
      if (sellProductHistory && sellProductHistory.student_id != record.student_id)
        message.error("Người mua không trùng khớp");
      else {
        setTuitionHistory(record);
        setStudentName(record.student_name);
      }
    } else {
      if (tuitionHistory && tuitionHistory.student_id != record.student_id)
        message.error("Người mua không trùng khớp");
      else {
        setSellProductHistory(record);
        setStudentName(record.student_name);
      }
    }
  };

  const close = () => {
    setTuitionHistory(null);
    setSellProductHistory(null);
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(th, sph, studentID, month) {
      setMonth(month);
      setTuitionHistory(th);
      setSellProductHistory(sph);
      if (th != null) setStudentName(th.student_name);
      if (sph != null) setStudentName(sph.student_name);
      setVisible(true);
      var firstDay = dayjs(new Date(month.year(), month.month(), 1)).format();
      var lastDay = dayjs(new Date(month.year(), month.month() + 1, 0)).format();
      setTimeout(() => {
        tuitionHistoryRef.current.reload({ StudentID: studentID, Start: firstDay, End: lastDay });
        sellProductHistoryRef.current.reload({
          StudentID: studentID,
          Start: firstDay,
          End: lastDay,
        });
      }, 500);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="In bill"
      okText="In"
      onOk={() => {
        var info = "";
        if (tuitionHistory) {
          if (
            (tuitionHistory.total_discount_percent && tuitionHistory.total_discount_percent != 0) ||
            (tuitionHistory.discount_percent && tuitionHistory.discount_percent != 0) ||
            (tuitionHistory.total_discount_money && tuitionHistory.total_discount_money != 0) ||
            (tuitionHistory.discount_money && tuitionHistory.discount_money != 0)
          ) {
            info = `(${
              tuitionHistory.total_discount_percent != 0 || tuitionHistory.discount_percent != 0
                ? `Khuyến mãi ${
                    tuitionHistory.total_discount_percent + tuitionHistory.discount_percent
                  }% học phí;`
                : ""
            } ${
              tuitionHistory.total_discount_money != 0 || tuitionHistory.discount_money != 0
                ? `Khuyến mãi ${formatCurrency(
                    tuitionHistory.total_discount_money + tuitionHistory.discount_money,
                  )}đ học phí;`
                : ""
            })`;
          }
        }
        info =
          formatCurrency(
            (tuitionHistory ? tuitionHistory.total_amount : 0) +
              (sellProductHistory ? sellProductHistory.price : 0),
          ).toString() +
          " VNĐ " +
          info;
        printRef.current.print({
          centerInfo: centerInfo,
          data: {
            orderNumber:
              tuitionHistory && sellProductHistory
                ? tuitionHistory.id + "_" + sellProductHistory.id
                : !tuitionHistory
                  ? sellProductHistory.id
                  : tuitionHistory.id,
            customerName: studentName,
            totalAmount: info,
            description: `${
              tuitionHistory
                ? `Học phí ${tuitionHistory.months.toLowerCase()}${sellProductHistory ? ", " : ""}`
                : ""
            }${sellProductHistory ? `SP: ${sellProductHistory.product_name}` : ""}`,
            date: month,
          },
        });
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="95%"
    >
      <Row gutter={20}>
        <Col span={12}>
          <TuitionHistory mode="add" add={addData} ref={tuitionHistoryRef}></TuitionHistory>
        </Col>
        <Col span={12}>
          <SellProductHistory
            mode="add"
            add={addData}
            ref={sellProductHistoryRef}
          ></SellProductHistory>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginTop: 20 }}>
        <Col span={12}>
          <Card style={{ position: "relative" }}>
            {tuitionHistory ? (
              <>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <CloseCircleOutlined
                    onClick={() => {
                      setTuitionHistory(null);
                    }}
                  />
                </div>
                <Row gutter={20}>
                  <Col span={6}>Tên học sinh:</Col>
                  <Col span={18}>{tuitionHistory.student_name}</Col>
                  <Col span={6}>Tháng:</Col>
                  <Col span={18}>{tuitionHistory.months}</Col>
                  <Col span={6}>Giá:</Col>
                  <Col span={18}>{formatCurrency(tuitionHistory.total_amount)}đ</Col>
                </Row>
              </>
            ) : (
              <Empty></Empty>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ position: "relative" }}>
            {sellProductHistory ? (
              <>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <CloseCircleOutlined
                    onClick={() => {
                      setSellProductHistory(null);
                    }}
                  />
                </div>
                <Row gutter={20}>
                  <Col span={6}>Tên học sinh:</Col>
                  <Col span={18}>{sellProductHistory.student_name}</Col>
                  <Col span={6}>Sản phẩm:</Col>
                  <Col span={18}>{sellProductHistory.product_name}</Col>
                  <Col span={6}>Giá:</Col>
                  <Col span={18}>{formatCurrency(sellProductHistory.price)}đ</Col>
                </Row>
              </>
            ) : (
              <Empty></Empty>
            )}
          </Card>
        </Col>
      </Row>
      <Print ref={printRef} type={"collect_tuition"}></Print>
    </Modal>
  );
});

export default PopupPrintBill;
