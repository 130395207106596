import * as React from "react";
import api from "api";
import { Select, Input, Form, message, Modal, Table, Row, Col, InputNumber } from "antd";
import { setAppLoading } from "component/common/loading";
import PopupNotification from "./popup_notification";
import dayjs from "dayjs";

const PopupEditClass = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [listUser, setListUser] = React.useState([]);
  const [listShift, setListShift] = React.useState([]);
  const [lstClassroom, setlstClassroom] = React.useState([]);
  const [lstLevel, setListLevel] = React.useState([]);
  const form = React.useRef();
  const popupNoti = React.useRef();

  React.useEffect(() => {
    console.log(listShift);
  }, [listShift]);

  const saveClass = (values) => {
    var lstSchedule = listShift.filter(
      (x) =>
        x.monday || x.tuesday || x.wednesday || x.thursday || x.friday || x.saturday || x.sunday
    );
    var dataPost = { data: values, LstSchedule: lstSchedule };
    api
      .post("/Class/Update", dataPost)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
        setListShift([]);
      })
      .catch((err) => {
        if (err.code == -2) {
          dataPost.id = err.id;
          popupNoti.current.open2(err.dataErr, dataPost);
        } else {
          message.error(err.message);
          popupNoti.current.open(err.dataErr);
        }
      });
  };

  const close = () => {
    form.current.resetFields();
    props.reload();
    setVisible(false);
  };

  const getlstuser = () => {
    api.get("User/GetListTeacher").then((res) => {
      setListUser(res.data);
    });
  };

  const getListLevel = () => {
    api.get("Class/GetListLevel").then((res) => {
      setListLevel(res.data);
    });
  };

  const getlistShift = (id) => {
    api.get("Shift/GetList").then(async (res) => {
      var lst = res.data.map((x) => {
        return {
          shift_id: x.id,
          start_time: x.start_time,
          end_time: x.end_time,
          shift_name: x.name,
        };
      });
      if (id != null) {
        var lstsche = await getListSchedule(id);
        lstsche.data.forEach((element) => {
          var index = lst.findIndex((x) => x.shift_id == element.shift_id);
          if (index >= 0) {
            lst[index] = { ...lst[index], ...element };
          }
        });
      }
      setListShift(lst);
      setAppLoading(false);
    });
  };
  const getlstClassroom = () => {
    api.get("Classroom/GetList").then((res) => {
      setlstClassroom(res.data);
    });
  };

  const chooseRoom = (id, name, value) => {
    var lst = [...listShift];
    var item = listShift.find((x) => x.shift_id == id);
    if (item) item[name] = value;
    setListShift(lst);
  };

  const getListSchedule = async (id) => {
    var res = await api.get("Class/GetScheduleByClass?id=" + id);
    return res;
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setAppLoading(true);
      setVisible(true);
      setTimeout(() => {
        getlstuser();
        getlistShift(id);
        getlstClassroom();
        getListLevel();
        if (id) {
          api.get("/Class/GetByID?id=" + id).then((res) => {
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({ status: "not_active_yet" });
        }
      }, 100);
    },
  }));

  const col = [
    {
      title: "Ca",
      dataIndex: "shift_name",
      key: "shift_name",
    },
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time",
      render: (text, record, index) => {
        return (
          dayjs(record.start_time).format("HH:mm") + " => " + dayjs(record.end_time).format("HH:mm")
        );
      },
    },
    {
      title: "Thứ 2",
      dataIndex: "monday",
      key: "monday",
      width: "12%",
      align: "center",
      render: (text, record, index) => {
        return (
          <Select
            value={record.monday}
            options={lstClassroom}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "100%" }}
            allowClear={true}
            onChange={(e) => {
              chooseRoom(record.shift_id, "monday", e);
            }}
          ></Select>
        );
      },
    },
    {
      title: "Thứ 3",
      dataIndex: "tuesday",
      key: "tuesday",
      align: "center",
      width: "12%",
      render: (text, record, index) => {
        return (
          <Select
            value={record.tuesday}
            options={lstClassroom}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "100%" }}
            allowClear={true}
            onChange={(e) => {
              chooseRoom(record.shift_id, "tuesday", e);
            }}
          ></Select>
        );
      },
    },
    {
      title: "Thứ 4",
      dataIndex: "wednesday",
      key: "wednesday",
      align: "center",
      width: "12%",
      render: (text, record, index) => {
        return (
          <Select
            value={record.wednesday}
            options={lstClassroom}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "100%" }}
            allowClear={true}
            onChange={(e) => {
              chooseRoom(record.shift_id, "wednesday", e);
            }}
          ></Select>
        );
      },
    },
    {
      title: "Thứ 5",
      dataIndex: "thursday",
      key: "thursday",
      align: "center",
      width: "12%",
      render: (text, record, index) => {
        return (
          <Select
            value={record.thursday}
            options={lstClassroom}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "100%" }}
            allowClear={true}
            onChange={(e) => {
              chooseRoom(record.shift_id, "thursday", e);
            }}
          ></Select>
        );
      },
    },
    {
      title: "Thứ 6",
      dataIndex: "friday",
      key: "friday",
      align: "center",
      width: "12%",
      render: (text, record, index) => {
        return (
          <Select
            value={record.friday}
            options={lstClassroom}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "100%" }}
            allowClear={true}
            onChange={(e) => {
              chooseRoom(record.shift_id, "friday", e);
            }}
          ></Select>
        );
      },
    },
    {
      title: "Thứ 7",
      dataIndex: "saturday",
      key: "saturday",
      align: "center",
      width: "12%",
      render: (text, record, index) => {
        return (
          <Select
            value={record.saturday}
            options={lstClassroom}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "100%" }}
            allowClear={true}
            onChange={(e) => {
              chooseRoom(record.shift_id, "saturday", e);
            }}
          ></Select>
        );
      },
    },
    {
      title: "Chủ nhật",
      dataIndex: "sunday",
      key: "sunday",
      align: "center",
      width: "12%",
      render: (text, record, index) => {
        return (
          <Select
            value={record.sunday}
            options={lstClassroom}
            fieldNames={{ label: "name", value: "id" }}
            style={{ width: "100%" }}
            allowClear={true}
            onChange={(e) => {
              chooseRoom(record.shift_id, "sunday", e);
            }}
          ></Select>
        );
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa lớp"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="90%"
    >
      <Form ref={form} onFinish={saveClass} layout="vertical">
        <Form.Item name="id" hidden></Form.Item>
        <Row gutter={20}>
          <Col span={5}>
            <Form.Item
              label="Tên "
              name="name"
              rules={[{ required: true, message: "Nhập tên ca!" }]}
            >
              <Input style={{ marginLeft: "5px" }} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Trạng thái" name="status">
              <Select
                disabled
                style={{ marginLeft: "5px" }}
                options={[
                  { value: "not_active_yet", label: "Chưa hoạt đông" },
                  { value: "is_active", label: "Đang hoạt động" },
                  { value: "finished", label: "Đã hoàn thành" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label="Giáo viên"
              name={"user_id"}
              rules={[{ required: true, message: "Chọn giáo viên!" }]}
            >
              <Select
                style={{ marginLeft: "5px" }}
                options={listUser}
                fieldNames={{ label: "name", value: "user_name" }}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label="Giáo trình"
              name="level_id"
              rules={[{ required: true, message: "Chọn cấp độ!" }]}
            >
              <Select fieldNames={{ label: "name", value: "id" }} options={lstLevel} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={"Học phí"}
              name={"tuition"}
              rules={[{ required: true, message: "Nhập học phí!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                step={1000}
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div>
        <Table columns={col} dataSource={listShift} pagination={false}></Table>
      </div>
      <PopupNotification ref={popupNoti} close={close}></PopupNotification>
    </Modal>
  );
});
export default PopupEditClass;
