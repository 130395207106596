import * as React from "react";
import api from "api";
import {
  DatePicker,
  Input,
  Form,
  message,
  Modal,
  InputNumber,
  Select,
  Row,
  Col,
  Switch,
} from "antd";
import dayjs from "dayjs";
const PopupEditPromotion = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [id, setID] = React.useState();

  const form = React.useRef();

  const savePromotion = (values) => {
    if (values.start_date) values.start_date = values.start_date.format();
    if (values.end_date) values.end_date = values.end_date.format();
    api
      .post("/PromotionProgram/Update", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setID(id);
      setTimeout(() => {
        if (id) {
          api.get("/PromotionProgram/GetByID?id=" + id).then((res) => {
            if (res.data?.start_date) res.data.start_date = dayjs(res.data.start_date);
            if (res.data?.end_date) res.data.end_date = dayjs(res.data.end_date);
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({ IsCreateMany: false });
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={id ? "Chỉnh sửa voucher" : "Thêm mới voucher"}
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={savePromotion} layout="vertical">
        <Form.Item name={"id"} hidden></Form.Item>

        <Form.Item label="Kích hoạt " name={"is_active"} valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item
          label="Tên "
          name={"name"}
          rules={[{ required: true, message: "Nhập tên voucher!" }]}
        >
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>

        <Form.Item
          label="Loại voucher "
          name={"type"}
          rules={[{ required: true, message: "Chọn loại của voucher!" }]}
        >
          <Select
            options={[
              { label: "Có thời hạn", value: "expir" },
              { label: "Vô hạn", value: "not_expir" },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) => {
            var type = getFieldValue("type");
            if (type == "expir")
              return (
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      label="Giờ bắt đâu "
                      name={"start_date"}
                      rules={[
                        { required: true, message: "Nhập thời gian bắt đầu!" },
                        {
                          validator: (rule, value) => {
                            var end = form.current.getFieldValue("end_date");
                            return new Promise((resolve, error) => {
                              if (end == null || value < end) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc!",
                        },
                      ]}
                    >
                      <DatePicker format={"DD/MM/YY HH:mm"} showTime />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Giờ kết thúc "
                      name={"end_date"}
                      rules={[
                        { required: true, message: "Nhập thời gian kết thúc!" },
                        {
                          validator: (rule, value) => {
                            var start = form.current.getFieldValue("start_date");

                            return new Promise((resolve, error) => {
                              if (start == null || value > start) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Thời gian tan ca phải lớn hơn thời gian vào ca!",
                        },
                      ]}
                    >
                      <DatePicker format={"DD/MM/YY HH:mm"} showTime defaultValue={dayjs()} />
                    </Form.Item>
                  </Col>
                </Row>
              );
          }}
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.unit !== currentValues.id}
            >
              {({ getFieldValue }) => {
                var unit = getFieldValue("unit");

                return (
                  <Form.Item
                    label={"Giá trị"}
                    name={"value"}
                    rules={[{ required: true, message: "Nhập giá trị voucher!" }]}
                  >
                    {unit == "percent" ? (
                      <InputNumber min={0} max={100}></InputNumber>
                    ) : (
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        step={1000}
                      ></InputNumber>
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Đơn vị "
              name={"unit"}
              rules={[{ required: true, message: "Nhập đơn vị của voucher!" }]}
            >
              <Select
                options={[
                  { label: " Phần trăm", value: "percent" },
                  { label: " VNĐ", value: "vnd" },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
export default PopupEditPromotion;
