import * as React from "react";
import { BellFilled } from "@ant-design/icons";
import { Badge, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import api from "api";
import realtime from "common/Realtime";
import { parseJwt } from "common/jwt/jwt_helper";
import "./style.css";

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

const Notification = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const paging = React.useRef(0);
  const hasMore = React.useRef(true);
  const originalTitle = React.useRef("RAINBOW English Center");
  const intervalID = React.useRef(null);

  const updateRead = (id) => {
    if (id) {
      var newData = [...data];
      var item = newData.find((x) => x.id == id);
      if (
        item.list_reader == null ||
        !item.list_reader.includes(parseJwt(localStorage.getItem("token")).user_name)
      ) {
        item.list_reader += "," + parseJwt(localStorage.getItem("token")).user_name;
        setData(newData);
        api.get("Notification/UpdateRead?id=" + id).then((res) => {
          setCount((count) => count - 1);
          if (item.link_redirect && window.location.pathname != item.link_redirect)
            window.location = item.link_redirect;
        });
      } else if (item.link_redirect && window.location.pathname != item.link_redirect)
        window.location = item.link_redirect;
    } else {
      var newData = [...data];
      newData.forEach((item) => {
        item.list_reader += "," + parseJwt(localStorage.getItem("token")).user_name;
      });
      setData(newData);
      api.get("Notification/UpdateRead?id=" + id).then((res) => {
        setCount(0);
      });
    }
  };

  const test = () => {
    api.get("Notification/testNoti").then((res) => {});
  };

  const flashTitle = (message) => {
    stopFlash();
    intervalID.current = setInterval(() => {
      if (document.title == originalTitle.current) document.title = message;
      else document.title = originalTitle.current;
    }, 1000);
  };
  const stopFlash = () => {
    if (intervalID.current) clearInterval(intervalID.current);
    document.title = originalTitle.current;
  };

  React.useEffect(() => {
    document.addEventListener(
      visibilityChange,
      () => {
        if (!document[hidden]) {
          stopFlash();
        }
      },
      false
    );
    realtime.subscribe("new_notification", (noti) => {
      if (
        noti.notification_to == "all" ||
        noti.notification_to == parseJwt(localStorage.getItem("token")).user_name
      ) {
        setData((data) => [{ ...noti }, ...data]);
        setCount((count) => count + 1);
        if (document[hidden]) flashTitle(noti.message);
        var audio = new Audio("/sound/noti.mp3");
        audio.play();
      }
    });
    api.get("Notification/GetListNoti?Paging=" + paging.current).then((res) => {
      if (res.data.length == 0) hasMore.current = false;
      setData(res.data);
    });

    api.get("Notification/GetCountUnRead").then((res) => {
      setCount(res.count.count);
    });
  }, []);

  return (
    <Popover
      trigger="click"
      content={
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 5,
              paddingRight: 10,
            }}
          >
            <span className="noselect" style={{ cursor: "pointer" }} onClick={() => updateRead(0)}>
              <FontAwesomeIcon icon={faCheckDouble} /> Đánh dấu tất cả đã đọc
            </span>
            {/* <span className="noselect" style={{ cursor: "pointer" }} onClick={() => test()}>
              <FontAwesomeIcon icon={faCheckDouble} /> test
            </span> */}
          </div>
          <div
            style={{
              height: 450,
              width: 320,
              overflow: "auto",
              overscrollBehavior: "contain",
            }}
          >
            <InfiniteScroll
              dataLength={data.length}
              next={() => {
                paging.current++;
                api.get("Notification/GetListNoti?Paging=" + paging.current).then((res) => {
                  if (res.data.length == 0) hasMore.current = false;
                  setData([...data, ...res.data]);
                });
              }}
              hasMore={hasMore.current}
              loader={
                <div style={{ width: "100%", textAlign: "center" }}>
                  <h4>Loading...</h4>
                </div>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Đã hết thông báo</b>
                </p>
              }
              height={450}
            >
              {data.map((i, index) => (
                <div
                  className="containerNoti"
                  style={{
                    borderRadius: 8,
                    maxHeight: 80,
                    margin: 6,
                    padding: 8,
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    backgroundColor: i.list_reader?.includes(
                      parseJwt(localStorage.getItem("token")).user_name
                    )
                      ? "#fff"
                      : "#cdcdcd",
                    cursor: "pointer",
                  }}
                  key={index}
                  onClick={() => updateRead(i.id)}
                >
                  <div className="contentNoti" style={{ width: "100%" }}>
                    {i.message}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end ",
                      fontSize: 10,
                      marginTop: 10,
                    }}
                  >
                    {dayjs(i.create_time).format("DD/MM/YYYY") == dayjs().format("DD/MM/YYYY")
                      ? dayjs(i.create_time).format("HH:mm")
                      : dayjs().date() - 1 == dayjs(i.create_time).date() &&
                        dayjs().month() == dayjs(i.create_time).month() &&
                        dayjs().year() == dayjs(i.create_time).year()
                      ? "hôm qua " + dayjs(i.create_time).format("HH:mm")
                      : dayjs(i.create_time).format("DD/MM/YYYY HH:mm")}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      }
      placement="bottom"
    >
      <Badge count={count} size={"small>"}>
        <BellFilled style={{ color: "#fff", fontSize: 26 }} onClick={stopFlash} />
      </Badge>
    </Popover>
  );
};
export default Notification;
