import * as React from "react";
import api from "api";
import {
  Row,
  Button,
  Space,
  Card,
  Select,
  Col,
  Table,
  DatePicker,
  InputNumber,
  Input,
  message,
  Drawer,
} from "antd";
import {
  PrinterOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import { Print } from "component/print";
import AttendanceOfStudent from "component/teacher/attendance_of_student";
import dayjs from "dayjs";

const TuitionNotification = React.forwardRef((props, ref) => {
  const [lstclass, setlstclass] = React.useState([]);
  const [filter, setFilter] = React.useState({ Month: dayjs().add(1, "month") });
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [missingLesson, setMissingLesson] = React.useState(0);
  const [studentName, setStudentName] = React.useState("");
  const [dataSelect, setDataSelect] = React.useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [studentApply, setStudentApply] = React.useState({});
  const [lstPromotion, setListPromotion] = React.useState([]);
  const [lstError, setLstError] = React.useState([]);
  const [dataPromotion, setDataPromotion] = React.useState({
    program: { totalPercent: 0, totalAmount: 0, lstProgram: [] },
    percent: 0,
    amount: 0,
  });
  const [centerInfo, setCenterInfo] = React.useState({});
  const [lstDataPromotion, setLstDataPromotion] = React.useState([]);

  const printRef = React.useRef();

  React.useEffect(() => {
    getListClass();
    getListData();
    getLstPromotion();
  }, []);

  React.useEffect(() => {
    getListData();
    api.get("Center/GetCenterInfo").then((res) => {
      setCenterInfo(res.center);
    });
  }, [filter.Month]);

  const getListClass = () => {
    api.get("Student/GetListClass").then((res) => {
      setlstclass(res.data);
    });
  };

  const getLstPromotion = () => {
    api.get("PromotionProgram/GetAll").then((res) => {
      setListPromotion(res.data);
    });
  };

  const getListData = () => {
    setLoading(true);
    api.post("Tuition/TuitionNotification", filter).then((res) => {
      setData(res.data);
      setLoading(false);
      setDataSelect([]);
    });
  };

  const onRowSelect = (record, selected, selectedRows, nativeEvent) => {
    var dataSe = [...dataSelect];
    if (selected) {
      var check = dataSe.find((x) => x == record.id);
      if (!check) dataSe.push(record.id);
    } else dataSe = dataSe.filter((x) => x != record.id);
    setDataSelect(dataSe);
  };

  const onRowSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      setDataSelect(data.map((x) => x.id));
    } else setDataSelect([]);
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const realLessonChange = (value, id) => {
    calculateAmount([...lstDataPromotion], id, value);
  };

  const noteChange = (value, id) => {
    var noti = [...data];
    var a = noti.find((x) => x.id == id);
    if (a) {
      a.note = value;
    }
    setData(noti);
  };

  const col = [
    {
      title: "Lớp",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Tên học sinh",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Tổng tiết học",
      dataIndex: "full",
      key: "full",
    },
    {
      title: (
        <div>
          <p>Tiết nghỉ</p>
          <p>(Không KTHP)</p>
        </div>
      ),
      dataIndex: "total_off_no_deduct",
      key: "total_off_no_deduct",
      align: "center",
    },
    {
      title: (
        <div>
          <p>Tiết nghỉ</p>
          <p>(Có KTHP)</p>
        </div>
      ),
      dataIndex: "total_off_deduct",
      key: "total_off_deduct",
      align: "center",
    },
    {
      title: "Tiết học",
      dataIndex: "total",
      key: "total",
      render: (text, record, index) => {
        return (
          <InputNumber
            min={0}
            value={record.total}
            onChange={(e) => {
              realLessonChange(e, record.id);
            }}
          />
        );
      },
    },
    {
      title: "Giá 1 tiết học",
      dataIndex: "tuition_lesson",
      key: "tuition_lesson",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Tổng tiền",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },

    {
      title: "KM",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => {
        return (
          <div
            style={{ cursor: "pointer", color: record.promotionApply ? "red" : "black" }}
            onClick={() => {
              setOpenDrawer(true);
              setStudentApply(record);
            }}
          >
            <PlusCircleOutlined />
          </div>
        );
      },
    },
    {
      title: "Thanh toán",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: (
        <div>
          <p>Tiết vắng học</p>
          <p>tháng trước</p>
        </div>
      ),
      dataIndex: "missing_count",
      key: "missing_count",
      align: "center",
    },
    {
      title: "Ghi chú ngày nghỉ",
      dataIndex: "day_off_description",
      key: "day_off_description",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      render: (text, record, index) => {
        return (
          <Input
            value={record.note}
            onChange={(e) => {
              noteChange(e.target.value, record.id);
            }}
          />
        );
      },
    },
  ];

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDataPromotion({
      program: { totalPercent: 0, totalAmount: 0, lstProgram: [] },
      percent: 0,
      amount: 0,
    });
    setLstError([]);
  };

  const proccessPrint = () => {
    setLoading(true);
    var dataPost = {
      month: filter.Month.month() + 1,
      year: filter.Month.year(),
      student_data: dataSelect.length != 0 ? data.filter((x) => dataSelect.includes(x.id)) : data,
    };
    api.post(`/Tuition/SaveStudentTuitonLock`, dataPost).then((res) => {
      setLoading(false);
      if (res.code == 1)
        printRef.current.print({
          data: {
            data: dataSelect.length != 0 ? data.filter((x) => dataSelect.includes(x.id)) : data,
            month: filter.Month,
          },
          centerInfo: centerInfo,
        });
      else message(res.message);
    });
  };

  const changeDataPromotion = (value, name) => {
    var data = { ...dataPromotion };
    if (name != "program") {
      data[name] = value;
    } else {
      var lstCheck = [...lstPromotion].filter((x) => value.includes(x.id));
      var lstError = lstCheck.filter(
        (x) =>
          !x.is_active ||
          (x.type != "not_expir" &&
            (dayjs(x.start_date).isAfter(dayjs().add(7, "hour")) ||
              dayjs(x.end_date).isBefore(dayjs().add(7, "hour")))),
      );
      setLstError(lstError);
      if (lstError.length > 0) {
        return false;
      } else {
        var totalPercent = lstCheck.reduce(
          (accumulator, current) => accumulator + (current.unit == "percent" ? current.value : 0),
          0,
        );

        var totalAmount = lstCheck.reduce(
          (accumulator, current) => accumulator + (current.unit == "percent" ? 0 : current.value),
          0,
        );

        data[name] = { totalPercent, totalAmount, lstProgram: [...value] };
      }
    }
    setDataPromotion(data);
  };

  const applyPromotion = () => {
    var lstPro = [...lstDataPromotion];
    var dataIndex = lstPro.findIndex((x) => x.student_id == studentApply.id);
    if (dataIndex == -1) {
      lstPro.push({ ...dataPromotion, student_id: studentApply.id });
    } else {
      lstPro[dataIndex] = { ...dataPromotion, student_id: studentApply.id };
    }

    setLstDataPromotion(lstPro);
    calculateAmount(lstPro, studentApply.id);
    onCloseDrawer();
  };

  const calculateAmount = (lstPro, student_id, value) => {
    var dataProc = [...data];
    var stdIndex = dataProc.findIndex((x) => x.id == student_id);
    var student = dataProc[stdIndex];

    student.total = value ?? student.total;
    var realAmount = student.total * student.tuition_lesson;
    student.totalDiscount = 0;
    var dataPro = [...lstPro].find((x) => x.student_id == student_id);
    if (dataPro) {
      student.totalDiscount =
        dataPro.program.totalAmount +
        dataPro.amount +
        (realAmount / 100) * (dataPro.program.totalPercent + dataPro.percent);
    }

    student.total_amount = Math.round((realAmount - (student.totalDiscount ?? 0)) / 1000) * 1000;
    student.promotionApply = student.totalDiscount != 0 ? dataPro : null;
    setData(dataProc);
  };

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={12}>
            <Card>
              <Row gutter={20}>
                <Col span={12}>
                  <div>
                    <div style={{ fontWeight: "bold" }}>Lớp học</div>
                    <Select
                      fieldNames={{ label: "name", value: "id" }}
                      value={filter.ClassID}
                      mode="multiple"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => {
                        paramChange(e, "ClassID");
                      }}
                      options={lstclass}
                      allowClear={true}
                      optionFilterProp={"name"}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <div style={{ fontWeight: "bold" }}>Tháng thanh toán</div>
                    <DatePicker
                      value={filter.Month}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => {
                        paramChange(e, "Month");
                      }}
                      picker="month"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Row gutter={20}>
                <Col span={12}>
                  <div>
                    <div style={{ fontWeight: "bold" }}>{"Số tiết vắng học(≥)"}</div>
                    <InputNumber
                      value={missingLesson}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => {
                        setMissingLesson(e);
                      }}
                      min={0}
                      picker="month"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <div style={{ fontWeight: "bold" }}>Tên học sinh</div>
                    <Input
                      value={studentName}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => {
                        setStudentName(e.target.value);
                      }}
                      picker="month"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                getListData();
              }}
              loading={loading}
            >
              <SearchOutlined /> Tìm kiếm
            </Button>
            <Button
              type="primary"
              onClick={() => {
                proccessPrint();
              }}
              loading={loading}
            >
              <PrinterOutlined /> In thông báo
            </Button>
          </Space>
        </div>
      </Card>

      <Card>
        <Table
          expandable={{
            expandedRowRender: (record) => {
              return (
                <AttendanceOfStudent
                  classID={record.class_id}
                  studentID={record.id}
                  month={filter.Month.add(-1, "month")}
                  noSearch={true}
                  loading={loading}
                ></AttendanceOfStudent>
              );
            },
          }}
          columns={col}
          customSearch={filter}
          dataSource={data.filter(
            (x) =>
              x.missing_count >= (missingLesson ?? 0) &&
              x.name.toLowerCase().includes(studentName.toLowerCase()),
          )}
          rowKey={"id"}
          footer={() => {
            return (
              <div style={{ width: "100%", textAlign: "right" }}>
                Tổng cộng: {data.length} học sinh
              </div>
            );
          }}
          rowSelection={{
            selectedRowKeys: dataSelect,
            onSelect: onRowSelect,
            onSelectAll: onRowSelectAll,
          }}
        ></Table>
        <Print ref={printRef} type={"noti_tuition"}></Print>
        <Drawer
          title={"Áp dụng khuyến mãi " + studentApply.name}
          placement={"right"}
          maskClosable={false}
          onClose={onCloseDrawer}
          open={openDrawer}
          closeIcon={false}
          extra={<CloseOutlined onClick={onCloseDrawer} />}
          footer={
            <Button type="primary" disabled={lstError.length > 0} onClick={applyPromotion}>
              Đồng ý
            </Button>
          }
          destroyOnClose={true}
        >
          <Row>
            <label>Chương trình KM</label>
            <Select
              options={lstPromotion}
              fieldNames={{ label: "name", value: "id" }}
              mode="multiple"
              style={{ width: "100%", marginTop: 5 }}
              onChange={(value) => {
                changeDataPromotion(value, "program");
              }}
            ></Select>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <label>Giám giá %</label>
            <InputNumber
              value={dataPromotion.percent}
              min={0}
              style={{ width: "100%", marginTop: 5 }}
              onChange={(e) => {
                changeDataPromotion(e, "percent");
              }}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            ></InputNumber>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <label>Giảm giá tiền mặt</label>
            <InputNumber
              value={dataPromotion.amount}
              min={0}
              style={{ width: "100%", marginTop: 5 }}
              onChange={(e) => {
                changeDataPromotion(e, "amount");
              }}
              step={10000}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            ></InputNumber>
          </Row>
          <Row style={{ marginTop: 50 }}>
            <b>Tổng áp dụng</b>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={12}>Tiền mặt</Col>
            <Col span={12}>Phần trăm</Col>
            <Col span={12}>{`${formatCurrency(
              dataPromotion.program.totalAmount + dataPromotion.amount,
            )} VNĐ`}</Col>
            <Col span={12}>{`${dataPromotion.program.totalPercent + dataPromotion.percent}%`}</Col>
          </Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "end",
              marginTop: 30,
            }}
          >
            {lstError.map((item) => (
              <p style={{ color: "red" }}>{`${item.name} không khả dụng!`}</p>
            ))}
          </div>
        </Drawer>
      </Card>
    </>
  );
});

export default TuitionNotification;
