const api_url = window.config.api_url;
const imageURL = window.config.imageURL;
const uploadImageURL = window.config.uploadImageURL;
const myDomain = window.config.myDomain;

// const api_url = "http://localhost:1000/api";
// const imageURL = "http://localhost:1000/Images/";
// const uploadImageURL = "http://localhost:1000/api/user/Image";
// const myDomain = "http://localhost:3000";

const permission = [
  {
    role: 1,
    name: "admin",
    menu: "ALL",
    defaultMenu: "/report",
  },
  {
    role: 4,
    name: "Giáo viên",
    menu: ["/student", "/schedule", "/teacher", "/attendance", "/myclass"],
    defaultMenu: "/student",
  },
  {
    role: 7,
    name: "Lễ Tân",
    menu: [
      "/student",
      "/schedule",
      "/attendance",
      "/revoucher",
      "/tuition",
      "/collect_tuition",
      "/tuition_history",
      "/tuition_notification",
      "/warehouseGroup",
      "/category",
      "/product",
      "/inventory",
      "/warehouse",
      "/sell_history",
      "/myclass",
    ],
    defaultMenu: "/student",
  },
];

export { api_url, imageURL, uploadImageURL, myDomain, permission };
