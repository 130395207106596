import * as React from "react";
import api from "api";
import { Input, Form, message, Modal, TimePicker } from "antd";
import dayjs from "dayjs";
const AttendanceTrackingPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [trackingDate, setTrackingDate] = React.useState(null);
  const [title, setTitle] = React.useState("Chỉnh sửa chấm công");
  const form = React.useRef();
  const saveTimeKeeping = (values) => {
    values.start_time = dayjs(
      trackingDate.format("MM/DD/YYYY") + " " + values.start_time.format("HH:mm:ss")
    ).format();
    values.end_time = dayjs(
      trackingDate.format("MM/DD/YYYY") + " " + values.end_time.format("HH:mm:ss")
    ).format();
    api.post("AttendanceTracking/UpdateAttendance", values).then((res) => {
      if (res.code == 1) {
        message.success(res.message);
        setVisible(false);
        props.reload();
      } else {
        message.error(res.message);
      }
    });
  };

  React.useImperativeHandle(ref, () => ({
    open(tikekeeping, user, tracking_date, role) {
      if (role != "1") return;
      setVisible(true);
      setTimeout(() => {
        if (tikekeeping) {
          setTrackingDate(dayjs(tikekeeping.start_time));
          tikekeeping.start_time = dayjs(tikekeeping.start_time || dayjs().format());
          tikekeeping.end_time = dayjs(tikekeeping.end_time || dayjs().format());
          form.current.setFieldsValue(tikekeeping);
          setTitle("Chỉnh sửa chấm công");
        } else {
          tracking_date = dayjs(tracking_date);
          setTrackingDate(tracking_date);
          form.current.setFieldsValue({
            id: null,
            user_name: user,
            start_time: tracking_date,
            end_time: tracking_date,
            tracking_date: tracking_date,
          });
          setTitle("Thêm chấm công");
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={title}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
      }}
      width="40%"
    >
      <Form ref={form} onFinish={saveTimeKeeping}>
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item name="user_name" hidden></Form.Item>
        <Form.Item name="tracking_date" hidden></Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label="Giờ bắt đâu "
            name="start_time"
            rules={[
              { required: true, message: "Nhập thời gian vào!" },
              {
                validator: (rule, value) => {
                  var end = form.current.getFieldValue("end_time");
                  return new Promise((resolve, error) => {
                    if (end == null) {
                      resolve();
                    } else {
                      const ft = dayjs(`2000-01-01 ${value.format("HH:mm")}`);
                      const tt = dayjs(`2000-01-01 ${end.format("HH:mm")}`);
                      const mins = tt.diff(ft, "minutes", true);
                      if (mins > 0) resolve();
                      error();
                    }
                  });
                },
                message: "Thời gian vào  phải nhỏ hơn thời gian ra!",
              },
            ]}
          >
            <TimePicker
              format={"HH:mm"}
              allowClear={false}
              showNow={false}
              onChange={() => {
                form.current.validateFields(["end_time"]);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Giờ kết thúc "
            name="end_time"
            rules={[
              { required: true, message: "Nhập thời gian kết thúc!" },
              {
                validator: (rule, value) => {
                  var start = form.current.getFieldValue("start_time");
                  return new Promise((resolve, error) => {
                    if (start == null) {
                      resolve();
                    } else {
                      const ft = dayjs(`2000-01-01 ${start.format("HH:mm")}`);
                      const tt = dayjs(`2000-01-01 ${value.format("HH:mm")}`);
                      const mins = tt.diff(ft, "minutes", true);
                      if (mins > 0) resolve();
                      error();
                    }
                  });
                },
                message: "Thời gian ra  phải lớn hơn thời gian vào !",
              },
            ]}
          >
            <TimePicker
              format={"HH:mm"}
              allowClear={false}
              showNow={false}
              onChange={() => {
                form.current.validateFields(["start_time"]);
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
});
export default AttendanceTrackingPopup;
