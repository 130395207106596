import * as React from "react";
import api from "api";
import {
  Card,
  Popconfirm,
  Button,
  Space,
  message,
  Row,
  Col,
  Select,
  Input,
  Tag,
  DatePicker,
} from "antd";
import PopupEditPromotion from "./popup_promotion_program";
import { EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { formatLongDate, formatCurrency } from "common/Format2";
import AntTable from "component/common/AntTable";
import dayjs from "dayjs";

const PromotionProgram = (props) => {
  const [filter, setFilter] = React.useState({});
  const promotionPopup = React.useRef();
  const tbPromotion = React.useRef();

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const deleteVoucher = (id, mode) => {
    api.get(`/PromotionProgram/Delete?id=${id}&many=${mode}`).then((res) => {
      message.success("Xóa thành công");
      getListPromotion();
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Loại",
      dataIndex: "type",
      key: "type",
      render: function (text, record, index) {
        return text == "expir" ? "Khuyến mãi theo thời gian" : "Khuyến mãi vĩnh viễn";
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "is_active",
      key: "is_active",
      render: function (text, record, index) {
        return record.is_active ? <Tag color="green">Đang hoạt động</Tag> : <Tag>Đã tạm dừng</Tag>;
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "start_date",
      key: "start_date",
      render: (text, record, index) => {
        if (text) return formatLongDate(text);
      },
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "end_date",
      key: "end_date",
      render: (text, record, index) => {
        if (text) return formatLongDate(text);
      },
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      render: (text, record, index) => {
        if (record.unit == "percent") return text + " %";
        else return formatCurrency(text) + " VNĐ";
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              promotionPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                promotionPopup.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              title="Bạn có muốn xóa chương trình khuyến mãi này không"
              placement="topRight"
              onConfirm={() => {
                deleteVoucher(record.id, false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const getListPromotion = () => {
    tbPromotion.current.reload();
  };

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái</div>
              <Select
                allowClear
                value={filter.Status}
                onChange={(e) => {
                  paramChange(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "not_active_yet", label: "Không hoạt đông" },
                  { value: "active", label: "Đang hoạt động" },
                ]}
              />
            </div>
          </Col>

          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Loại voucher</div>
              <Select
                allowClear
                value={filter.Type}
                onChange={(e) => {
                  paramChange(e, "Type");
                }}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "expir", label: "Theo thời gian" },
                  { value: "not_expir", label: "Vô hạn" },
                ]}
              />
            </div>
          </Col>

          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Đơn vị khuyến mãi</div>
              <Select
                allowClear
                value={filter.Unit}
                onChange={(e) => {
                  paramChange(e, "Unit");
                }}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "percent", label: "Phần trăm" },
                  { value: "currency", label: "VNĐ" },
                ]}
              />
            </div>
          </Col>

          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Ngày tạo</div>
              <DatePicker
                style={{ width: "100%" }}
                value={filter.CreateDate ? dayjs(filter.CreateDate, "DD/MM/YYYY") : null}
                format={"DD/MM/YYYY"}
                onChange={(e) => {
                  paramChange(e, "CreateDate");
                }}
              />
            </div>
          </Col>

          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.status}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <Button
            type="primary"
            onClick={() => {
              getListPromotion();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable
          columns={col}
          ajax="PromotionProgram/GetList"
          customSearch={filter}
          ref={tbPromotion}
        ></AntTable>
        <PopupEditPromotion ref={promotionPopup} reload={getListPromotion}></PopupEditPromotion>
      </Card>
    </>
  );
};

export default PromotionProgram;
