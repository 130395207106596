import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Space, message, Row, Col, Select, Input, Tag } from "antd";
import PopupEditclass from "./popup_edit_class";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  RotateRightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import PopupEditClassStatus from "./popup_update_status";
import realtime from "common/Realtime";
const Class = (props) => {
  const [filter, setFilter] = React.useState({});
  const [lstUser, setListUser] = React.useState([]);
  const [lstLevel, setListLevel] = React.useState([]);
  const classPopup = React.useRef();
  const classStatusPopup = React.useRef();
  const tbClass = React.useRef();

  React.useEffect(() => {
    getListLevel();
    getListTeacher();
    realtime.subscribe("hahaha", (data) => {
      console.log(data);
    });
  }, []);

  React.useEffect(() => {
    getLstClass();
  }, []);

  const getListTeacher = () => {
    api.get("User/GetListTeacher").then((res) => {
      setListUser(res.data);
    });
  };
  const getListLevel = () => {
    api.get("Class/GetListLevel").then((res) => {
      setListLevel(res.data);
    });
  };

  const getLstClass = () => {
    tbClass.current.reload();
  };

  const deleteclass = (id) => {
    api.get("Class/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getLstClass();
    });
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "not_active_yet") return <Tag>Chưa hoạt động</Tag>;
        if (text == "is_active") return <Tag color="#108ee9">Đang hoạt động</Tag>;
        if (text == "finished") return <Tag color="green">Đã hoàn thành</Tag>;
      },
    },
    {
      title: "Giáo viên",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Level",
      dataIndex: "level_name",
      key: "level_name",
    },
    {
      title: "Học phí",
      dataIndex: "tuition",
      key: "tuition",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Ngày hoàn thành",
      dataIndex: "finish_date",
      key: "finish_date",
      render: (text, record, index) => {
        if (text) return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              classPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                classPopup.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => {
                classStatusPopup.current.open(record.id);
              }}
            >
              <RotateRightOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa lớp học này không"
              placement="topRight"
              onConfirm={() => {
                deleteclass(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Level</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.LevelIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "LevelIDs");
                }}
                options={lstLevel}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Giáo viên</div>
              <Select
                fieldNames={{ label: "name", value: "user_name" }}
                value={filter.UserIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "UserIDs");
                }}
                options={lstUser}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái</div>
              <Select
                value={filter.Status}
                onChange={(e) => {
                  paramChange(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "not_active_yet", label: "Chưa hoạt đông" },
                  { value: "is_active", label: "Đang hoạt động" },
                  { value: "finished", label: "Đã hoàn thành" },
                ]}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.status}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
                onPressEnter={() => {
                  getLstClass();
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstClass();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable columns={col} ajax="Class/GetList" customSearch={filter} ref={tbClass}></AntTable>
        <PopupEditclass ref={classPopup} reload={getLstClass}></PopupEditclass>
        <PopupEditClassStatus ref={classStatusPopup} reload={getLstClass}></PopupEditClassStatus>
      </Card>
    </>
  );
};

export default Class;
