import * as React from "react";
import api from "api";
import { Card, Alert, Button, Space, Row, Col, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const Schedule = () => {
  const [lstTeacher, setLstTeacher] = React.useState([]);
  const [lstClass, setLstClass] = React.useState([]);
  const [lstClassroom, setLstClassroom] = React.useState([]);
  const [lstShiftSelect, setLstShiftSelect] = React.useState([]);
  const [lstTeacherSelect, setLstTeacherSelect] = React.useState([]);
  const [lstClassSelect, setLstClassSelect] = React.useState([]);
  const [lstClassroomSelect, setLstClassroomSelect] = React.useState([]);
  const [lstStatusSelect, setLstStatusSelect] = React.useState([]);
  const [lstDateSelect, setLstDateSelect] = React.useState([]);
  const [lstShift, setLstShift] = React.useState([]);
  const [lstShiftShow, setLstShiftShow] = React.useState();
  const [dataShow, setDataShow] = React.useState();
  const lstDateOfWeek = [
    { label: "Thứ 2", value: "monday" },
    { label: "Thứ 3", value: "tuesday" },
    { label: "Thứ 4", value: "wednesday" },
    { label: "Thứ 5", value: "thursday" },
    { label: "Thứ 6", value: "friday" },
    { label: "Thứ 7", value: "saturday" },
    { label: "Chủ nhật", value: "sunday" },
  ];

  React.useEffect(() => {
    getListTeacher();
    getListClass();
    getlstClassroom();
    getLstShift();
  }, []);

  const getListTeacher = () => {
    api.get("User/GetListTeacher").then((res) => {
      setLstTeacher(res.data);
    });
  };

  const getListClass = () => {
    api.get("/Class/GetAll").then((res) => {
      setLstClass(res.data);
    });
  };

  const getlstClassroom = () => {
    api.get("Classroom/GetList").then((res) => {
      setLstClassroom(res.data);
    });
  };

  const getLstShift = (id) => {
    api.get("Shift/GetList").then(async (res) => {
      setLstShift(res.data);
    });
  };

  const getLstSchedule = () => {
    var strDates = "";
    lstDateSelect.forEach((item) => {
      strDates += " or " + item + " is not null";
    });
    setLstShiftShow(lstShiftSelect);
    var dataPost = {
      ClassIDs: lstClassSelect,
      ShiftIDs: lstShiftSelect,
      UserNames: lstTeacherSelect,
      ClassRoom_ids: lstClassroomSelect,
      Status: lstStatusSelect,
      DateOfWeaks: strDates,
    };
    var dataShow = {};
    var lstsh =
      lstShiftSelect == null || lstShiftSelect.length == 0
        ? lstShift.map((x) => x.id)
        : lstShiftSelect;
    api.post("Schedule/GetListSchedule", dataPost).then((res) => {
      lstsh.forEach((a) => {
        var lst = res.data.filter((x) => x.shift_id == a);
        var item = {};
        lstDateOfWeek.forEach((b) => {
          item[b.value] = lst.filter((x) => x[b.value]);
        });
        dataShow["ca_" + a] = { ...item };
      });
      setDataShow(dataShow);
    });
  };
  return (
    <div>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Lớp học</div>
              <Select
                mode="multiple"
                fieldNames={{ label: "name", value: "id" }}
                value={lstClassSelect}
                onChange={(e) => {
                  setLstClassSelect(e);
                }}
                style={{
                  width: "100%",
                }}
                options={lstClass}
              />
            </div>
          </Col>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái lớp học</div>
              <Select
                mode="multiple"
                value={lstStatusSelect}
                onChange={(e) => {
                  setLstStatusSelect(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "not_active_yet", label: "Chưa hoạt đông" },
                  { value: "is_active", label: "Đang hoạt động" },
                  { value: "finished", label: "Đã hoàn thành" },
                ]}
              />
            </div>
          </Col>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Giáo viên</div>
              <Select
                fieldNames={{ label: "name", value: "user_name" }}
                value={lstTeacherSelect}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  setLstTeacherSelect(e);
                }}
                options={lstTeacher}
              />
            </div>
          </Col>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Ca học</div>
              <Select
                mode="multiple"
                fieldNames={{ label: "name", value: "id" }}
                value={lstShiftSelect}
                onChange={(e) => {
                  setLstShiftSelect(e);
                }}
                style={{
                  width: "100%",
                }}
                options={lstShift}
              />
            </div>
          </Col>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Phòng học</div>
              <Select
                mode="multiple"
                fieldNames={{ label: "name", value: "id" }}
                value={lstClassroomSelect}
                onChange={(e) => {
                  setLstClassroomSelect(e);
                }}
                style={{
                  width: "100%",
                }}
                options={lstClassroom}
              />
            </div>
          </Col>
          <Col span={4}>
            <div>
              <div style={{ fontWeight: "bold" }}>Ngày trong tuần</div>
              <Select
                mode="multiple"
                value={lstDateSelect}
                onChange={(e) => {
                  setLstDateSelect(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                options={lstDateOfWeek}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstSchedule();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <Row gutter={20} style={{ marginBottom: 20 }}>
          <Col span={8}>
            <Alert
              message="Đã hoàn tất"
              type="success"
              style={{ borderColor: "#35fc59", textAlign: "center" }}
            />
          </Col>
          <Col span={8}>
            <Alert
              message="Đang hoạt động"
              style={{ borderColor: "#1677ff", textAlign: "center" }}
            />
          </Col>
          <Col span={8}>
            <Alert
              message="Chưa hoạt động"
              style={{ borderColor: "#dfdfdf", backgroundColor: "#f1efefcf", textAlign: "center" }}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={3} style={{ border: "solid #dfdfdf 1px", padding: 10 }}></Col>
          {lstDateOfWeek.map((x) => {
            return (
              <Col span={3} style={{ border: "solid #dfdfdf 1px", padding: 10 }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  {x.label}
                </div>{" "}
              </Col>
            );
          })}
        </Row>
        {dataShow && (
          <Row gutter={20}>
            {(lstShiftShow == null || lstShiftShow.length == 0
              ? lstShift
              : lstShift.filter((x) => lstShiftShow.includes(x.id))
            ).map((x) => {
              return (
                <>
                  <Col span={3} style={{ border: "solid #dfdfdf 1px", padding: 10 }}>
                    <div style={{ fontWeight: "bold" }}>{x.name}</div>
                    <div>
                      {dayjs(x.start_time).format("HH:mm") +
                        " => " +
                        dayjs(x.end_time).format("HH:mm")}
                    </div>
                  </Col>
                  {lstDateOfWeek.map((y) => {
                    return (
                      <Col span={3} style={{ border: "solid #dfdfdf 1px", padding: 10 }}>
                        <Space direction="vertical">
                          {dataShow["ca_" + x.id][y.value].map((z) => {
                            var borderColor = "#dfdfdf";
                            if (z.class_status == "finished") borderColor = "#35fc59";
                            if (z.class_status == "is_active") borderColor = "#1677ff";
                            return (
                              <div
                                style={{
                                  border: `solid ${borderColor} 2px`,
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-evenly",
                                  flexWrap: "wrap",
                                }}
                              >
                                <div style={{ color: "#a11f37" }}>
                                  <b>Lớp:</b> {z.class_name}
                                </div>

                                <div>
                                  <b>Phòng:</b>{" "}
                                  {lstClassroom.find((cl) => cl.id == z[y.value]).name}
                                </div>
                                <div>
                                  <b>GV:</b> {z.teacher_name}
                                </div>
                              </div>
                            );
                          })}
                        </Space>
                      </Col>
                    );
                  })}
                </>
              );
            })}
          </Row>
        )}
      </Card>
    </div>
  );
};
export default Schedule;
