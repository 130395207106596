import * as React from "react";
import api from "api";
import { Input, Form, message, Modal } from "antd";
const { TextArea } = Input;
const CategoryPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const form = React.useRef();
  const [edit, setmode] = React.useState("edit");
  const saveCategory = (values) => {
    api
      .post("Category/SaveCategory", values)
      .then((res) => {
        message.success(res.message);
        props.reload();
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(role) {
      setVisible(true);
      setTimeout(() => {
        setmode("edit");
        if (role) form.current.setFieldsValue(role);
        else {
          form.current.setFieldsValue({ id: null, name: "", description: "" });
          setmode("add");
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={edit == "add" ? "Thêm mới danh mục" : "Chỉnh sửa danh mục"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} ref={form} onFinish={saveCategory}>
        <Form.Item label="Mã danh mục" name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Tên danh mục "
          name="name"
          rules={[{ required: true, message: "Nhập tên danh mục!" }]}
        >
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>
        <Form.Item label="Mô tả " name="description">
          <TextArea
            rows={4}
            style={{ marginLeft: "5px" }}
            rules={[{ required: true, message: "Nhập mô tả!" }]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default CategoryPopup;
