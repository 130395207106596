import * as React from "react";
import api from "api";
import { Input, Form, message, Modal } from "antd";
const { TextArea } = Input;
const ClassLevelPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const form = React.useRef();
  const [edit, setmode] = React.useState("edit");
  const savelevel = (values) => {
    api
      .post("ClassLevel/SaveLevel", values)
      .then((res) => {
        message.success(res.message);
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(role) {
      setVisible(true);
      setTimeout(() => {
        setmode("edit");
        if (role) form.current.setFieldsValue(role);
        else {
          form.current.setFieldsValue({ id: null, name: "", description: "" });
          setmode("add");
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={edit == "add" ? "Thêm mới giáo trình" : "Chỉnh sửa giáo trình"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        close();
      }}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 24 }} ref={form} onFinish={savelevel}>
        <Form.Item label="Mã level" name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Tên giáo trình"
          name="name"
          rules={[{ required: true, message: "Nhập tên giáo trình!" }]}
        >
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>
        <Form.Item label="Mô tả " name="description">
          <TextArea rows={4} style={{ marginLeft: "5px" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default ClassLevelPopup;
