import * as React from "react";
import api from "api";
import { TimePicker, Input, Form, message, Modal, InputNumber } from "antd";
import dayjs from "dayjs";

const { TextArea } = Input;

const PopupEditShift = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();

  const form = React.useRef();

  const saveShift = (values) => {
    values.start_time = values.start_time.format();
    values.end_time = values.end_time.format();
    api
      .post("/Shift/Update", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onDateChange = () => {
    var data = form.current.getFieldValue();
    if (data.start_time && data.end_time) {
      var diff =
        Math.abs(
          data.end_time.year(1990).month(1).date(1) - data.start_time.year(1990).month(1).date(1)
        ) / 1000;
      var value = Math.round((diff / 5400) * 100) / 100;
      form.current.setFieldsValue({ lesson: value });
    }
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setTimeout(() => {
        if (id) {
          api.get("/Shift/GetByID?id=" + id).then((res) => {
            res.data.start_time = dayjs(res.data.start_time);
            res.data.end_time = dayjs(res.data.end_time);
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa ca"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={saveShift} layout="vertical">
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item label="Tên " name="name" rules={[{ required: true, message: "Nhập tên ca!" }]}>
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            label="Giờ bắt đâu "
            name="start_time"
            rules={[
              { required: true, message: "Nhập thời gian bắt đầu!" },
              {
                validator: (rule, value) => {
                  value = value.year(1990).month(0).date(1);
                  var end = form.current.getFieldValue("end_time");
                  if (end) end = dayjs(end).year(1990).month(0).date(1);
                  return new Promise((resolve, error) => {
                    if (end == null || value < end) {
                      resolve();
                    } else {
                      error();
                    }
                  });
                },
                message: "Thời gian vào ca phải nhỏ hơn thời gian tan ca!",
              },
            ]}
          >
            <TimePicker
              format={"HH:mm"}
              onChange={() => {
                onDateChange();
              }}
            />
          </Form.Item>
          <Form.Item
            label="Giờ kết thúc "
            name="end_time"
            rules={[
              { required: true, message: "Nhập thời gian kết thúc!" },
              {
                validator: (rule, value) => {
                  value.year(1990).month(0).date(1);
                  var start = form.current.getFieldValue("start_time");
                  if (start) start = dayjs(start.year(1990).month(0).date(1));
                  return new Promise((resolve, error) => {
                    if (start == null || value > start) {
                      resolve();
                    } else {
                      error();
                    }
                  });
                },
                message: "Thời gian tan ca phải lớn hơn thời gian vào ca!",
              },
            ]}
          >
            <TimePicker
              format={"HH:mm"}
              onChange={() => {
                onDateChange();
              }}
            />
          </Form.Item>
          <Form.Item
            label="Số tiết"
            name="lesson"
            rules={[{ required: true, message: "Nhập số tiết cho ca" }]}
          >
            <InputNumber></InputNumber>
          </Form.Item>
        </div>

        <Form.Item label="Mô tả" name="description">
          <TextArea></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default PopupEditShift;
