import * as React from "react";
import api from "api";
import ProductPopup from "./product_popup";
import { Row, Popconfirm, Button, Space, Card, message, Select, Col, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import AntTable from "component/common/AntTable";
import { imageURL } from "config/index";
const Product = (props) => {
  const [lstctg, setlstctg] = React.useState([]);
  const proPopup = React.useRef();
  const tbproduct = React.useRef();
  const [filter, setFilter] = React.useState({});
  React.useEffect(() => {
    getListCtg();
  }, []);
  React.useEffect(() => {
    getlstproduct();
  }, [filter]);
  const getlstproduct = () => {
    tbproduct.current.reload();
  };
  const getListCtg = () => {
    api.get("Product/GetListCategory").then((res) => {
      setlstctg(res.lstctg);
    });
  };
  const deleteProduct = (id) => {
    api.get("Product/DeleteProduct?id=" + id).then((res) => {
      message.success(res.message);
      getlstproduct();
    });
  };
  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Hình",
      dataIndex: "image",
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={imageURL + record.image} />
          </>
        );
      },
    },

    {
      title: "Tên sản phẩm",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Danh mục",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Đơn vị tính",
      dataIndex: "unit_name",
      key: "unit_name",
    },
    {
      align: "right",
      title: "Giá",
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },

    // {
    //   title: "Ngày tạo",
    //   dataIndex: "create_date",
    //   key: "create_date",
    //   render: (text, record, index) => {
    //     return formatShortDate(text);
    //   },
    // },
    // {
    //   title: "Người tạo",
    //   dataIndex: "create_by",
    //   key: "create_by",
    // },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              proPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                proPopup.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              title="Bạn có muốn xóa sản phẩm này không?"
              placement="topRight"
              onConfirm={() => {
                deleteProduct(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const col2 = [
    {
      title: "Hình",
      dataIndex: "image",
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={imageURL + record.image} />
          </>
        );
      },
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tồn kho",
      dataIndex: "current_quantity",
      key: "current_quantity",
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      key: "action",
      render: (text, record, index) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              if (props.addProduct) props.addProduct(record);
            }}
          >
            <PlusSquareOutlined />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Danh mục</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.CategoryID}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "CategoryID");
                }}
                options={lstctg}
              />
            </div>
          </Col>

          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                style={{
                  width: "100%",
                }}
                onPressEnter={(e) => {
                  paramChange(e.target.value, "Info");
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => {
              getlstproduct();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable
          columns={props.mode == "add" ? col2 : col}
          ajax="Product/Listproduct"
          customSearch={filter}
          ref={tbproduct}
        ></AntTable>
        <ProductPopup ref={proPopup} reload={getlstproduct}></ProductPopup>
      </Card>
    </>
  );
};

export default Product;
