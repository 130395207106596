import React from "react";
import api from "api";
import { Calendar, Row, Col, Divider, Card } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { parseJwt } from "common/jwt/jwt_helper";
import { ClockCircleOutlined } from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import { useOutletContext } from "react-router-dom";
import "./style.css";

const Teacher = (props) => {
  let navigate = useNavigate();
  const [dateSelect, setDateSelect] = React.useState(dayjs());
  const [lstAtt, setLstAtt] = React.useState([]);
  const [lstSche, setLstSche] = React.useState({});
  const [sourceChange, setSourceChange] = React.useState({});
  const [teacherSelect, setTeacherSelect] = React.useState();
  const [fullUser, setFullUser] = React.useState([]);
  const [dataReport, setDataReport] = React.useState({});
  const context = useOutletContext();

  const lstDateOfWeek = [
    { value1: 1, value2: "monday" },
    { value1: 2, value2: "tuesday" },
    { value1: 3, value2: "wednesday" },
    { value1: 4, value2: "thursday" },
    { value1: 5, value2: "friday" },
    { value1: 6, value2: "saturday" },
    { value1: 0, value2: "sunday" },
  ];

  React.useEffect(() => {
    var token = window.localStorage.getItem("token");
    var data = parseJwt(token);

    if (props.teacherSelect) {
      setTeacherSelect(props.teacherSelect.user_name);
      setFullUser(props.teacherSelect);
    } else {
      setTeacherSelect(data.user_name);
      api.get("User/GetByUsername?username=" + data.user_name).then((x) => {
        setFullUser(x.data);
      });
    }
  }, []);

  React.useEffect(() => {
    if (props.teacherSelect) {
      setTeacherSelect(props.teacherSelect.user_name);
      setFullUser(props.teacherSelect);
    }
  }, [props.teacherSelect]);

  React.useEffect(() => {
    if (teacherSelect) {
      getData();
      getDataReport();
    }
  }, [teacherSelect]);

  React.useEffect(() => {
    if (sourceChange == "month") {
      getData();
      if (props.teacherSelect) getDataReport();
    }
  }, [dateSelect]);

  const getData = () => {
    api
      .get(
        `/Attendance/GetListAttendanceByMonth?date=${dateSelect.format(
          "YYYY-MM-DD"
        )}&username=${teacherSelect}`
      )
      .then((res) => {
        var data = {};
        lstDateOfWeek.forEach((item) => {
          data[item.value1.toString()] = res.lstSche.filter((x) => x[item.value2]);
        });
        setLstAtt(res.lstAtt);
        setLstSche(data);
      });
  };

  const getDataReport = () => {
    api
      .get(
        `/Attendance/ReportTeacherWageByMonth?date=${dateSelect.format(
          "YYYY-MM-DD"
        )}&username=${teacherSelect}`
      )
      .then((res) => {
        setDataReport(res.data);
      });
  };

  const renderDate = (e) => {
    e = e.hour(0).minute(0).second(0).millisecond(0);
    var now = dayjs().hour(0).minute(0).second(0).millisecond(0);

    if (e < now) {
      var data = lstAtt.filter(
        (x) => dayjs(x.create_date).format("DD/MM/YYYY") == e.format("DD/MM/YYYY")
      );
      return (
        <div>
          {data.map((ele) => {
            return (
              <div style={{ border: "1px solid #dfdfdf", padding: 10 }}>
                <b>{ele.teacher_name}</b> : <span>{ele.class_name}</span>
              </div>
            );
          })}
        </div>
      );
    } else {
      var month = e.day();
      var lstuser = [];
      (lstSche[month.toString()] ? lstSche[month.toString()] : []).forEach((ele) => {
        var e = lstuser.find((x) => x.user_id == ele.user_id);
        if (e) {
          e.class_name += "," + ele.class_name;
        } else {
          lstuser.push({
            user_id: ele.user_id,
            class_name: ele.class_name,
            teacher_name: ele.teacher_name,
          });
        }
      });
      return (
        <div>
          {lstuser.map((ele) => {
            return (
              <div style={{ border: "1px solid #dfdfdf", padding: 10 }}>
                <b>{ele.teacher_name}</b> : <span>{ele.class_name}</span>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Row gutter={16}>
      <Col span={props.teacherSelect ? 18 : 24}>
        <Card>
          <Calendar
            mode="month"
            dateCellRender={(e) => renderDate(e)}
            onSelect={(a, b) => {
              if (b.source == "date") navigate("/myclass/" + a.format("YYYY-MM-DD"));

              setSourceChange(b.source);
              setDateSelect(a);
            }}
          />
        </Card>
      </Col>
      {props.teacherSelect && (
        <Col span={6}>
          <Card style={{ height: "100%" }}>
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>
              <ClockCircleOutlined /> Công làm việc
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Tên:</p>
              <p>
                <b>{fullUser?.name}</b>
              </p>
            </div>
            <Divider style={{ margin: "0px" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Tháng:</p>
              <p>
                <b>{dateSelect.month() + 1}</b>
              </p>
            </div>
            <Divider style={{ margin: "0px" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Số ca dạy:</p>
              <p>
                <b>{dataReport?.total_shift}</b>
              </p>
            </div>
            <Divider style={{ margin: "0px" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Số tiết dạy:</p>
              <p>
                <b>{dataReport?.total_lesson}</b>
              </p>
            </div>
            <Divider style={{ margin: "0px" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Tiền lương:</p>
              <p>
                <b>{formatCurrency(dataReport?.total_wage?.toFixed(0)) + " VNĐ"}</b>
              </p>
            </div>
          </Card>
        </Col>
      )}
    </Row>
  );
};
export default Teacher;
