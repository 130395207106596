import React from "react";
import { Row, Col, Form, Input, message, Spin, Divider, Card, InputNumber } from "antd";
import api from "api";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { parseJwt } from "common/jwt/jwt_helper";
import dayjs from "dayjs";
import "./extendstyle.css";
export default function Extend(props) {
  const [loadingApp, setLoadingApp] = React.useState(false);
  const [numMonth, setNumMonth] = React.useState(1);
  const [url, setUrl] = React.useState(null);
  const [centerInfo, setCenterInfo] = React.useState({});
  const center_name = React.useRef("");
  const pka = React.useRef("");

  React.useEffect(() => {
    var token = localStorage.getItem("token");
    if (token) center_name.current = parseJwt(token).center_name;
    api.get("Center/GetCenterInfo").then((res) => {
      setCenterInfo(res.center);
    });
  }, []);

  const generateQRUrl = (pk, num) => {
    switch (pk) {
      case "Starter":
        setUrl(
          `https://img.vietqr.io/image/VCB-1012373178-print.png?accountName=LE%20NHAT%20NAM&amount=${
            150000 * (num || numMonth)
          }&addInfo=${center_name.current}%20starter%20${num || numMonth}`
        );
        break;
      case "Professional":
        setUrl(
          `https://img.vietqr.io/image/VCB-1012373178-print.png?accountName=LE%20NHAT%20NAM&amount=${
            300000 * (num || numMonth)
          }&addInfo=${center_name.current}%20Professional%20${num || numMonth}`
        );
        break;
      case "Organization":
        setUrl(
          `https://img.vietqr.io/image/VCB-1012373178-print.png?accountName=LE%20NHAT%20NAM&amount=${
            800000 * (num || numMonth)
          }&addInfo=${center_name.current}%20Organization%20${num || numMonth}`
        );
        break;
    }
  };
  return (
    <>
      <Card>
        <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          <div style={{ width: "30%" }}></div>
          <h1>BẢNG GIÁ CÁC GÓI SỬ DỤNG PHẦN MỀM</h1>
          <div style={{ width: "30%", textAlign: "right" }}>
            <span>hạn sử dụng đến: {dayjs(centerInfo.valid_to).format("DD/MM/YYYY")}</span>
          </div>
        </div>
        <hr
          style={{
            borderTop: "6px solid #6495EE",
            borderRadius: "4px",
            width: "10%",
            borderColor: "blue",
            marginBottom: "20px",
          }}
        />
        <Row>
          <Col span={18}>
            <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
              <div
                className="extend_container"
                style={{ width: "100%", backgroundColor: "lavender", borderRadius: "10px" }}
              >
                <div className="table centered">
                  <div className="row">
                    <div className="column">
                      <ul className="price">
                        <li className="header">
                          <br />
                          <br />
                          Features
                        </li>
                        <li>Số lượng học viên</li>
                        <li>Số lượng lớp học</li>
                        <li>Số lượng nhân viên</li>
                        <li>Dashboard</li>
                        <li>Yêu cầu tính năng</li>
                        <li>Khuyến mãi</li>
                      </ul>
                    </div>

                    <div className="column">
                      <ul className="price">
                        <li className="header">
                          Starter
                          <br />
                          <span>150,000</span>
                          <p>per month</p>
                          <div className="button_cont" align="center">
                            <a
                              className="btn"
                              target="_blank"
                              rel="nofollow noopener"
                              onClick={() => {
                                pka.current = "Starter";
                                generateQRUrl("Starter");
                              }}
                            >
                              Go Starter
                            </a>
                          </div>
                        </li>
                        <li>100</li>
                        <li>10</li>
                        <li>unlimited</li>
                        <li>
                          <CheckCircleOutlined />
                        </li>
                        <li>
                          <CloseCircleOutlined />
                        </li>
                        <li>
                          <CloseCircleOutlined />
                        </li>
                      </ul>
                    </div>

                    <div className="column">
                      <ul className="price">
                        <li className="header">
                          Professional
                          <br />
                          <span>300,000</span>
                          <p>per month</p>
                          <div className="button_cont" align="center">
                            <a
                              className="btn"
                              target="_blank"
                              rel="nofollow noopener"
                              onClick={() => {
                                pka.current = "Professional";
                                generateQRUrl("Professional");
                              }}
                            >
                              Go Professional
                            </a>
                          </div>
                        </li>
                        <li>300</li>
                        <li>20</li>
                        <li>unlimited</li>
                        <li>
                          <CheckCircleOutlined />
                        </li>
                        <li>
                          <CloseCircleOutlined />
                        </li>
                        <li>Tặng 1 tháng khi gia hạn 6 tháng</li>
                      </ul>
                    </div>

                    <div className="column">
                      <ul className="price">
                        <li className="header">
                          Organization
                          <br />
                          <span>800,000</span>
                          <p>per month</p>
                          <div className="button_cont" align="center">
                            <a
                              className="btn"
                              target="_blank"
                              rel="nofollow noopener"
                              onClick={() => {
                                pka.current = "Organization";
                                generateQRUrl("Organization");
                              }}
                            >
                              Go Organization
                            </a>
                          </div>
                        </li>
                        <li>unlimited</li>
                        <li>unlimited</li>
                        <li>unlimited</li>
                        <li>
                          <CheckCircleOutlined />
                        </li>
                        <li>
                          <CheckCircleOutlined />
                        </li>
                        <li>Tặng 1 tháng khi gia hạn 3 tháng</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={6}>
            {url && (
              <div style={{ height: "500px", textAlign: "right", width: "100%" }}>
                <img src={url} style={{ width: "100%", height: "100%" }} />
                <div style={{ textAlign: "center", width: "100%", paddingLeft: 20 }}>
                  <InputNumber
                    addonAfter="Tháng"
                    min={1}
                    max={76}
                    value={numMonth}
                    onChange={(value) => {
                      setNumMonth(value);
                      generateQRUrl(pka.current, value);
                    }}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Card>

      {loadingApp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999,
              backgroundColor: "#d3d1d1",
              opacity: 0.5,
            }}
          ></div>
          <div style={{ margin: "auto", zIndex: 10000 }}>
            <Spin size={"large"}></Spin>
          </div>
        </div>
      )}
    </>
  );
}
