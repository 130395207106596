import moment from "moment";
import numeral from "numeral";

const formatShortDate = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

const formatLongDate = (value) => {
  // return moment(value).format("MM/DD/YYYY h:mm:ss.SSS");
  return moment(value).format("DD/MM/YYYY HH:mm");
};

const formatCurrency = (value) => {
  return numeral(value).format("#,###");
};

const formatDecimal = (value) => {
  return numeral(value).format("#,###.##");
};

export { formatShortDate, formatLongDate, formatCurrency, formatDecimal };
