import * as React from "react";
import api from "api";
import { Input, Form, message, Modal } from "antd";
const { TextArea } = Input;
const UserRolePopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const form = React.useRef();
  const [edit, setmode] = React.useState("edit");
  const saveRole = (values) => {
    api.post("Role/SaveRole", values).then((res) => {
      if (res.code == 1) {
        message.success(res.message);
        props.reload();
        setVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  React.useImperativeHandle(ref, () => ({
    open(role) {
      setVisible(true);
      setTimeout(() => {
        setmode("edit");
        if (role) form.current.setFieldsValue(role);
        else {
          form.current.setFieldsValue({ id: null, name: "", description: "" });
          setmode("add");
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={edit == "add" ? "Thêm mới quyền" : "Chỉnh sửa quyền"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 24 }} ref={form} onFinish={saveRole}>
        <Form.Item label="Mã quyền" name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Tên quyền "
          name="name"
          rules={[{ required: true, message: "Nhập tên quyền!" }]}
        >
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>
        <Form.Item label="Mô tả " name="description">
          <TextArea rows={4} style={{ marginLeft: "5px" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default UserRolePopup;
