import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Login from "component/login";
import Role from "component/user_role/userroles";
import User from "component/user/user";
import Shift from "component/shift";
import ClassRoom from "component/class_room";
import Class from "component/class";
import ClassLevel from "component/class_level/class_level";
import Student from "component/student/student";
import Category from "component/product_categoty";
import Product from "component/product";
import Schedule from "component/schedule";
import Teacher from "component/teacher";
import ClassInDate from "component/teacher/class_in_date";
import PaymentTuitionOnMonth from "component/tuition";
import AttendanceTracking from "component/attendance_tracking";
import Voucher from "component/voucher";
import TuitionHistory from "component/tuition/tuition_history";
import TuitionNotification from "component/tuition/tuition_notification";
import Register from "component/register";
import { Print } from "component/print";
import ReceiptsExpenses from "component/receipts_expenses";
import PromotionProgram from "component/promotion_program";
import DayOff from "component/day_off";
import { parseJwt } from "common/jwt/jwt_helper";
import { permission } from "config/index";
import { WarningOutlined } from "@ant-design/icons";
import Warehouse from "component/warehouse";
import Inventory from "component/warehouse/inventory";
import SellProductHistory from "component/tuition/sell_product_history";
import Report from "component/report";
import Active from "component/active";
import Extend from "component/extend";

const root = ReactDOM.createRoot(document.getElementById("root"));
const ProtectedRoute = () => {
  var path = window.location.pathname.toLowerCase();
  if (path == "/") path = "/student";
  var last = path.lastIndexOf("/");
  path = path.substring(
    0,
    last != -1 && last != 0 ? path.lastIndexOf("/") : path.length,
  );
  var token = localStorage.getItem("token");
  if (token) {
    var role = parseJwt(token).role;
    var myRole = permission.find((x) => x.role == role);
    if (myRole && (myRole.menu == "ALL" || myRole.menu.includes(path))) {
      return <Outlet />;
    }
  } else {
    return <Navigate to={"/login"} replace />;
  }

  return <Navigate to={"/warning"} replace />;
};

root.render(
  <Router>
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/active/:active_code" element={<Active></Active>} />
      <Route element={<App />}>
        <Route element={<ProtectedRoute />}>
          <Route exact path="/" element={<Navigate to="/student" replace />} />
          <Route path="/extend" element={<Extend></Extend>} />
          <Route path="/shift" element={<Shift></Shift>} />
          <Route path="/classroom" element={<ClassRoom></ClassRoom>} />
          <Route path="/role" element={<Role></Role>} />
          <Route path="/user" element={<User></User>} />
          <Route path="/class" element={<Class></Class>} />
          <Route path="/classlevel" element={<ClassLevel></ClassLevel>} />
          <Route path="/student" element={<Student></Student>} />
          <Route path="/category" element={<Category></Category>} />
          <Route path="/product" element={<Product></Product>} />
          <Route path="/schedule" element={<Schedule></Schedule>} />
          <Route path="/teacher" element={<Teacher></Teacher>} />
          <Route path="/myclass/:date" element={<ClassInDate></ClassInDate>} />
          <Route
            path="/attendance"
            element={<AttendanceTracking></AttendanceTracking>}
          />
          <Route path="/voucher" element={<Voucher></Voucher>} />
          <Route
            path="/promotionprogram"
            element={<PromotionProgram></PromotionProgram>}
          />
          <Route path="/dayoff" element={<DayOff></DayOff>} />
          <Route
            path="/collect_tuition"
            element={<PaymentTuitionOnMonth></PaymentTuitionOnMonth>}
          />
          <Route
            path="/tuition_history"
            element={<TuitionHistory></TuitionHistory>}
          />
          <Route
            path="/sell_history"
            element={<SellProductHistory></SellProductHistory>}
          />
          <Route
            path="/tuition_notification"
            element={<TuitionNotification></TuitionNotification>}
          />
          <Route
            path="/print"
            element={<Print view={true} type={"collect_tuition"}></Print>}
          />
          <Route
            path="/revoucher"
            element={<ReceiptsExpenses></ReceiptsExpenses>}
          />
          <Route path="/warehouse" element={<Warehouse></Warehouse>} />
          <Route path="/inventory" element={<Inventory></Inventory>} />
          <Route path="/report" element={<Report></Report>} />
        </Route>
        <Route
          path="/warning"
          element={
            <div
              style={{
                width: "100%",
                height: "60vh",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "800px", height: "200px", padding: 20 }}>
                <WarningOutlined style={{ fontSize: 120, color: "red" }} />
                <div style={{ fontSize: 40 }}>Bạn không có quyền truy cập!</div>
              </div>
            </div>
          }
        />
      </Route>
    </Routes>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
