import * as React from "react";
import api from "api";
import { Row, Button, Space, Card, Select, Col, Input, Alert, DatePicker, Tag } from "antd";
import VouhcherPopup from "./voucher_popup";
import {
  PlusOutlined,
  SearchOutlined,
  DollarOutlined,
  EyeOutlined,
  AuditOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { formatCurrency, formatShortDate, formatLongDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import dayjs from "dayjs";
import "./style.css";
const Option = Select;
const { RangePicker } = DatePicker;

const REVouhcher = (props) => {
  const REPopup = React.useRef();
  const tblRE = React.useRef();
  const [currentAmount, setcurrentAmount] = React.useState("");
  const [currentTranferAmount, setcurrentTranferAmount] = React.useState("");
  const [filter, setFilter] = React.useState({
    StartDate: dayjs().date(1),
    EndDate: dayjs().endOf("month"),
    VoucherType: "",
    Info: "",
  });
  React.useEffect(() => {
    getCurrentAmount();
  }, []);

  const getlstREVoucher = () => {
    tblRE.current.reload();
    getCurrentAmount();
  };
  const getCurrentAmount = () => {
    api.get("ReceiptsExpenses/GetCurrentAmount").then((res) => {
      setcurrentAmount(res.CurrentAmount);
      setcurrentTranferAmount(res.CurrentTranferAmount);
    });
  };
  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const dateChange = (e) => {
    var data = { ...filter };
    data.StartDate = e ? e[0] : null;
    data.EndDate = e ? e[1] : null;
    setFilter(data);
  };
  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Ngày chứng từ",
      dataIndex: "day_vouchers",
      key: "day_vouchers",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },
    {
      title: "Loại phiếu",
      dataIndex: "voucher_type",
      key: "voucher_type",
      render: (text, record, index) => {
        if (text == "receipts") return "Thu " + record.payment_name;
        else return "Chi " + record.payment_name;
      },
    },

    {
      className: "alignRight",
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      render: (text, record, index) => {
        if (record.voucher_type == "receipts") return "+" + formatCurrency(text).toString();
        else return "-" + formatCurrency(text).toString();
      },
    },
    {
      className: "alignRight",
      title: "Số tiền còn lại",
      dataIndex: "current_amount",
      render: (text, record, index) => {
        return formatCurrency(record.current_amount);
      },
    },
    {
      className: "alignRight",
      title: "Ngân hàng",
      dataIndex: "current_transfer_amount",
      render: (text, record, index) => {
        return formatCurrency(record.current_transfer_amount);
      },
    },
    {
      className: "alignRight",
      title: "Tiền mặt",
      dataIndex: "current_monney_amount",
      render: (text, record, index) => {
        return formatCurrency(record.current_monney_amount);
      },
    },
    {
      title: "Loại thu/chi",
      dataIndex: "apply_name",
      key: "apply_name",
    },

    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
      className: "note-column",
    },
    {
      title: "Ngày Tạo",
      dataIndex: "create_date",
      key: "create_date",
      align: "center",
      render: (text, record, index) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{record.create_by_name}</p>
            <p style={{ whiteSpace: "nowrap" }}>{formatLongDate(text)}</p>
            <Tag color="lime-inverse">{record.apply_for_name}</Tag>
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              REPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                REPopup.current.open(record);
              }}
            >
              <EyeOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Loại phiếu</div>
              <Select
                value={filter.VoucherType}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "VoucherType");
                }}
              >
                <Option value="">Tất cả</Option>
                <Option value="receipts">Phiếu thu</Option>
                <Option value="expenses">Phiếu chi</Option>
              </Select>
            </div>
          </Col>

          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div style={{ fontWeight: "bold" }}>Ngày:</div>
            <div>
              <RangePicker
                style={{ width: "100%" }}
                value={[filter.StartDate, filter.EndDate]}
                format={"DD/MM/YYYY"}
                onChange={dateChange}
                presets={[
                  {
                    label: "7 ngày trước",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Tháng trước",
                    value: [
                      dayjs().add(-1, "month").date(1),
                      dayjs().add(-1, "month").endOf("month"),
                    ],
                  },
                  {
                    label: "3 Tháng trước",
                    value: [dayjs().add(-3, "month").date(1), dayjs().endOf("month")],
                  },
                  {
                    label: "Tháng này",
                    value: [dayjs().date(1), dayjs().endOf("month")],
                  },
                  {
                    label: "Năm này",
                    value: [dayjs().month(0).date(1), dayjs().endOf("month")],
                  },
                ]}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => {
              getlstREVoucher();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>

      <Card>
        <div>
          <Alert
            message={
              <>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <div style={{ textAlign: "center", marginRight: "16px" }}>
                    <DollarOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "8px",
                        color: "#35fc59",
                      }}
                    />
                    <span style={{ fontSize: "16px" }}>
                      Tổng: {formatCurrency(currentAmount)} (VNĐ)
                    </span>
                  </div>
                  <div style={{ textAlign: "center", marginRight: "16px" }}>
                    <BankOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "8px",
                        color: "#35fc59",
                      }}
                    />

                    <span style={{ fontSize: "16px" }}>
                      Ngân hàng: {formatCurrency(currentTranferAmount)} (VNĐ)
                    </span>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <AuditOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "8px",
                        color: "#35fc59",
                      }}
                    />
                    <span style={{ fontSize: "16px" }}>
                      Tiền mặt: {formatCurrency(currentAmount - currentTranferAmount)} (VNĐ)
                    </span>
                  </div>
                </div>
              </>
            }
            type="success"
            style={{ borderColor: "#35fc59" }}
          />
        </div>
        <AntTable
          columns={col}
          ajax="ReceiptsExpenses/GetListREVoucher"
          customSearch={filter}
          ref={tblRE}
        ></AntTable>
        <VouhcherPopup ref={REPopup} reload={getlstREVoucher}></VouhcherPopup>
      </Card>
    </>
  );
};

export default REVouhcher;
