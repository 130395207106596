import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhoneVolume, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FacebookFilled } from "@ant-design/icons";
import dayjs from "dayjs";

const FilePrint = React.forwardRef(
  ({ orderNumber, customerName, totalAmount, description, month, centerInfo }, ref) => {
    return (
      <div className="bodyPrint">
        <style>{`@media print {
            body{
              zoom: 50%;
            }
            p {
              font-size: 17pt;
              line-height: 5px;
              white-space: normal;
            }
            div {
              font-size: 17pt;
            }
          }`}</style>
        <div ref={ref} className="container">
          <div
            style={{
              border: "1px solid #ccc",
              margin: 5,
              padding: 15,
              width: "50%",
            }}
          >
            <div>
              <strong className="header">
                TRUNG TÂM {centerInfo?.customer_name?.toUpperCase()}
              </strong>
              <p style={{ margin: "10px 0px 10px 0px" }}>
                <FontAwesomeIcon icon={faLocationDot} /> Địa chỉ: {centerInfo?.address}
              </p>
              <p style={{ margin: "10px 0px 10px 0px" }}>
                <FontAwesomeIcon icon={faPhoneVolume} /> Số điện thoại: {centerInfo?.phone_number}
              </p>
              <p style={{ margin: "10px 0px 10px 0px" }}>
                <FontAwesomeIcon icon={faEnvelope} /> Email: {centerInfo?.email}
              </p>
              <p className="right">Số: {orderNumber} </p>
            </div>
            <div className="midheader">
              <strong style={{ fontSize: 50 }}>BIÊN LAI THU TIỀN</strong>
            </div>
            <p style={{ fontSize: 28 }}>Nhận của: {customerName}</p>
            <p style={{ fontSize: 28 }}>Về khoản: {description}</p>
            <p style={{ fontSize: 28 }}>Số tiền: {totalAmount}</p>
            <p className="right">
              Ngày {dayjs(month).date()} Tháng {dayjs(month).month() + 1} Năm {dayjs(month).year()}
            </p>
            <div className="bottom">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "95px",
                  textAlign: "center",
                }}
              >
                <p style={{ margin: "13px 0px 0px 0px" }}>Người nộp</p>
                <p style={{ margin: "5px 0px 0px 0px" }}>{customerName}</p>{" "}
              </div>
              <div>
                <p style={{ margin: "13px 0px 0px 0px" }}>Người thu</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default FilePrint;
