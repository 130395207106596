import * as React from "react";
import api from "api";
import { Table, Card } from "antd";
import { formatCurrency } from "common/Format2";

const TuitionDiscountHistory = (props) => {
  const [listDetail, setListDetail] = React.useState([]);

  React.useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    api.get("Tuition/GetListDiscountDetail?id=" + props.tuitionID).then((res) => {
      setListDetail(res.data);
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Loại",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Tên/Mã",
      key: "name",
      render: (index, record, text) => {
        if (record.source == "voucher") return record.code;
        return record.name;
      },
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      render: (index, record, text) => {
        return formatCurrency(record.value);
      },
    },
    {
      title: "Đơn vị",
      dataIndex: "unit",
      key: "unit",
    },
  ];

  return (
    <Card>
      <Table
        style={{ width: "100%" }}
        locale={{
          emptyText: "Không áp dụng giảm giá!",
        }}
        columns={col}
        dataSource={listDetail}
        rowKey="id"
      />
    </Card>
  );
};

export default TuitionDiscountHistory;
