import * as React from "react";
import api from "api";
import { Row, Button, Space, Card, Select, Col, DatePicker } from "antd";
import WHPopup from "./warehouse_popup";
import { PlusOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { formatCurrency, formatShortDate } from "common/Format2";
import dayjs from "dayjs";
import AntTable from "component/common/AntTable";
const Option = Select;
const { RangePicker } = DatePicker;

const Warehouse = (props) => {
  const whPopup = React.useRef();
  const tblWH = React.useRef();
  const [lstproduct, setlstproduct] = React.useState([]);
  const [filter, setFilter] = React.useState({
    StartDate: dayjs().date(1),
    EndDate: dayjs().endOf("month"),
    Info: "",
  });

  React.useEffect(() => {
    getListProduct();
  }, []);

  React.useEffect(() => {
    getlstWarehouse();
  }, [filter]);
  const getlstWarehouse = () => {
    tblWH.current.reload();
  };

  const paramChange = (value, name) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };
  const getListProduct = () => {
    api.get("Warehouse/GetAllProduct").then((res) => {
      setlstproduct(res.data);
    });
  };
  const dateChange = (dates) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      StartDate: dates[0],
      EndDate: dates[1],
    }));
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Ngày chứng từ",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text, record, index) => formatShortDate(text),
    },
    {
      title: "Loại phiếu",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record, index) => (text === "IN" ? "Phiếu nhập" : "Phiếu xuất"),
    },
    // {
    //   title: "Mã sản phẩm",
    //   dataIndex: "product_id",
    //   key: "product_id",
    // },
    {
      title: "Sản phẩm",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Thay đổi",
      dataIndex: "Change",
      children: [
        {
          title: "Số lượng",
          className: "alignRight",
          dataIndex: "change_quantity",
          render: (text, record, index) => {
            return (
              <div>
                {record.change_quantity > 0
                  ? "+" + formatCurrency(record.change_quantity)
                  : formatCurrency(record.change_quantity)}
              </div>
            );
          },
        },
        {
          title: "Giá trị",
          className: "alignRight",
          dataIndex: "change_price",
          render: (text, record, index) => {
            return (
              <div>{formatCurrency(Math.abs(record.change_quantity) * record.change_price)}</div>
            );
          },
        },
      ],
    },
    {
      title: "Tồn kho",
      dataIndex: "Stock",
      children: [
        {
          title: "Giá vốn",
          className: "alignRight",
          dataIndex: "current_quantity",
          render: (text, record, index) => {
            return <div>{formatCurrency(record.current_price)}</div>;
          },
        },
        {
          title: "Số lượng",
          className: "alignRight",
          dataIndex: "current_quantity",
          render: (text, record, index) => {
            return <div>{formatCurrency(record.current_quantity)}</div>;
          },
        },
        {
          title: "Giá trị",
          className: "alignRight",
          dataIndex: "current_price",
          render: (text, record, index) => {
            return <div>{formatCurrency(record.current_quantity * record.current_price)}</div>;
          },
        },
      ],
    },
    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
    },
    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              whPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record) => (
        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              whPopup.current.open(record.wh_voucher_id);
            }}
          >
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          {/* <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
              />
            </div>
          </Col> */}
          <Col span={6}>
            <div style={{ fontWeight: "bold" }}>Ngày:</div>
            <div>
              <RangePicker
                style={{ width: "100%" }}
                value={[filter.StartDate, filter.EndDate]}
                format={"DD/MM/YYYY"}
                onChange={dateChange}
                allowClear={false}
                presets={[
                  {
                    label: "7 ngày trước",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Tháng trước",
                    value: [
                      dayjs().add(-1, "month").date(1),
                      dayjs().add(-1, "month").endOf("month"),
                    ],
                  },
                  {
                    label: "Tháng này",
                    value: [dayjs().date(1), dayjs().endOf("month")],
                  },
                ]}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Loại</div>
              <Select
                value={filter.Type}
                onChange={(e) => {
                  paramChange(e, "Type");
                }}
                style={{
                  width: "100%",
                }}
                options={[
                  { value: "IN", label: "Phiếu nhập" },
                  { value: "OUT", label: "Phiếu xuất" },
                ]}
                allowClear={true}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Sản phẩm</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.ProductID}
                allowClear={true}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "ProductID");
                }}
                options={lstproduct}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
          <Button
            type="primary"
            onClick={() => {
              getlstWarehouse();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>

      <Card>
        <AntTable
          bordered
          columns={columns}
          ajax="Warehouse/GetlstWarehouse"
          customSearch={filter}
          ref={tblWH}
          className="ant-table-tbody"
        ></AntTable>
        <WHPopup ref={whPopup} reload={getlstWarehouse} />
      </Card>
    </>
  );
};

export default Warehouse;
