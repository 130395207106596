import * as React from "react";
import api from "api";
import { Table, Card } from "antd";
import { formatCurrency } from "common/Format2";

const TuitionHistoryDetail = (props) => {
  const [listDetail, setListDetail] = React.useState([]);

  React.useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    api.get("Tuition/GetListDetail?id=" + props.tuitionID).then((res) => {
      setListDetail(res.data);
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tháng",
      dataIndex: "months",
      key: "months",
      render: (index, record, text) => {
        return record.month_payment + "/" + record.year_payment;
      },
    },
    {
      title: "Giá gốc ",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Giá thanh toán",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
    {
      title: "Tổng tiết học",
      dataIndex: "total_lesson",
      key: "total_lesson",
    },
    {
      title: "Số tiết học",
      dataIndex: "total_lesson_real",
      key: "total_lesson_real",
    },
    {
      title: "Giá 1 tiết học",
      dataIndex: "tuition_lesson",
      key: "tuition_lesson",
      render: (text, record, index) => {
        return formatCurrency(text);
      },
    },
  ];

  return (
    <Card>
      <Table
        style={{ width: "100%" }}
        columns={col}
        dataSource={listDetail}
        rowKey="id"
        pagination={false}
      />
    </Card>
  );
};

export default TuitionHistoryDetail;
