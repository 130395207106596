import * as React from "react";
import api from "api";
import { DatePicker, Input, Form, message, Modal, Select, Switch, Row, Col } from "antd";
import dayjs from "dayjs";

const { TextArea } = Input;

const PopupEditDayOff = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [lstClass, setLstClass] = React.useState([]);
  const [id, setId] = React.useState();

  const form = React.useRef();

  React.useEffect(() => {
    getListClass();
  }, []);

  const getListClass = () => {
    api.get("Class/GetListActiveAndFinish").then((res) => {
      setLstClass(res.data);
    });
  };

  const saveDayOff = (values) => {
    values.start_date = values.start_date.format();
    values.end_date = values.end_date.format();
    var url = "/DayOff/CreateDayOff";
    if (id && id != 0) url = "/DayOff/UpdateDateOff";
    api
      .post(url, values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  console.log("id", id);

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setTimeout(() => {
        setId(id);
        if (id) {
          api.get("/DayOff/GetByID?id=" + id).then((res) => {
            res.data.start_date = dayjs(res.data.start_date);
            res.data.end_date = dayjs(res.data.end_date);
            console.log("res", res);
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={id && id != 0 ? " Chỉnh sửa ngày nghỉ" : "Thêm ngày nghỉ"}
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={saveDayOff} layout="vertical">
        <Form.Item name="id" hidden></Form.Item>
        {id && id != 0 ? (
          <>
            <Form.Item
              label="Lớp "
              name="class_id"
              rules={[{ required: true, message: "Bạn chưa chọn lớp" }]}
            >
              <Select options={lstClass} fieldNames={{ label: "name", value: "id" }} />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label="Lớp "
            name="ClassIDs"
            rules={[
              { required: true, message: "Bạn chưa chọn lớp" },
              {
                validator: (rule, value) => {
                  return new Promise((resolve, error) => {
                    if (value.length > 0) {
                      resolve();
                    } else {
                      error();
                    }
                  });
                },
                message: "Bạn chưa chọn lớp!",
              },
            ]}
          >
            <Select
              options={lstClass}
              fieldNames={{ label: "name", value: "id" }}
              mode="multiple"
            />
          </Form.Item>
        )}

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Giờ bắt đâu "
              name="start_date"
              rules={[
                { required: true, message: "Nhập thời gian bắt đầu!" },
                {
                  validator: (rule, value) => {
                    value = value.year(1990).month(0).date(1);
                    var end = form.current.getFieldValue("end_date");
                    if (end) end = dayjs(end).year(1990).month(0).date(1);
                    return new Promise((resolve, error) => {
                      if (end == null || value < end) {
                        resolve();
                      } else {
                        error();
                      }
                    });
                  },
                  message: "Thời gian vào ca phải nhỏ hơn thời gian tan ca!",
                },
              ]}
            >
              <DatePicker format={"DD-MM-YYYY HH:mm"} showTime />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Giờ kết thúc "
              name="end_date"
              rules={[
                { required: true, message: "Nhập thời gian kết thúc!" },
                {
                  validator: (rule, value) => {
                    value.year(1990).month(0).date(1);
                    var start = form.current.getFieldValue("start_date");
                    if (start) start = dayjs(start.year(1990).month(0).date(1));
                    return new Promise((resolve, error) => {
                      if (start == null || value > start) {
                        resolve();
                      } else {
                        error();
                      }
                    });
                  },
                  message: "Thời gian tan ca phải lớn hơn thời gian vào ca!",
                },
              ]}
            >
              <DatePicker format={"DD-MM-YYYY HH:mm"} showTime />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Khấu trừ học phí" name="is_deduction_tuition" valuePropName="checked">
          <Switch>Khấu trừ học phí</Switch>
        </Form.Item>

        <Form.Item label="Mô tả" name="description">
          <TextArea></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default PopupEditDayOff;
