import React from "react";
import { Row, Col, Form, Input, message, Spin } from "antd";
import api from "api";
import { UserOutlined, LockOutlined, HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { parseJwt } from "common/jwt/jwt_helper";
import { permission } from "config/index";

export default function Login(props) {
  const [loadingApp, setLoadingApp] = React.useState(false);
  let navigate = useNavigate();
  const onFinish = (values) => {
    setLoadingApp(true);
    api
      .post("Login/Login", {
        user_name: values.username,
        password: values.password,
        center_name: values.center_name,
      })
      .then((res) => {
        if (res.code == 1) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("center", values.center_name);
          // props.handleNavigate();
          var role = parseJwt(res.token).role;
          var myRole = permission.find((x) => x.role == role);
          navigate(myRole.defaultMenu);
        } else message.error(res.Message);
        setLoadingApp(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoadingApp(false);
      });
  };

  return (
    <Row>
      <Col xl={16} lg={12} md={14} sm={0} xs={0}>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url("/image/loginBg.jpg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        ></div>
      </Col>
      <Col xl={8} lg={12} md={10} sm={14} xs={24}>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            backgroundImage: `url("/image/logofull.png")`,
            backgroundSize: "80%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#8befe914",
          }}
        >
          <div style={{ margin: "auto" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "purple",
                  fontFamily: "Texturina",
                  fontWeight: "bold",
                }}
              >
                <h1>SIGN IN</h1>
              </div>
            </div>

            <Form
              name="basic"
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true, center_name: localStorage.getItem("center") || "" }}
              onFinish={onFinish}
              autoComplete="off"
              style={{
                width: 300,
              }}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: "Please input your username!" }]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  style={{ fontSize: 22, borderRadius: 50 }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined />}
                  style={{ fontSize: 22, borderRadius: 50, bordercolor: "red" }}
                />
              </Form.Item>

              <Form.Item
                name="center_name"
                rules={[{ required: true, message: "Please input your center name!" }]}
              >
                <Input
                  size="large"
                  prefix={<HomeOutlined />}
                  style={{ fontSize: 22, borderRadius: 50, bordercolor: "red" }}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <div style={{ textAlign: "center" }}>
                  <button className="button-85" role="button">
                    Sign in
                  </button>
                  <div style={{ position: "absolute", bottom: 5, right: 5 }}>
                    <a
                      onClick={() => {
                        navigate("/register");
                      }}
                    >
                      Sign up
                    </a>
                  </div>
                  {/* 
                  <Button type="primary" htmlType="submit">
                    Login
                  </Button> */}
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
      {loadingApp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999,
              backgroundColor: "#d3d1d1",
              opacity: 0.5,
            }}
          ></div>
          <div style={{ margin: "auto", zIndex: 10000 }}>
            <Spin size={"large"}></Spin>
          </div>
        </div>
      )}
    </Row>
  );
}
