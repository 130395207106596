import * as React from "react";
import api from "api";
import {
  Row,
  Input,
  Col,
  Form,
  message,
  DatePicker,
  Select,
  Modal,
  Upload,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { imageURL, uploadImageURL } from "config/index";
import axios from "axios";

const { Option } = Select;
const { TextArea } = Input;
const UserPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [lstwagetype, setlstwagetype] = React.useState([]);
  const [lstrole, setlstrole] = React.useState([]);
  const form = React.useRef();
  const [mode, setmode] = React.useState("edit");
  const wage_type = (
    <Form.Item name="wage_type" noStyle>
      <Select
        style={{ width: 100 }}
        options={lstwagetype.map((item) => {
          return { value: item.id, label: item.name };
        })}
        rules={[{ required: true, message: "Chọn hình thức tính công!" }]}
      ></Select>
    </Form.Item>
  );

  const [defaultImageURL, setdefaultImageURL] = React.useState("");
  const defaultFileList = [
    {
      url: defaultImageURL,
    },
  ];

  React.useEffect(() => {
    getlstrole();
    getListWagetype();
  }, [props.reload]);

  const getListWagetype = () => {
    api.get("User/GetListWagetype").then((res) => {
      setlstwagetype(res.lstwagetype);
    });
  };

  const getlstrole = () => {
    api.get("User/GetListRole").then((res) => {
      setlstrole(res.lstrole);
    });
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    var token = localStorage.getItem("token");
    if (!token) token = "";
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data", token },
    };
    fmData.append("image", file);
    try {
      const res = await axios.post(uploadImageURL, fmData, config);
      setdefaultImageURL(imageURL + res.data);
      form.current.setFieldsValue({ avatar: res.data });
      onSuccess("Ok");
    } catch (err) {
      setdefaultImageURL();
      form.current.setFieldsValue({ avatar: "" });
      onError({ err });
    }
  };
  const saveUser = (values) => {
    values.social_insurance_begin = values.social_insurance_begin?.format();
    values.birthday = values.birthday?.format();
    const data = { user: values, mode };
    api
      .post("User/SaveUser", data)
      .then((res) => {
        message.success(res.message);
        props.reload();
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(user) {
      setVisible(true);
      setTimeout(() => {
        setmode("edit");
        if (user) {
          user.birthday = user.birthday ? dayjs(user.birthday) : null;
          user.social_insurance_begin = user.social_insurance_begin
            ? dayjs(user.social_insurance_begin)
            : null;
          form.current.setFieldsValue(user);

          form.current.setFieldsValue({ role_id: user.role_id });
          form.current.setFieldsValue({ gender: user.gender });
          form.current.setFieldsValue({ wage_type: user.wage_type });
          setdefaultImageURL(imageURL + user.avatar);
        } else {
          setmode("add");
          form.current.resetFields();
          setdefaultImageURL();
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      width="50%"
      open={visible}
      title={mode == "add" ? "Thêm mới nhân viên" : "Chỉnh sửa nhân viên"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
    >
      <Form ref={form} onFinish={saveUser} name="validateOnly" layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Tên nhân viên"
              name="name"
              rules={[{ required: true, message: "Nhập tên nhân  viên!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên đăng nhập"
              name="user_name"
              rules={[{ required: true, message: "Nhập tên đăng nhập!" }]}
            >
              <Input disabled={mode == "edit" ? true : false} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Mật khẩu" name="password" hidden={mode == "edit" ? true : false}>
              <Input.Password value={1234567890} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="role_id"
              label="Quyền"
              rules={[{ required: true, message: "Chọn quyền!" }]}
            >
              <Select
                placeholder="Quyền"
                allowClear
                options={lstrole.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="gender"
              label="Giới tính"
              rules={[{ required: true, message: "Chọn giới tính!" }]}
            >
              <Select placeholder="Giới tính" allowClear>
                <Option value="male">Nam</Option>
                <Option value="female">Nữ</Option>
                <Option value="other">Khác</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone_number"
              label="Số điện thoại"
              rules={[{ required: true, message: "Vui lòng nhập SĐT!" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Sinh nhật " name="birthday">
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Địa chỉ " name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Bảo hiểm xã hội" name="social_insurance">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Thời gian bắt đầu đóng BHXH" name="social_insurance_begin">
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="wage"
              label="Tiền công/Hình thức"
              rules={[{ required: true, message: "Nhập tiền công!" }]}
            >
              <InputNumber
                addonAfter={wage_type}
                style={{ width: "100%" }}
                formatter={(value) => `${value} VNĐ`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "").replace(" VNĐ", "")}
                step={1000}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Mô tả " name="description">
              <TextArea rows={4} style={{ marginLeft: "5px" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Avatar" name="avatar">
              <Upload
                customRequest={uploadImage}
                fileList={defaultFileList}
                maxCount={1}
                listType="picture-card"
                accept={".png, .jpg, .jpeg"}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Avatar</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
export default UserPopup;
