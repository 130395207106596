import React from "react";
import FilePrint from "./FilePrint";
import FilePrint_Other from "./FilePrint_Other";
import useStateCallback from "component/common/useStateCallBack";
import { useReactToPrint } from "react-to-print";
import * as ReactDOM from "react-dom";
import NotiTuiton from "./noti_tuiton";
import NotiTuitonOther from "./noti_tuiton_other";

const Print = React.forwardRef((props, ref) => {
  const componentRef = React.useRef();
  const [dataPrint, setDataPrint] = useStateCallback({});

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  React.useImperativeHandle(ref, () => ({
    print(data) {
      setDataPrint(data, () => {
        handlePrint();
      });
    },
  }));
  return (
    <div style={{ display: props.view ? "block" : "none" }}>
      {props.type == "collect_tuition" && (
        <>
          {dataPrint?.centerInfo?.center_name == "Rainbow" ? (
            <FilePrint
              ref={componentRef}
              orderNumber={dataPrint.data.orderNumber}
              customerName={dataPrint.data.customerName}
              totalAmount={dataPrint.data.totalAmount}
              description={dataPrint.data.description}
              month={dataPrint.data.month}
            />
          ) : (
            <FilePrint_Other
              ref={componentRef}
              orderNumber={dataPrint.orderNumber}
              customerName={dataPrint.customerName}
              totalAmount={dataPrint.totalAmount}
              description={dataPrint.description}
              month={dataPrint.month}
              centerInfo={dataPrint.centerInfo}
            />
          )}
        </>
      )}
      {props.type == "noti_tuition" && (
        <>
          {dataPrint?.centerInfo?.center_name == "Rainbow" ? (
            <NotiTuiton data={dataPrint} ref={componentRef} />
          ) : (
            <NotiTuitonOther data={dataPrint} ref={componentRef} />
          )}
        </>
      )}
    </div>
  );
});

function proccessPrint(data) {
  ReactDOM.render(
    React.createElement(
      "div",
      { id: "printCom", style: { display: "none" } },
      React.createElement(FilePrint, { ...data }),
    ),
    document.getElementById("printRoot"),
    () => {
      var content = document.getElementById("printCom");
      var pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(
        '<link rel="stylesheet" href="http://localhost:1000/Content/style.css" media="print" type="text/css" />',
      );
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      setTimeout(() => {
        pri.print();
      }, 500);
    },
  );
}

export { Print, proccessPrint };
