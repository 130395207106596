import * as React from "react";
import api from "api";
import {
  Row,
  Input,
  Col,
  message,
  Select,
  Modal,
  Button,
  Card,
  Skeleton,
  Steps,
  Spin,
  Divider,
} from "antd";
import "./stylewh.css";
import { formatCurrency } from "common/Format2";
import SearchProduct from "./product_search";
import ProductItem from "./product_item";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;
const WarehousePopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const form = React.useRef();
  const [mode, setmode] = React.useState("complete");
  const searchPro = React.useRef();
  const [lstdetail, setlstDetail] = React.useState([]);
  const [id, setId] = React.useState();
  const [description, setDescription] = React.useState();
  const [type, setType] = React.useState();
  const [isLoading, setIsLoading] = React.useState();
  const [isSpinning, setIsSpinning] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState();
  const [lstpaymentmethod, setlstpaymentmethod] = React.useState([]);
  const [payment, setPayment] = React.useState();
  const [modal, contextHolder] = Modal.useModal();
  React.useEffect(() => {
    getListPaymentMethod();
  }, [props.reload]);

  const getListPaymentMethod = () => {
    api.get("ReceiptsExpenses/GetListPaymentMethod").then((res) => {
      setlstpaymentmethod(res.data);
    });
  };

  const confirm = () => {
    modal.confirm({
      title: "Xác nhận",
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có muốn tạo phiếu thu/chi cho phiếu nhập/xuất này không?",
      okText: "Có",
      cancelText: "Không",
      onOk: () => {
        saveVoucher(true);
      },
      onCancel: () => {
        saveVoucher(false);
      },
    });
  };

  const productSelect = (productSelect) => {
    const product = lstdetail.find((item) => item.product_id === productSelect.product_id);

    if (product) {
      const updatedProduct = {
        ...product,
        quantity: product.quantity + 1,
        price: product.price,
        total_amount: (product.quantity + 1) * product.price,
      };
      setlstDetail((prevList) => {
        const updatedList = prevList.map((item) =>
          item.product_id === productSelect.product_id ? updatedProduct : item
        );
        return updatedList;
      });
    } else {
      const newProduct = {
        ...productSelect,
        quantity: 1,
        price: productSelect.price,
        total_amount: productSelect.price,
      };
      setlstDetail((prevList) => [...prevList, newProduct]);
    }
  };
  let totalQuantity = 0;
  let totalPrice = 0;

  lstdetail.forEach((item) => {
    totalQuantity += item.quantity;
    totalPrice += item.total_amount;
  });
  const saveVoucher = (isCreateREVoucher) => {
    setIsSaving(true);
    api
      .post("Warehouse/SaveVoucher", {
        List: lstdetail,
        ID: id,
        TotalQuantity: totalQuantity,
        TotalPrice: totalPrice,
        Description: description,
        Type: type,
        PaymentMethod: payment,
        IsCreateREVoucher: isCreateREVoucher,
      })
      .then((res) => {
        setIsSaving(false);
        message.success(res.message);
        props.reload();
        setVisible(false);
      })
      .catch((err) => {
        setIsSaving(false);
        message.error(err.message);
      });
  };
  const productChange = (index, updatedProduct) => {
    setlstDetail((newlist) => newlist.map((product, i) => (i == index ? updatedProduct : product)));
  };
  const dataChange = (value, name) => {
    if (name == "Description") setDescription(value);
    if (name == "Type") {
      setType(value);
      setlstDetail([]);
    }
    if (name == "Payment") setPayment(value);
  };
  const productRemove = (index) => {
    const newlist = [...lstdetail];
    newlist.splice(index, 1);
    setlstDetail(newlist);
  };

  const close = () => {
    setVisible(false);
  };
  React.useImperativeHandle(ref, () => ({
    open(id) {
      setId(id);
      setVisible(true);
      setIsLoading(true);
      setTimeout(() => {
        if (id) {
          setmode("complete");
          api.get("Warehouse/GetByID?id=" + id).then((res) => {
            setlstDetail(res.detail);
            setType(res.voucher.transaction_type);
            setDescription(res.voucher.description);
            setPayment(res.voucher.payment_method_id);
            setIsLoading(false);
          });
        } else {
          setmode("add");
          setlstDetail([]);
          setType("IN");
          setDescription("");
          setPayment("");
          setIsLoading(false);
        }
      }, 300);
    },
  }));
  const renderdata = () => {
    return (
      <Row gutter={16} style={{ padding: "0px" }}>
        <Col span={16}>
          <Card
            size="small"
            title={
              <div>
                <b>Sản phẩm</b>
              </div>
            }
          >
            {mode == "add" && <SearchProduct ref={searchPro} onSelectProduct={productSelect} />}
            <div className="table-container">
              <table className="table">
                <colgroup>
                  {/* <col style={{ width: "15%" }} /> */}

                  <col style={{ width: "30%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "5%" }} />
                </colgroup>

                <thead>
                  <tr>
                    {/* <th>Mã sản phẩm</th> */}
                    <th>Tên sản phẩm</th>
                    <th>Số lượng</th>
                    <th>{type == "IN" ? "Giá nhập" : "Giá vốn"}</th>
                    <th>Thành tiền</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {lstdetail.map((item, index) => {
                    return (
                      <ProductItem
                        key={`product_${item.product_id}`}
                        data={item}
                        index={index}
                        onChange={productChange}
                        onRemove={productRemove}
                        status={mode}
                        type={type}
                      ></ProductItem>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small" title={<b>Thông tin</b>}>
            <Col span={24}>
              <Col>
                <p style={{ float: "left" }}>Loại phiếu</p>
                <Select
                  style={{ width: "100%" }}
                  value={type}
                  name="transaction_type"
                  label="Chọn loại phiếu"
                  disabled={mode === "complete" ? true : false}
                  onChange={(e) => {
                    dataChange(e, "Type");
                  }}
                >
                  <Option value="IN">Phiếu nhập</Option>
                  <Option value="OUT">Phiếu xuất</Option>
                </Select>
                <p style={{ float: "left" }}>Phương thức thanh toán</p>
                <Select
                  style={{ width: "100%" }}
                  value={payment}
                  disabled={mode === "complete" ? true : false}
                  placeholder="Phương thức thanh toán"
                  options={lstpaymentmethod.map((item) => {
                    return { value: item.id, label: item.name };
                  })}
                  onChange={(e) => {
                    dataChange(e, "Payment");
                  }}
                ></Select>
                <p style={{ float: "left" }}>Tổng số lượng</p>
                <p style={{ float: "right", fontWeight: "bold" }}>
                  {formatCurrency(totalQuantity)}
                </p>
                <Divider style={{ margin: "0px" }} />
                <p style={{ float: "left" }}>Tổng tiền thành toán</p>
                <p style={{ float: "right", fontWeight: "bold" }}>{formatCurrency(totalPrice)}</p>
                <Divider style={{ margin: "0px" }} />
                <p style={{ float: "left" }}>Ghi chú</p>
                <TextArea
                  value={description}
                  style={{ float: "right" }}
                  name="description"
                  label="Ghi chú"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  onChange={(e) => {
                    dataChange(e.target.value, "Description");
                  }}
                />
              </Col>
            </Col>
          </Card>
          <div>
            <div style={{ marginTop: 15, textAlign: "right" }}>
              <Button
                loading={isSaving}
                type="primary"
                onClick={() => {
                  if (mode === "complete") {
                    close();
                  } else {
                    confirm();
                  }
                }}
                style={{ marginRight: 15 }}
              >
                {mode === "complete" ? <> Đóng </> : <> Lưu </>}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Modal
        maskClosable={false}
        open={visible}
        onCancel={close}
        style={{ width: "100%", marginBottom: 20 }}
        title={
          <>
            <div>
              <b>
                {mode === "complete" ? <> Xem chi tiết phiếu kho </> : <> Thêm mới phiếu kho </>}
              </b>
            </div>
          </>
        }
        footer={null}
        width="70%"
      >
        <>
          {isLoading ? (
            <>
              <Skeleton active />
            </>
          ) : (
            <>
              <Spin spinning={isSpinning} delay={100}>
                {renderdata()}
              </Spin>
            </>
          )}
        </>
      </Modal>
      {contextHolder}
    </>
  );
});
export default WarehousePopup;
