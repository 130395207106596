import * as React from "react";
import api from "api";
import { Row, Button, Card, Calendar, Col, Select, message, Divider } from "antd";
import Teacher from "component/teacher";
import { ClockCircleOutlined } from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import CalendarLocale from "rc-picker/lib/locale/vi_VN";
import AttendanceTrackingPopup from "./attendance_tracking_popup";
const { Option } = Select;
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

const AttendanceTracking = (props) => {
  const [shiftsData, setShiftsData] = React.useState([]);
  const [lstuser, setlstuser] = React.useState([]);
  const [mode, setmode] = React.useState("in");
  const todate = new Date();
  const [value, setValue] = React.useState(dayjs(todate));
  const [selectedValue, setSelectedValue] = React.useState(() => dayjs(todate));
  const [loadingAttendance, setLoadingAttendance] = React.useState(false);
  const [selectuser, setselectuser] = React.useState("");
  const [selectFullUser, setSelectFullUser] = React.useState({});
  const timepopup = React.createRef();
  const [detailInfo, setdetailInfo] = React.useState(() => []);
  const [role, setdRole] = React.useState(() => "");
  React.useEffect(() => {
    getdefaultmode();
    getListUser();
    getUser();
  }, []);

  React.useEffect(() => {
    console.log(shiftsData);
  }, [shiftsData]);

  const getListShift = (time, user) => {
    api
      .get("AttendanceTracking/GetListAttendance?date=" + dayjs(time) + "&user=" + user)
      .then((res) => {
        setShiftsData(res.lstattendance);
        setdetailInfo(res.info);
      });
  };
  const getListUser = () => {
    api.get("User/GetAllUser").then((res) => {
      setlstuser(res.data);
      if (res.data.length > 0) {
        setselectuser(res.data[0].user_name);
        getListShift(value, res.data[0].user_name);
        setSelectFullUser(res.data[0]);
      }
    });
  };

  const getUser = () => {
    api.get("AttendanceTracking/GetUserInfo").then((res) => {
      setdRole(res.role);
    });
  };
  const getdefaultmode = () => {
    api.get("AttendanceTracking/GetMode").then((res) => {
      setmode(res.mode);
    });
  };
  const onSelect = (newValue) => {
    setValue(newValue);
    setSelectedValue(newValue);
  };
  const changeUser = async (user) => {
    var fullUser = lstuser.find((x) => x.user_name == user);
    setselectuser(user);
    setSelectFullUser(fullUser);
    if (fullUser.wage_type == 1) getListShift(value, user);
  };
  const reload = () => {
    getListShift(value, selectuser);
  };
  const onPanelChange = (newValue) => {
    getListShift(newValue, selectuser);
    setValue(newValue);
  };

  const dateCellRender = (date) => {
    const dateString = date.format("DD-MM-YYYY");
    const shiftDate = shiftsData.filter(
      (item) => dateString === dayjs(item.tracking_date).format("DD-MM-YYYY")
    );

    let totalatt = 0;

    shiftDate.forEach((item) => {
      const start = dayjs(item.start_time);
      const end = dayjs(item.end_time);
      const total = end.diff(start, "minutes") || 0;
      totalatt += total;
    });
    var hours = Math.floor(totalatt / 60);
    var minutes = totalatt % 60;
    return (
      <div style={{ textAlign: "center" }}>
        <p
          style={{ color: "green", textAlign: "center", fontSize: "15px", margin: "0px" }}
          onClick={() => {
            timepopup.current.open(null, selectuser, date, role);
          }}
        >
          {shiftDate.length > 0 ? (
            <div>
              {hours < 10 ? "0" + hours : hours} : {minutes < 10 ? "0" + minutes : minutes}
            </div>
          ) : (
            <div>+</div>
          )}
        </p>

        {shiftDate.map((item) => (
          <>
            <p
              key={item.id}
              onClick={() => {
                timepopup.current.open({ ...item }, null, null, role);
              }}
              style={{ margin: "0px" }}
            >
              <ClockCircleOutlined />{" "}
              {dayjs(item.start_time).format("HH:mm") != "Invalid Date"
                ? dayjs(item.start_time).format("HH:mm")
                : "--:--"}{" "}
              -{" "}
              {dayjs(item.end_time).format("HH:mm") != "Invalid Date"
                ? dayjs(item.end_time).format("HH:mm")
                : "--:--"}
            </p>
          </>
        ))}
      </div>
    );
  };
  const saveAttendanceTracking = (values) => {
    setLoadingAttendance(true);
    api
      .post("AttendanceTracking/SaveAttendanceTrackingByUser?mode=" + values)
      .then((res) => {
        setLoadingAttendance(false);
        message.success(res.message);
        setmode(mode == "in" ? "out" : "in");
        reload();
      })
      .catch((err) => {
        setLoadingAttendance(false);
        message.error(err.message);
      });
  };

  return (
    <>
      <Card style={{ width: "100%", marginBottom: 20 }} size="small">
        <Select
          style={{ marginBottom: "10px", width: "20%" }}
          placeholder="Chọn nhân viên"
          onChange={(e) => {
            changeUser(e);
          }}
          value={selectuser}
          options={lstuser.map((item) => {
            return { value: item.user_name, label: item.name };
          })}
        ></Select>
      </Card>
      {selectFullUser.wage_type == 1 ? (
        <Row gutter={16}>
          <Col span={18}>
            <Card>
              {/* <Alert message={`Ngày đang chọn: ${selectedValue?.format("DD-MM-YYYY")}`} /> */}
              <Calendar
                mode="month"
                value={value}
                onSelect={onSelect}
                onPanelChange={onPanelChange}
                dateCellRender={dateCellRender}
                locale={{
                  lang: {
                    shortWeekDays: ["CN", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
                    shortMonths: [
                      "Tháng 1",
                      "Tháng 2",
                      "Tháng 3",
                      "Tháng 4",
                      "Tháng 5",
                      "Tháng 6",
                      "Tháng 7",
                      "Tháng 8",
                      "Tháng 9",
                      "Tháng 10",
                      "Tháng 11",
                      "Tháng 12",
                    ],
                    ...CalendarLocale,
                  },
                }}
              />
            </Card>
          </Col>
          <Col span={6} style={{ height: "100vh" }}>
            <Card style={{ height: "100%" }}>
              <Row gutter={8}>
                {role != "1" && role != "" && (
                  <>
                    <Col span={12}>
                      <Button
                        type="primary"
                        danger
                        style={{ width: "100%" }}
                        onClick={() => saveAttendanceTracking(mode)}
                        loading={loadingAttendance}
                      >
                        Chấm công
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Select
                        placeholder="checkin-checkout"
                        style={{ width: "100%", textAlign: "center" }}
                        onChange={(e) => {
                          setmode(e);
                        }}
                        value={mode || "in"}
                      >
                        <Option value="in">Vào</Option>
                        <Option value="out">Ra</Option>
                      </Select>
                    </Col>
                  </>
                )}

                <Col span={24}>
                  <Col>
                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                      <ClockCircleOutlined /> Công làm việc
                    </p>
                    <p style={{ float: "left" }}>Họ và tên</p>
                    <p style={{ float: "right", fontWeight: "bold" }}>{detailInfo.full_name}</p>
                    <Divider style={{ margin: "0px" }} />
                    <p style={{ float: "left" }}>Chức vụ</p>
                    <p style={{ float: "right", fontWeight: "bold" }}>{detailInfo.position}</p>
                    <Divider style={{ margin: "0px" }} />
                    <p style={{ float: "left" }}>Tổng số giờ </p>
                    <p style={{ float: "right", fontWeight: "bold" }}>
                      {detailInfo.total_hour ?? 0}
                    </p>
                    <Divider style={{ margin: "0px" }} />
                    <p style={{ float: "left" }}>Tổng số buổi</p>
                    <p style={{ float: "right", fontWeight: "bold" }}>{detailInfo.total_work}</p>
                    <Divider style={{ margin: "0px" }} />

                    <p style={{ float: "left" }}>Công theo giờ</p>
                    <p style={{ float: "right", fontWeight: "bold" }}>
                      {formatCurrency((detailInfo.total_wage || 0).toFixed(0)) + " VNĐ"}
                    </p>
                  </Col>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      ) : (
        <Teacher teacherSelect={selectFullUser}></Teacher>
      )}

      <AttendanceTrackingPopup ref={timepopup} reload={reload}></AttendanceTrackingPopup>
    </>
  );
};

export default AttendanceTracking;
