import * as React from "react";
import api from "api";
import { Select } from "antd";
import { formatCurrency } from "common/Format2";
import debounce from "lodash.debounce";
import { imageURL } from "config/index";
const { Option } = Select;
const ProductSearch = React.forwardRef((props, ref) => {
  const [lstPro, setlstPro] = React.useState([]);

  React.useEffect(() => {
    getListProducts("");
  }, []);

  const getListProducts = (values) => {
    api.get("Warehouse/GetListProduct?info=" + values).then((res) => {
      setlstPro(res.data);
    });
  };

  const selectProduct = (value, option) => {
    const productSelect = {
      product_id: value,
      name: option.props.label,
      price: option.props.price,
    };
    if (props.onSelectProduct) {
      props.onSelectProduct(productSelect);
    }
  };

  const onsearch = React.useRef(
    debounce(async (values) => {
      await getListProducts(values);
    }, 500)
  ).current;
  return (
    <>
      <Select
        onSearch={onsearch}
        showAction={["focus", "click"]}
        placeholder={"Chọn sản phẩm"}
        style={{
          width: "100%",
          zIndex: "10000",
        }}
        showSearch={true}
        value={[]}
        onSelect={selectProduct}
        autoClearSearchValue={true}
        optionFilterProp={"label"}
      >
        {lstPro.map((item) => (
          <Option key={item.id} value={item.id} label={item.name} price={item.change_price}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <span style={{ flex: "20%" }}>
                <img style={{ height: "50px" }} src={imageURL + item.image} />
              </span>
              <span style={{ flex: "40%" }}>{item.name}</span>
              <span style={{ flex: "20%" }}>{formatCurrency(item.change_price)}</span>
              <span style={{ flex: "20%" }}>Số lượng: {item.quantity}</span>
            </div>
          </Option>
        ))}
      </Select>
    </>
  );
});
export default ProductSearch;
