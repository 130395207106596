var { api_url, secret } = require("config");
var CryptoJS = require("crypto-js");
var axios = require("axios");

let getToken = async function () {
  return localStorage.getItem("token");
};

var axiosInstance = axios.create({
  baseURL: api_url,
});

axiosInstance.interceptors.request.use(async function (config) {
  // var shopName = window.localStorage.getItem("shopName");
  // var timeStamp = new Date().getTime();
  var token = await getToken();
  if (!token) token = "";
  config.headers["token"] = token;
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    var data = response.data;
    if (!data.Error) {
      if (data.code == 1) {
        return data;
      } else {
        console.error("api error: ", data.Error);
        return Promise.reject(data);
      }
    } else {
      if (data.Error.Message == "Missing token") {
        window.location = "/login";
      } else {
        console.error("api error: ", data.Error);
        return Promise.reject(data);
      }
    }
  },
  function (error) {
    console.error("api error: ", error);
    return Promise.reject(error);
  }
);

axiosInstance.setTokenKey = function (key) {
  axiosInstance._TokenKey = key;
};

// axiosInstance.setToken = function (token) {
//   var key = "";
//   if (axiosInstance._TokenKey) {
//     key = axiosInstance._TokenKey;
//   }
//   localStorage.setItem(key, token);
// };

// axiosInstance.getToken = function () {
//   return getToken();
// };

export default axiosInstance;
