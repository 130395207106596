import * as React from "react";
import api from "api";
import { Steps, Modal, message, Button } from "antd";
import { formatShortDate } from "common/Format2";
const StudyHistory = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [student, setStudent] = React.useState({});
  const [lstHistory, setLstHistory] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    open(std) {
      setVisible(true);
      setStudent(std);
      api
        .get("Student/GetListHistoryStatus?student_id=" + std.id)
        .then((res) => {
          setLstHistory(res.data);
        })
        .catch((err) => {
          message.error(err.message);
        });
    },
  }));

  return (
    <Modal
      maskClosable={false}
      width="30%"
      open={visible}
      title={`Lịch sử học sinh ${student.name}`}
      footer={
        <Button
          type="primary"
          onClick={() => {
            setVisible(false);
          }}
        >
          Ok
        </Button>
      }
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Steps
        progressDot
        current={lstHistory.length}
        direction="vertical"
        items={lstHistory.map((item) => {
          var new_value = "";
          var old_value = "";
          switch (item.new_status) {
            case "studying":
              new_value = "Đang học";
              break;
            case "pandemic_break":
              new_value = "Ngưng học";
              break;
            case "on_hole":
              new_value = "Tạm ngưng";
              break;
            default:
              new_value = "";
              break;
          }
          switch (item.oldstatus) {
            case "studying":
              old_value = "Đang học";
              break;
            case "pandemic_break":
              old_value = "Ngưng học";
              break;
            case "on_hole":
              old_value = "Tạm ngưng";
              break;
            default:
              old_value = "";
              break;
          }

          return {
            title: `${formatShortDate(item.created_date)} - ${item.name}`,
            description: `${old_value} ${old_value != "" ? "=>" : ""} ${
              old_value != "" ? new_value : "Nhập học"
            }`,
          };
        })}
      />
    </Modal>
  );
});
export default StudyHistory;
